import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { LANGUAGES } from "src/constants";
import Icon from "src/Components/ui/Icon";
import { togglePanelMenu } from "src/store/components/mobile/menu/menuSlice";
import { updateLanguage } from "src/store/components/startUp/startUpActions";

const Wrap = styled.div`
  height: calc(100% - 20px);
`;
const WrapInner = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  overscroll-behavior: contain;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0 0 120px 0;
  margin: 0;
  text-align: left;
  @media (orientation: portrait) {
    padding: 0 0 140px 0;
  }
`;

const Item = styled.li``;
const Lang = styled.span`
  cursor: pointer;
  font-size: 14px;
  padding: 11.5px 20px;
  color: rgba(230, 230, 230, 1);
  display: flex;
  border-bottom: 1px solid rgba(76, 76, 76, 1);
  &.active {
    background-color: rgba(255, 255, 26, 0.2);
    color: rgba(230, 230, 230, 1);
    .icon {
      svg {
        path {
          fill: rgba(255, 255, 26, 1);
        }
      }
    }
  }
  .icon {
    margin-left: auto;
    margin-right: 70px;
  }
`;

const Img = styled.img`
  width: 34px;
  height: 20px;
  margin-right: 10px;
`;
const Label = styled.span``;

const Languages = () => {
  const { language, languages } = useSelector((state) => state.startUp);
  const currentLangId = String(language);
  const dispatch = useDispatch();

  const handleChangeLanguage = (lang) => {
    if (lang !== currentLangId) {
      dispatch(togglePanelMenu(false));
      setTimeout(() => {
        dispatch(updateLanguage(lang));
      }, 400);
    }
  };

  const handleScroll = (e) => {
    e.stopPropagation();
  };

  return (
    <Wrap>
      <WrapInner onTouchMove={handleScroll}>
        <List>
          {(languages || []).map((lang) => (
            <Item key={`${lang}-lang`} id={`${lang}-lang`}>
              <Lang
                className={lang === currentLangId && "active"}
                onClick={(event) => {
                  event.preventDefault();
                  handleChangeLanguage(lang);
                }}
              >
                <Img src={`/assets/img/icons/languages/${LANGUAGES[lang]}.svg`} alt="" />
                <Label>
                  <FormattedMessage id={`${lang}`} defaultMessage="Not Found" />
                </Label>
                <Icon name="check" fill="#4c4c4c" width="21.929" height="15.948" />
              </Lang>
            </Item>
          ))}
        </List>
      </WrapInner>
    </Wrap>
  );
};

export default Languages;
