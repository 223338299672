import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(76, 76, 76, 0.3);
`;
const Icon = styled.div`
  span {
    display: block;
    font-size: 0;
  }
`;
const Label = styled.div`
  font-size: 14px;
  margin-left: 10px;
  color: #e6e6e6;
  text-transform: uppercase;
`;

const Heading = ({ icon, title }) => (
  <Wrapper>
    <Icon>{icon}</Icon>
    <Label>{title}</Label>
  </Wrapper>
);

export default Heading;
