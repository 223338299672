import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { closeExternalGame } from "src/store/components/gameCategories/gameSlice";
import { FormattedMessage } from "react-intl";
import LoaderComponent from "src/Components/common/LoaderComponent";
import Portal from "src/Components/common/Portal";
import { useDispatch, useSelector } from "react-redux";
import { getBP } from "src/utils/styleUtils";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  float: left;
  position: fixed;
  display: block;
  top: 0;
  border: 0;
  z-index: 20;
  background: rgb(0, 0, 0);
`;
const CloseButton = styled.button`
  width: 34px;
  height: 34px;
  position: absolute;
  right: 0;
  border: 1px solid hsla(0, 0%, 100%, 0.15);
  border-radius: 0.15em;
  background: hsla(0, 0%, 5%, 0.8);
  padding: 8px;
  cursor: pointer;
  z-index: 102;
  &:hover {
    background: hsla(0, 0%, 85%, 0.85);
  }
`;
const MessageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100;
`;
const Message = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
  font-size: 30px;
`;
const Btn = styled.button`
  height: 60px;
  border-radius: 30px;
  font-size: 20px;
  width: 220px;
  border: none;
  color: #000;
  cursor: pointer;
  background-color: #ffff1a;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    height: 40px;
    border-radius: 20px;
    font-size: 20px;
    width: 150px;
  }
`;
const Frame = styled.iframe`
  width: 100%;
  position: absolute;
  border: 0;
  z-index: 101;
  @media (orientation: landscape) {
    width: calc(100% - 52px);
  }
`;

const closeButtonTopRatio = 15;
const { innerHeight } = window;

const ExternalGameWrapperComponent = () => {
  const { externalGame } = useSelector((state) => state.gameCategories);
  const dispatch = useDispatch();

  const [height, setHeight] = useState(innerHeight);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // iphone top bar workaround
    window.addEventListener("resize", () => {
      const { innerHeight } = window;
      setHeight(innerHeight);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
  });

  const onCloseExternalGame = () => {
    setIsLoading(true);
    dispatch(closeExternalGame());
  };

  const onLoadHandler = () => {
    setIsLoading(false);
  };

  return (
    (externalGame.url || externalGame.error) && (
      <Portal>
        <StyledWrapper>
          <CloseButton
            onClick={() => onCloseExternalGame()}
            style={{ top: `${(closeButtonTopRatio / 100) * height}px` }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="white">
              <path
                id="close_ic"
                data-name="close ic"
                d="M2501,1802.81l-1.81-1.81-7.19,7.19-7.19-7.19-1.81,1.81,7.19,7.19-7.19,7.19,1.81,1.81,7.19-7.19,7.19,7.19,1.81-1.81-7.19-7.19Z"
                transform="translate(-2483 -1801)"
              />
            </svg>
          </CloseButton>

          {externalGame.url && !externalGame.error && (
            <Frame
              onLoad={onLoadHandler}
              id="externalGame"
              title="External Game"
              src={externalGame.url}
              style={{ height: `${height}px` }}
            />
          )}
          {isLoading && <LoaderComponent />}
          {externalGame.error && (
            <MessageWrapper>
              <Message>
                <FormattedMessage id="GLexternalGameError" values={{ breakLine: <br /> }} />
                <br />
                <br />
                <Btn key="login" onClick={() => onCloseExternalGame()}>
                  <FormattedMessage id="GLgoBack" />
                </Btn>
              </Message>
            </MessageWrapper>
          )}
        </StyledWrapper>
      </Portal>
    )
  );
};

export default ExternalGameWrapperComponent;
