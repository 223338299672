import React, { useState } from "react";
import { sendForgotPassword } from "src/store/components/user/userSlice";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { logoXpg } from "../../utils/commonUtils";
import ForgotPasswordSuccessComponent from "../Info/ForgotPasswordSuccessComponent";
import ForgotPasswordFailedComponent from "../Info/ForgotPasswordFailedComponent";
import StyledFormComponent from "../common/formComponentStyles";
import { Redirect, useLocation } from "react-router-dom";
import BasicLayout from "../layout/Basic";
import { useSelector } from "react-redux";
import { Wrapper } from "./styles";
import useWindowHeight from "src/customHooks/useWindowHeight";

const ForgotPasswordValidation = ({ isFail }) => {
  const { mobileLogoUrl } = useSelector((state) => state.startUp);
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();

  const location = useLocation();

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const onSubmit = (e) => {
    dispatch(sendForgotPassword(false));
    setRedirectToLogin(true);
  };

  const typeComponent = (isFail, errorCode) => {
    if (isFail) {
      return <ForgotPasswordFailedComponent submit={onSubmit} errorCode={errorCode} />;
    }

    return <ForgotPasswordSuccessComponent submit={onSubmit} />;
  };

  const src = mobileLogoUrl || logoXpg;
  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          <div className="screen">
            <Grid container justifyContent="center">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={src} className="img" alt={"Chips"} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {redirectToLogin && <Redirect to="/login" />}

            {typeComponent(!!isFail, location.state)}
          </div>
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default ForgotPasswordValidation;
