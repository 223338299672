import React from "react";
import RouletteComponent from "./RouletteLastResults";
import BaccaratComponent from "./BaccaratLastResults";
import BlackJackComponent from "./BlackJackLastResults";
import DragonTigerComponent from "./DragonTigerLastResults";
import TTCardsStatistics from "./32CardsStatistics";

const renderResultComponent = (item) => {
  switch (item.gameType) {
    case 1:
      return item.rouletteLastResults ? <RouletteComponent collection={item.rouletteLastResults} /> : null;

    case 4:
      return item.bigRoadMap ? <BaccaratComponent collection={item.bigRoadMap} /> : null;

    case 2:
      return item.activeSeatsData ? <BlackJackComponent collection={item.activeSeatsData} /> : null;

    case 12:
      return item.bigRoadMap ? <DragonTigerComponent collection={item.bigRoadMap} /> : null;

    case 30:
      return item.resultStatistics ? <TTCardsStatistics resultStatistics={item.resultStatistics} /> : null;

    default:
      return null;
  }
};

export default renderResultComponent;
