// @flow
import type { LoginRequest } from "../clientModels/LoginRequest";
import type { LoginResponse } from "../clientModels/LoginResponse";
import type { LCLoginRequest } from "../serverModels/LCLoginRequest";
import type { LCLoginResponse } from "../serverModels/LCLoginResponse";
import type { ServiceResponse } from "../ResponseTypes";
import type { LoginWithTokenRequest, LoginMGLRequest } from "../clientModels/LoginWithTokenRequest";
import md5 from "md5";

export const mapLoginRequest = (request: LoginRequest): LCLoginRequest => ({
  userName: request.userName,
  password: md5(request.password).toUpperCase(),
  operatorId: request.operatorId,
  isPasswordHashed: true,
  isLoginByToken: false,
  urlReferrer: document.referrer || document.URL
});

export const mapLoginWithTokenRequest = (request: LoginWithTokenRequest): LCLoginRequest => ({
  userName: request.userName,
  password: request.token,
  operatorId: request.operatorId,
  isPasswordHashed: false,
  isLoginByToken: true,
  urlReferrer: document.referrer || document.URL
});

export const mapLoginMGLRequest = (request: LoginMGLRequest): LCLoginRequest => ({
  accessPassword: request.token,
  operatorId: request.operatorId
});

const mapLoginResponse = (data: LCLoginResponse): LoginResponse => {
  let response = data.description.split(";");
  return {
    token: response[0],
    userName: response[1],
    playerId: parseInt(response[2], 10),
    externalSessionId: response[3],
    errorCode: data.errorCode
  };
};

export const resolveResponse = (response: ServiceResponse<LCLoginResponse>): LoginResponse => {
  return mapLoginResponse(response.data);
};
