// @flow
import React, { Component, createContext } from "react";
import { parseString } from "xml2js/lib/parser";

const initialState = {
  data: null,
  loading: false,
  error: null
};

const initialQueryContextValue = {
  state: initialState,
  actions: {}
};

const QueryContext: Object = createContext(initialQueryContextValue);

type Props = {
  isXml: boolean,
  children: Object,
  options: Object,
  disableInitialFetch: boolean,
  stateReducer: (...args: Object) => void,
  deserialize: (...args: Object) => void,
  fetch: (...args: Object) => void,
  url: string
};

class QueryProvider extends Component<Props> {
  static Consumer = QueryContext.Consumer;
  static defaultProps: Object = {
    fetch,
    disableInitialFetch: false,
    isXml: false,
    stateReducer: (update, state, props) => update,
    deserialize: async (res, isXml) => (isXml ? res.text() : res.json())
  };
  state: Object = initialState;
  componentDidMount() {
    if (!this.props.disableInitialFetch) {
      this.request();
    }
  }

  setReducedState = (update: Object) => {
    const { stateReducer } = this.props;
    this.setState((state) => stateReducer(update, state, this.props));
  };

  request = async (optionsPart: Object) => {
    const { fetch, url: propUrl, options, deserialize, isXml } = this.props;

    this.setReducedState({ loading: true });

    // use the url from the request argument or fallback to the url from props
    const endPoint = (optionsPart && optionsPart.url) || propUrl;
    let fetchOptions = options;
    if (optionsPart) {
      // strip the url key from the fetch options if it is provided
      const { url, ...restOptions } = optionsPart;
      fetchOptions = { ...options, ...restOptions };
    }

    try {
      const res = await fetch(endPoint, fetchOptions);
      const data = await deserialize(res, isXml);
      if (isXml) {
        parseString(data, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
          const text = result.response;
          if (text.errorCode !== "0") {
            this.setReducedState({
              loading: false,
              error: {
                code: text.errorCode,
                description: text.description,
                priority: "high"
              }
            });
          } else {
            this.setReducedState({
              data: text,
              loading: false,
              error: null
            });
          }
        });
      } else {
        this.setReducedState({
          data,
          loading: false,
          error: null
        });
      }
    } catch (error) {
      this.setReducedState({
        loading: false,
        error
      });
    }
  };

  actions = {
    fetch: this.request
  };

  render() {
    const { children } = this.props;
    const value = {
      state: this.state,
      actions: this.actions
    };
    return (
      <QueryContext.Provider value={value}>
        {typeof children === "function" ? children(value) : children}
      </QueryContext.Provider>
    );
  }
}

export default QueryProvider;
