import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import DealerImage from "./AsyncImage";
import SwitchGameComponent from "./SwitchGameComponent";
import AddTableComponent from "./AddTableComponent";
import OpenedTableComponent from "./OpenedTableComponent";
import GameLimitComponent from "./GameLimitComponent";
import GameInfoComponent from "./GameInfoComponent";
import GameImageComponent from "../GameImageComponent";
import { getBP } from "src/utils/styleUtils";
import { useDeviceState } from "src/utils/helpers";
import PlayersCountComponent from "./PlayersCountComponent";
const StyledGrid = styled((props) => <Grid {...props} />)`
  @media screen and (min-width: 768px) {
    &.item {
      height: 100%;
      position: relative;
      padding: 12px;

      @media (max-width: ${({ theme: t }) => getBP(t, "sm")}) and (orientation: landscape) {
        max-width: 33.3%;
        flex-basis: 33.3%;
      }
    }
    &.hide {
      display: none;
    }

    .selected {
      border: 1px solid #ffff1a;
    }
  }
  @media (orientation: landscape) {
    &.item {
      height: 100%;
      position: relative;
      padding: 12px;

      @media (max-width: ${({ theme: t }) => getBP(t, "sm")}) and (orientation: landscape) {
        max-width: 33.3%;
        flex-basis: 33.3%;
      }
    }
    &.hide {
      display: none;
    }

    .selected {
      border: 1px solid #ffff1a;
    }
  }
`;

const StyledGame = styled.div`
  background-color: black;
  display: block;
  border: 1px solid #86818e;
  transition: 0.2s linear;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
    background-image: linear-gradient(0deg, rgb(64, 63, 77) 0%, rgba(64, 63, 77, 0) 15%);
    content: " ";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  &:hover {
    border: 1px solid #ffff1a;
    box-shadow: 0 13px 19px 1px rgba(255, 255, 26, 0.1);

    & .limit {
      color: #1a1a1a;
      background: rgba(255, 255, 26, 0.7);
    }
  }
`;

const Game = ({ game, addGame, switchGame, selectGame }) => {
  const { isMobile, isLandscape } = useDeviceState();
  const { cdnPath } = useSelector((state) => state.startUp);
  const { gridType } = useSelector((state) => state.gameCategories);
  const { games: openedGames, lobbyMode } = useSelector((state) => state.multiView);
  const { currencySymbol } = useSelector((state) => state.user);

  const isSelected = openedGames.find(
    (item) => item.gameId === game.gameId && item.limitId === game.limitSet.limitSetId
  );
  const isSameGame = openedGames.find((item) => game.gameId === item.gameId);

  return (
    <StyledGrid
      className="asd"
      item
      xs={(!isMobile || isLandscape) && 12}
      sm={(!isMobile || isLandscape) && 4}
      md={(!isMobile || isLandscape) && 4 - gridType}
      lg={(!isMobile || isLandscape) && 3 - gridType}
      key={`${game.gameId}_${game.limitSet.limitSetId}`}
      onClick={() => selectGame(game)}
      classes={{
        item: "item" + (!isSelected && isSameGame ? " hide" : "")
      }}
    >
      <StyledGame className={(isSelected && " selected") || ""}>
        <GameImageComponent path={`${cdnPath || "/assets/img/"}LiveGamesBackground/${game.gameId}.jpg`} />

        <GameLimitComponent item={game} symbol={currencySymbol} smallGrid={gridType} />

        <DealerImage source={game.dealerImageUrl} smallGrid={gridType} />

        <GameInfoComponent item={game} smallGrid={gridType} />
        {!isMobile && <PlayersCountComponent playersCount={game.playersCount} />}
      </StyledGame>

      {!isSelected && !isSameGame && lobbyMode === 0 && openedGames.length < 4 && (
        <AddTableComponent item={game} onSelect={addGame} />
      )}

      {!isSelected && isSameGame && <SwitchGameComponent item={game} onSelect={switchGame} />}

      {isSelected && <OpenedTableComponent />}
    </StyledGrid>
  );
};

export default Game;
