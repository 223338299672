import React from "react";
import { useDispatch } from "react-redux";
import { togglePanelMenu } from "src/store/components/mobile/menu/menuSlice";
import styled from "styled-components";
import Icon from "src/Components/ui/Icon";
import StickyBottom from "../../../../StickyBottom";

const Button = styled.button`
  padding: 0;
  width: 44px;
  height: 44px;
  position: fixed;
  right: 20px;
  z-index: 19;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(178, 178, 178, 1);
  &:focus {
    outline: none;
  }
  span {
    font-size: 0;
    vertical-align: middle;
    display: block;
  }
`;

const ButtonClose = () => {
  const dispatch = useDispatch();

  return (
    <StickyBottom>
      <Button onClick={() => dispatch(togglePanelMenu(false))}>
        <Icon name="close" fill="black" width="16" height="16" />
      </Button>
    </StickyBottom>
  );
};

export default ButtonClose;
