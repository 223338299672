import React from "react";
import styled from "styled-components";
import { LogoutService } from "src/services";
import { useDispatch, useSelector } from "react-redux";
import { loginWentWrong } from "src/store/components/user/userSlice";
import { tokenLoginFailed } from "src/store/common/commonActions";

const LinkMenu = styled.div``;

const Logout = ({ children, onToggleMenu = () => {} }) => {
  const { apiClientUrl: client } = useSelector((state) => state.startUp);
  const { token } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    const res = await LogoutService.logout(client, {
      registerToken: token
    });

    if (res.errorCode === "0") {
      localStorage.removeItem("token");
      dispatch(loginWentWrong(""));
      onToggleMenu(false);
    } else {
      dispatch(tokenLoginFailed(res));
    }
  };

  return (
    <LinkMenu className="item-menu" onClick={handleLogout}>
      {children}
    </LinkMenu>
  );
};

export default Logout;
