import React from "react";
import { StyledInfoPage } from "../common/formControlStyles";
import Check from "@material-ui/icons/Check";

const IconConfirmComponent = () => {
  return (
    <StyledInfoPage>
      <div className={"iconWrapper"}>
        <Check className={"icon"} />
      </div>
    </StyledInfoPage>
  );
};

export default IconConfirmComponent;
