import React from "react";
import styled from "styled-components";
import Icon from "src/Components/ui/Icon";
import StickyBottom from "../../../StickyBottom";
import { getBP } from "src/utils/styleUtils";

const Button = styled.button`
  padding: 0;
  width: 44px;
  height: 44px;
  position: fixed;
  right: 20px;
  z-index: 15;
  display: ${({ isOpenPanel }) => (isOpenPanel ? "none" : "block")};
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: ${({ typeBtn }) => (typeBtn === "open" ? "#ffff1a" : "rgba(178, 178, 178, 0.9)")};
  @media (min-width: ${({ theme: t }) => getBP(t, "desktop")}) {
    display: none;
  }
  &:focus {
    outline: none;
  }
  span {
    font-size: 0;
    vertical-align: middle;
    display: block;
  }
`;

const ButtonMenu = ({ typeBtn, onToggleMenu, isOpenPanel }) => (
  <StickyBottom>
    <Button
      data-name={`GLMenuButton-${typeBtn}`}
      typeBtn={typeBtn || "open"}
      onClick={onToggleMenu}
      isOpenPanel={isOpenPanel}
    >
      <Icon name={typeBtn === "open" ? "menu" : "close"} fill="black" width="16" height="16" />
    </Button>
  </StickyBottom>
);

export default ButtonMenu;
