import { blockIp, storeRememberMeFields, updateLoginType, updateOperatorId } from "../../common/commonActions";
import { getTranslations } from "./startUpActions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  clientSessionLength: 0,
  extendTokenInterval: 0,
  loginTokenExtendTime: 0,
  operatorId: 0,
  languages: [],
  language: 0,
  translations: {},
  translationError: 0, // 0 -> success, 1 -> reject
  deviceType: "Web",
  defaultCategory: "",
  translationServerCommURL: "",
  ipNotAuthorized: false,
  forgotPasswordURL: "",
  signUpURL: "",
  loginType: 0,
  cdnPath: "",
  lobbyMenuItems: [],
  GLTheme: null,
  homeUrl: "",
  gameType: 0
};

const startUpSlice = createSlice({
  name: "startUp",
  initialState,
  reducers: {
    getApiParamsAction: (state, action) => ({
      ...state,
      ...action.payload
    }),
    updateDeviceType: (state, action) => {
      state.deviceType = action.payload;
    },
    updateDefaultCategory: (state, action) => {
      state.defaultCategory = action.payload;
    }
  },
  extraReducers: {
    [storeRememberMeFields.fulfilled]: (state, action) => {
      state.userName = action.payload.userName;
      state.password = action.payload.password;
      state.rememberMe = action.payload.rememberMe;
    },
    [updateOperatorId.fulfilled]: (state, action) => {
      state.operatorId = action.payload;
    },
    [updateLoginType.fulfilled]: (state, action) => {
      state.loginType = action.payload;
    },
    [blockIp.fulfilled]: (state) => {
      state.ipNotAuthorized = true;
    },

    [getTranslations.fulfilled]: (state, action) => {
      state.translations = action.payload.translations;
      state.language = action.payload.language;
    },
    [getTranslations.rejected]: (state, action) => {
      state.translationError = action.payload;
    }
  }
});

const { reducer, actions } = startUpSlice;
export const { getApiParamsAction, updateDeviceType, updateDefaultCategory } = actions;
export default reducer;
