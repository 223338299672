import React from "react";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import UserComponent from "../User/UserComponent";
import UserMobileComponent from "../User/UserMobileComponent";
import ToolBarComponent from "./ToolBarComponent";

const Header = styled.div`
  width: 100%;
  position: relative;
`;
const HeaderComponent = () => {
  return (
    <Header>
      <Hidden smDown>
        <UserComponent />
        <ToolBarComponent />
        <div style={{ clear: "both", height: 0 }} />
      </Hidden>
      <Hidden mdUp>
        <UserMobileComponent />
      </Hidden>
    </Header>
  );
};

export default HeaderComponent;
