import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const FullScreen = () => <Wrapper />;

export default FullScreen;
