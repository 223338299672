import React, { useEffect, useState } from "react";
import { activate } from "src/store/components/user/userActions";
import { resetActivation } from "src/store/components/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import LoaderComponent from "../common/LoaderComponent";
import { getUrlParams } from "../../utils/commonUtils";
import { FormattedMessage } from "react-intl";
import BasicLayout from "../layout/Basic";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 10%;
  overflow: auto;
  display: block;
  box-sizing: border-box;
`;

const AccountActivationComponent = () => {
  const { activationStatus, activationResponse } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [redirectToActivationFailed, setRedirectToActivationFailed] = useState(false);

  useEffect(() => {
    const rawUrlParams = getUrlParams("/activate");

    console.log("Url raw params: " + JSON.stringify(rawUrlParams));

    dispatch(activate(rawUrlParams.registerCode));
  }, []);

  useEffect(() => {
    if (activationStatus === "success") {
      if (activationResponse && activationResponse.errorCode) {
        switch (activationResponse.errorCode) {
          case "33":
            setRedirectToActivationFailed(true);
            break;
          case "0":
          case "41":
            setRedirectToLogin(true);
            break;
          default:
        }
        dispatch(resetActivation());
      }
    }
  }, [activationStatus]);

  return (
    <BasicLayout>
      <Wrapper>
        {redirectToLogin && <Redirect to="/login" />}
        {redirectToActivationFailed && <Redirect to="/activationFailed" />}
        <LoaderComponent message={<FormattedMessage id="GLloading" />} />
      </Wrapper>
    </BasicLayout>
  );
};

export default AccountActivationComponent;
