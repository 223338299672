import React, { Fragment } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import qs from "qs";
import QueryProvider from "src/provider/Query";
import { setError } from "src/store/components/error/errorSlice";

const Wrapper = styled.div``;

const Currency = styled.div`
  color: #ffff1a;
  font-size: 12px;
  @media (orientation: landscape) {
    display: inline-block;
    margin-left: 5px;
  }
`;

const Header = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    color: #e6e6e6;
    font-size: 12px;
    th {
      padding: 8px 15px;
      @media (orientation: landscape) {
        padding: 8px 20px;
      }
      background-color: rgba(76, 76, 76, 1);
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      width: auto;
      &:nth-child(n + 2) {
        width: 33%;
        padding: 8px 13px;
      }
      @media (orientation: landscape) {
        &:nth-child(n + 2) {
          width: 23%;
        }
      }
    }
  }
`;

const Body = styled.div`
  height: 100%;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 90px;
    font-size: 12px;
    @media (orientation: landscape) {
      font-size: 14px;
    }
    tr.date {
      td {
        color: #808080;
        padding: 2px 15px;
        background-color: rgba(76, 76, 76, 0.3);
        text-align: center;
      }
    }
    tr.games {
      &:not(:last-of-type) {
        td {
          border-right: 1px solid #4c4c4c;
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }
    tr {
      td {
        padding: 5px 15px;
        @media (orientation: landscape) {
          padding: 5px 20px;
        }
        color: #e6e6e6;
        width: auto;
        &:nth-child(n + 2) {
          width: 33%;
        }
        @media (orientation: landscape) {
          &:nth-child(n + 2) {
            width: 23%;
          }
        }
      }
      &:last-of-type {
        td {
          padding-bottom: 60px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: -0.5px;
            width: 1px;
            height: 100%;
            z-index: 10;
            background-image: linear-gradient(#4c4c4c, rgba(0, 0, 0, 0.1));
          }
        }
      }
    }
  }
`;

const GamesHistory = () => {
  const { token, currencySymbol: currency } = useSelector((state) => state.user);
  const { operatorId, apiClientUrl } = useSelector((state) => state.startUp);
  const dispatch = useDispatch();

  return (
    <Wrapper id="games-history-tab">
      <Header>
        <table>
          <tbody>
            <tr>
              <th>
                <FormattedMessage id="game" />
              </th>
              <th>
                <FormattedMessage id="bet" />
                <Currency>{currency}</Currency>
              </th>
              <th>
                <FormattedMessage id="win" /> / <FormattedMessage id="lose" />
                <Currency>{currency}</Currency>
              </th>
            </tr>
          </tbody>
        </table>
      </Header>
      <Body>
        <QueryProvider
          url={`${apiClientUrl}Services/ExternalApi.svc/clientGetBettingHistory`}
          isXml
          options={{
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data"
            },
            body: qs.stringify({
              registerToken: token,
              operatorId: parseInt(operatorId, 10)
            })
          }}
        >
          {({ state: { data, loading, error } }) => {
            if (loading) {
              return "Loading";
            }

            if (error) {
              dispatch(setError(error));
            }

            if (data) {
              console.log("data exists");
              const history = JSON.parse(data.description);
              return (
                <table>
                  <tbody>
                    {history.map((games, keyH) => (
                      <Fragment key={`tr-fragment-${keyH.toString()}`}>
                        <tr className="date" key={`tr-date-${keyH.toString()}`}>
                          <td colSpan="3">- - {games.Date} - -</td>
                        </tr>
                        {games.GamesInfo.map((game, keyG) => (
                          <tr className="games" key={`tr-game-${keyH.toString()}-${keyG.toString()}`}>
                            <td>{game.Name}</td>
                            <td>{game.Bet.toFixed(2)}</td>
                            <td>{(game.Win - game.Bet).toFixed(2)}</td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              );
            }
            return null;
          }}
        </QueryProvider>
      </Body>
    </Wrapper>
  );
};

export default GamesHistory;
