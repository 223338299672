// @flow

import type { ServiceResponse } from "../ResponseTypes";
import type { LCBalanceRequest } from "../serverModels/LCBalanceRequest";
import type { LCBalanceResponse } from "../serverModels/LCBalanceResponse";
import type { BalanceRequest } from "../clientModels/BalanceRequest";
import type { BalanceResponse } from "../clientModels/BalanceResponse";

export const mapBalanceRequest = (
  request: BalanceRequest
): LCBalanceRequest => ({
  registerToken: request.registerToken,
  operatorId: request.operatorId
});

const mapBalanceResponse = (data: LCBalanceResponse): BalanceResponse => ({
  balance: parseFloat(data.balance),
  currencySymbol: decodeURIComponent(data.currency)
});

export const resolveResponse = (
  response: ServiceResponse<LCBalanceResponse>
): BalanceResponse => {
  return mapBalanceResponse(response.data);
};
