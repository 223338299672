import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { DetectBrowsers } from "../../utils/commonUtils";
import { getBP } from "src/utils/styleUtils";

const Icon = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 5.4%;
  margin-left: 5.4%;
  width: 9%;

  @media (max-width: ${({ theme }) => getBP(theme, "xs")}) {
    margin-right: 3.4%;
    margin-left: 3.4%;
    width: 13.1%;
  }

  & span {
    position: relative;
    width: 100%;
    color: #d9d9d9;
    font-size: 0.85vw;
    display: block;
    padding-top: 114%;
    text-align: center;
    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      display: none;
    }
  }

  & img {
    position: relative;
    float: left;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
`;
const PopupContainer = styled.div`
  position: absolute;
  width: 40%;
  height: auto;
  top: 38.8%;
  left: 50%;
  padding: 1.65%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid #808080;
  border-radius: 6px 6px 6px 6px;
  z-index: 100;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    width: 80%;
    top: 48%;
  }
`;
const TextContainer = styled.div`
  width: 100%;
  padding-left: 5.85%;
  padding-right: 5.85%;
  box-sizing: border-box;
`;
const TextTitle = styled.div`
  text-align: center;
  color: #d9d9d9;
  font-size: 24px;
  margin-bottom: 3%;
`;
const TextContent = styled.div`
  text-align: center;
  color: #d9d9d9;
  font-size: 16px;
`;
const IconContainer = styled.div`
  width: 100%;
  margin-top: 4%;
`;
const BtnWrapper = styled.div`
  text-align: center;
  margin-top: 5%;
  height: 100%;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    margin-bottom: 0.5%;
  }
  @media (max-width: ${({ theme }) => getBP(theme, "xs")}) {
    margin-bottom: 3%;
  }
`;
const Btn = styled.button`
  border-radius: 30px;
  font-size: 18px;
  border: none;
  color: #000;
  background-color: #ffff1a;
  padding: 9px 17px;
  bottom: 8%;
  box-sizing: border-box;

  &:hover {
    background-color: #e5e517;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }

  @media (orientation: landscape) and (max-width: 732px) {
    ${BtnWrapper} {
      margin-bottom: 1%;
    }
  }
`;

const BrowserIcon = (src, name) => (
  <Icon key={name}>
    <img src={src} alt={`${name}-icon`} />
    <span>{name}</span>
  </Icon>
);

const createIconArray = () => {
  const ChromeIcon = `/assets/img/icons/chrome.png`;
  const EdgeIcon = `/assets/img/icons/edge.png`;
  const FireFoxIcon = `/assets/img/icons/firefox.png`;
  const OperaIcon = `/assets/img/icons/opera.png`;
  const SafariIcon = `/assets/img/icons/safari.png`;

  const iconArray = [
    { src: ChromeIcon, name: "Chrome" },
    { src: EdgeIcon, name: "Edge" },
    { src: FireFoxIcon, name: "Firefox" },
    { src: OperaIcon, name: "Opera" },
    { src: SafariIcon, name: "Safari" }
  ];

  return iconArray;
};

const CompatibilityComponent = () => {
  const { deviceType } = useSelector((state) => state.startUp);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(true);

  useEffect(() => {
    const detectBrowserInstance = new DetectBrowsers();
    setIsSupportedBrowser(detectBrowserInstance.isSupportedBrowser(deviceType));
  }, []);

  const iconArray = createIconArray();
  return (
    !isSupportedBrowser && (
      <Container>
        <PopupContainer>
          <TextContainer>
            <TextTitle>
              <FormattedMessage id="GLbrowserDetect.UseAnotherBrowserTitle" />
            </TextTitle>
            <TextContent>
              <FormattedMessage id="GLbrowserDetect.UseAnotherBrowser" values={{ breakLine: <br /> }} />
            </TextContent>
          </TextContainer>
          <IconContainer>{iconArray.map((value) => BrowserIcon(value.src, value.name))}</IconContainer>
          <BtnWrapper>
            <Btn key="continue" onClick={() => setIsSupportedBrowser(true)}>
              <FormattedMessage id="GLcontinue" />
            </Btn>
          </BtnWrapper>
        </PopupContainer>
      </Container>
    )
  );
};

export default CompatibilityComponent;
