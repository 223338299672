import React from "react";
import ActivationInfoComponent from "./ActivationInfoComponent";

const ForgotPasswordSuccessComponent = ({ submit }) => {
  return (
    <ActivationInfoComponent
      translationKeys={{
        mainText: "GLForgotPasswordValidation.SuccessMain",
        subText: "GLForgotPasswordValidation.SuccessSub"
      }}
      submit={submit}
    />
  );
};

export default ForgotPasswordSuccessComponent;
