import { createAsyncThunk } from "@reduxjs/toolkit";
import MobileDetect from "mobile-detect";
import { clientId } from "../../../services/configs/servicesConfig";
import { updateLoginType, storeRememberMeFields, tokenLogin, mglLogin, blockIp } from "../../common/commonActions";
import { loadingAppProgress } from "../../app/appSlice";
import { ApiParamsService, TranslationsService } from "../../../services";
import { setError } from "../error/errorSlice";
import { checkUrlHTTP, isInArray, setLocStorage } from "../../../utils/helpers";
import { setTabsMenu } from "../mobile/menu/menuSlice";
import Cookies from "js-cookie";
import { getApiParamsAction, updateDeviceType, updateDefaultCategory } from "./startUpSlice";

const lobbyTypeMGL = clientId === 5;

export const startUpAction = createAsyncThunk("startUp/startUp", ({ startParams, loginType }, { dispatch }) => {
  const md = new MobileDetect(window.navigator.userAgent);
  const deviceType = md.mobile() ? "Mobile" : "Web";
  const { userName, token, password, rememberMe, defaultCategory } = startParams;

  dispatch(updateDeviceType(deviceType));

  if (defaultCategory) {
    dispatch(updateDefaultCategory(defaultCategory));
  }

  dispatch(updateLoginType(parseInt(loginType, 10)));

  rememberMe && dispatch(storeRememberMeFields({ userName, password, rememberMe }));

  if (!!token && !!userName) {
    dispatch(tokenLogin({ ...startParams, deviceType }));
    // dispatch(updateLoginType(1));
  } else if (lobbyTypeMGL) {
    dispatch(mglLogin({ ...startParams, deviceType }));
  } else {
    dispatch(loadingAppProgress("finish"));
  }
});

export const getTranslations = createAsyncThunk(
  "startUp/getTranslations",
  async (langId, { getState, rejectWithValue, dispatch }) => {
    const { startUp } = getState();
    const languageID = langId || startUp.language;

    try {
      const res = await TranslationsService.getTranslations({
        gameType: 0,
        languageID,
        url: startUp.translationServerCommURL
      });
      return {
        translations: res.translations,
        language: languageID
      };
    } catch (err) {
      if (err) {
        dispatch(
          setError({
            code: "101",
            description: "Translation error",
            priority: "high"
          })
        );
      }
      return rejectWithValue(1);
    }
  }
);

export const updateLanguage = createAsyncThunk("startUp/updateLanguage", (langId, { dispatch }) => {
  const lang = Number(langId);
  dispatch(getTranslations(lang));
  localStorage.setItem("languageId", String(lang));
});

export const getApiParams = createAsyncThunk("startUp/getApiParams", async (urlParams, { dispatch, getState }) => {
  const storedOperatorId = localStorage.getItem("operatorId");
  const isUrlParams = Object.values(urlParams).some((el) => el);

  if (isUrlParams) {
    // check local storage in incognito mode
    try {
      setLocStorage("urlParams", urlParams);
    } catch (e) {
      console.log(e);
    }
  }

  const operatorId = (urlParams && urlParams.operatorId) || (storedOperatorId && parseInt(storedOperatorId, 10)) || -1;
  const paramLanguageId = urlParams.languageId;

  try {
    const res = await ApiParamsService.getApiParams({ operatorId, gameTypeId: 0 });

    if (res.IpNotAuthorized) {
      dispatch(blockIp());
      dispatch(
        setError({
          code: "ipNotAuthorized",
          description: "ipNotAuthorized",
          priority: "high"
        })
      );
      dispatch(loadingAppProgress("finish"));
      return;
    }

    const { startUp } = getState();
    const params = res.operatorId <= 0 && startUp.operatorId > 0 ? { ...res, operatorId: startUp.operatorId } : res;
    if (urlParams.homeUrl.length > 0) {
      localStorage.setItem("homeUrl", checkUrlHTTP(urlParams.homeUrl));
    }
    const urlToParams = {
      homeUrl: urlParams.homeUrl ? checkUrlHTTP(urlParams.homeUrl) : "",
      gameType: urlParams.gameType || 0
    };

    dispatch(getApiParamsAction({ ...params, ...urlToParams }));
    const tabsMenu = params.lobbyMenuItems.filter((el) => !isInArray(el.key, ["changepassword", "gamerules"]));
    dispatch(setTabsMenu(tabsMenu));

    const storedLanguageId = localStorage.getItem("languageId");

    const isLangArr = Array.isArray(res.languages);
    let languageId = null;
    if (isLangArr) {
      languageId =
        res.languages.find((item) => item === paramLanguageId) ||
        res.languages.find((item) => item === storedLanguageId) ||
        null;
    }

    if (!languageId && res.languages.length >= 1) {
      ({ 0: languageId } = res.languages);
    }

    if (!languageId) {
      languageId = "2057";
    }
    dispatch(updateLanguage(languageId));

    const loginType = localStorage.getItem("loginType") || 0;

    if ((urlParams.token && urlParams.userName) || (urlParams.token && lobbyTypeMGL)) {
      dispatch(startUpAction({ startParams: urlParams, loginType: loginType }));
    } else {
      const loginParams = JSON.parse(Cookies.get("loginParams") || "{}");
      const userName = localStorage.getItem("userName") || loginParams.userName;
      const token = localStorage.getItem("token") || loginParams.token;
      const operatorId = localStorage.getItem("operatorId") || loginParams.operatorId;
      const brandName = localStorage.getItem("brandName") || loginParams.brandName;
      const password = localStorage.getItem("password");
      const rememberMe = localStorage.getItem("rememberMe") === "true";
      dispatch(
        startUpAction({
          startParams: {
            userName,
            token,
            operatorId,
            brandName,
            password,
            rememberMe
          },
          loginType
        })
      );
    }
  } catch (err) {
    dispatch(
      setError({
        code: "400",
        description: "400",
        priority: "high"
      })
    )
    dispatch(loadingAppProgress("finish"));
    console.log(err);
  }
});
