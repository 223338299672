import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import errorAnimation from "src/Components/ui/base64/errorAnimation";
import { FormattedMessage } from "react-intl";
import { GeneralErrorMapper } from "src/utils/userUtils";
import { ScrollWrapper } from "src/Components/ui/simpleBar";
import ErrorScreenCountry from "src/Components/common/ErrorScreenCountry";
import { isLobbyInIframe, getLocStorage } from "../../utils/helpers";
import Button from "./backButton";

const Wrapper = styled(ScrollWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #1a1a1a;
  overflow: auto;
  & .simplebar-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
const Label = styled.div`
  color: #ffffff;
  font-size: 24px;
  margin: 0 8px 16px 8px;
  @media (max-width: 896px) and (max-height: 896px) {
    font-size: 20px;
  }
`;
const Icon = styled.div`
  img {
    margin: auto;
    display: block;
    max-width: 100%;
    @media (max-width: 896px) and (orientation: landscape) {
      height: 70vh;
    }
  }
`;

const ErrorBox = ({ error }) => {
  const { operatorId } = useSelector((state) => state.startUp);
  const dataBackBtn = { key: "", url: "" };
  const referrer = localStorage.getItem("referrer") || "";
  const loginType = localStorage.getItem("loginType") || null;
  const homeUrl = localStorage.getItem("homeUrl") || "";
  const isUserBlocked = JSON.parse(process.env.REACT_APP_BLOCKED_OPERATORID).some((id) => id === operatorId);
  isUserBlocked && localStorage.removeItem("loginWithToken")
  const errorLoginWithToken = error.code || (getLocStorage("loginWithToken") && getLocStorage("loginWithToken").errorCode);

  if (errorLoginWithToken === "1" || errorLoginWithToken === "8") {
    if (window.history.length > 1) {
      dataBackBtn.key = "history";
    }
  } else if (homeUrl.length > 0) {
    dataBackBtn.key = "homeUrl";
    dataBackBtn.url = homeUrl;
  } else if (referrer.length > 0) {
    dataBackBtn.key = "referrer";
    dataBackBtn.url = referrer;
  } else if (loginType === "0") {
    dataBackBtn.key = "loginForm";
  } else if (window.history.length >= 2) {
    dataBackBtn.key = "history";
  }
  const errorKey = isUserBlocked ? "GLplayerBlocked" : GeneralErrorMapper[errorLoginWithToken] || "404";
  const isIpNotAuthorized = errorLoginWithToken === "ipNotAuthorized";
  return (
    <>
      {isIpNotAuthorized ? (
        <ErrorScreenCountry />
      ) : (
        <Wrapper>
          <Icon>
            <img src={errorAnimation} alt="Error" />
          </Icon>
          <Label>
            {!isLobbyInIframe() ? (
              <FormattedMessage id={errorKey} values={{ breakLine: <br /> }} defaultMessage="Something went wrong." />
            ) : (
              <FormattedMessage id="GLloginWentWrong" values={{ breakLine: <br /> }} />
            )}
          </Label>
          {(error.code !== '400' && dataBackBtn.key) && !isLobbyInIframe() && <Button data={dataBackBtn} />}
        </Wrapper>
      )}
    </>
  );
};

export default ErrorBox;
