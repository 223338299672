export const ErrorCodes = Object.freeze({
  NO_ERROR: "0",
  PLAYER_BANNED: "8",
  PLAYER_NOT_ACTIVATED: "33",
  FORGOTTEN_PASSWORD_TOKEN_EXISTS: "42"
});

export const SignUpErrorMapper = {
  "0": "GLSignUpSent",
  "3": "GLSignUp.playerAlreadyExists",
  "4": "GLSignUp.nicknameAlreadyExists",
  "16": "GLSignUp.emailAlreadyExists"
};

export const ChangePasswordErrorMapper = {
  "6": "GLChangePass.authenticationFailed",
  "15": "GLChangePass.errorUpdatingPlayer",
  "29": "GLpasswordTooShort",
  "100": "requestDenied"
};

export const ForgotPasswordErrorMapper = {
  "2": "GLForgotPass.playerNotFound",
  "5": "GLForgotPass.requiredFieldMissing",
  "39": "GLForgotPass.invalidEmail",
  "40": "GLForgotPass.errorSendMail"
};

export const GeneralErrorMapper = {
  "6": "GLChangePass.authenticationFailed",
  "1": "GLipNotAuthorized",
  "101": "GLloginWentWrong",
  "8": "GLplayerBlocked",
  "2": "GLForgotPass.playerNotFound",
  "21": "GLChangePass.authenticationFailed",
  ipNotAuthorized: "IpCountryError"
};

export const AllCodeErrorMapper = {
  ...SignUpErrorMapper,
  ...ChangePasswordErrorMapper,
  ...ForgotPasswordErrorMapper
};

export default AllCodeErrorMapper;
