// @flow
import qs from "qs";
import axios from "axios";
import { parseString } from "xml2js/lib/parser";

export default class LogoutProvider {
  request = (endPoint: string, request: any) =>
    new Promise<void>((resolve, reject) => {
      const options = {
        method: "POST",
        url: endPoint,
        headers: { "Content-Type": "multipart/form-data" },
        data: qs.stringify(request)
      };

      axios(options)
        .then(response => {
          parseString(response.data, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
            resolve(result.response);
          });
        })
        .catch(error => {
          reject(error);
        });
    });

  logout = (url: string, request: any) => {
    const time = new Date().getTime();
    const endPoint = `${url}Services/ExternalApi.svc/ClientUserLogout?t=${time}`;
    return this.request(endPoint, request);
  };
}
