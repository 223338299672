import React from "react";
import ActivationInfoComponent from "./ActivationInfoComponent";

const ActivateSuccessComponent = ({ submit }) => {
  return (
    <ActivationInfoComponent
      translationKeys={{
        mainText: "GLActivation.ActivateSuccessMain",
        subText: "GLActivation.ActivateSuccessSub"
      }}
      submit={submit}
    />
  );
};

export default ActivateSuccessComponent;
