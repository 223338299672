import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import serialize from "form-serialize";

import { useDispatch, useSelector } from "react-redux";
import { Input, Grid, Button, InputLabel, FormControl, FormHelperText } from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import { AllCodeErrorMapper } from "src/utils/userUtils";

import { changePassword } from "src/store/components/user/userActions";
import { resetChangePassword } from "src/store/components/user/userSlice";
import { isEmpty } from "src/utils/helpers";

const Wrapper = styled.div`
  padding: 0 60px;
`;
const WrappPassword = styled.div`
  padding: 30px 90px 60px 90px;

  .inputLabelfocused {
  }
  .inputLabel {
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    &.inputLabelfocused {
      color: rgba(255, 255, 26, 1);
    }
  }
  .inputLabelError {
    color: #f44336;
  }
  .fieldFocused {
  }
  .input {
    color: rgba(255, 255, 26, 1);
    &::after {
      border-bottom-width: 0;
    }
    &::before {
      transition: none;
      border-bottom: 1px solid rgba(229, 229, 229, 1);
    }
    &:hover {
      border-bottom-width: 0;
      &::before {
        border-bottom: 1px solid rgba(229, 229, 229, 1) !important;
      }
    }
    &.fieldFocused::after {
      border-bottom: 2px solid rgba(255, 255, 26, 1);
    }
  }
  .fieldError {
    &::before {
      border-bottom: 1px solid #f44336 !important;
    }
  }
  .btn {
    font-size: 16px;
    min-width: 180px;
    min-height: 50px;
    border-radius: 6px;
    cursor: pointer;
    letter-spacing: 0.75px;
    font-weight: 700;
  }
  .closeBtn {
    background-color: #bdbdbd;
    color: rgba(26, 26, 26, 1);
    &:hover {
      background-color: #9e9e9e;
    }
  }
  .submitBtn {
    color: rgba(26, 26, 26, 1);
    background-color: rgba(255, 255, 26, 1);
    &:hover {
      background-color: #e0e016;
    }
  }
  .disabledBtn {
    background-color: rgba(255, 255, 26, 1) !important;
  }
`;
const WrappBtn = styled.div`
  text-align: center;
  margin-top: 45px;
  display: flex;
  justify-content: space-around;
`;
const Form = styled.form``;

const NewPassWrapper = styled((props) => <Grid {...props} />)`
  margin-bottom: 10px;
`;
const StyledFormHelperText = styled((props) => <FormHelperText {...props} />)`
  text-align: left;
`;

const ChangePasswordForm = ({ onClosePopup, onSaveSuccess }) => {
  const { changePasswordStatus, changePasswordError } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const onSubmit = (form) => {
    form.preventDefault();
    form.stopPropagation();
    const formData = serialize(form.currentTarget, { hash: true });
    const validClient = validateClient(formData);
    if (validClient) {
      setDisabled(true);
      dispatch(changePassword(formData));
    }
  };

  useEffect(() => {
    if (changePasswordStatus === "success") {
      onSaveSuccess(true);
    } else if (changePasswordStatus === "error") {
      const errorKey = AllCodeErrorMapper[changePasswordError.errorCode];
      validateServer(errorKey);
    }
  }, [changePasswordStatus]);

  useEffect(() => {
    return () => dispatch(resetChangePassword());
  }, []);

  const validateServer = (failed) => {
    const msg = <FormattedMessage id={`${failed}`}>{(placeholder) => placeholder}</FormattedMessage>;
    setErrors({ newPassword: msg, confirmPassword: msg });
    setDisabled(false);
  };

  const validateClient = (password) => {
    if (isEmpty(password)) {
      const msg = <FormattedMessage id="GLCantBeBlank">{(placeholder) => placeholder}</FormattedMessage>;
      setErrors({ newPassword: msg, confirmPassword: msg });
      return false;
    } else if (isEmpty(password.newPassword)) {
      const msg = <FormattedMessage id="GLCantBeBlank">{(placeholder) => placeholder}</FormattedMessage>;
      setErrors({ newPassword: msg });
      return false;
    } else if (password.newPassword.match(/\s/g, "")) {
      const msg = <FormattedMessage id="GLInvalidPassword">{(placeholder) => placeholder}</FormattedMessage>;
      setErrors({ newPassword: msg });
      return false;
    } else if (isEmpty(password.confirmPassword)) {
      const msg = <FormattedMessage id="GLCantBeBlank">{(placeholder) => placeholder}</FormattedMessage>;

      setErrors({ confirmPassword: msg });
      return false;
    } else if (password.newPassword !== password.confirmPassword) {
      const msg = <FormattedMessage id="GLPasswordsNotMatch">{(placeholder) => placeholder}</FormattedMessage>;
      setErrors({ newPassword: msg });
      return false;
    }

    setErrors({});
    return true;
  };

  const handleOnChange = () => {
    setErrors({});
  };

  return (
    <Wrapper>
      <Form autocomplete="off" onSubmit={onSubmit}>
        <WrappPassword>
          <NewPassWrapper container alignItems="flex-end">
            <Grid item xs={1}>
              <Lock />
              <FormHelperText />
            </Grid>
            <Grid item xs={11}>
              <FormControl fullWidth error={!isEmpty(errors.newPassword)}>
                <InputLabel
                  htmlFor="newPassword"
                  classes={{
                    root: "inputLabel",
                    error: "inputLabelError",
                    focused: "inputLabelfocused"
                  }}
                >
                  <FormattedMessage id="GLNewPassword" />
                </InputLabel>
                <Input
                  classes={{
                    root: "input",
                    error: "fieldError",
                    focused: "fieldFocused"
                  }}
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  inputProps={{ minLength: "3", maxLength: "64" }}
                  onChange={handleOnChange}
                />
                <StyledFormHelperText id="newPassword-error-text">{errors.newPassword}</StyledFormHelperText>
              </FormControl>
            </Grid>
          </NewPassWrapper>
          <Grid container alignItems="flex-end">
            <Grid item xs={1}>
              <Lock />
              <FormHelperText />
            </Grid>
            <Grid item xs={11}>
              <FormControl fullWidth error={!isEmpty(errors.confirmPassword)}>
                <InputLabel
                  htmlFor="confirmPassword"
                  classes={{
                    root: "inputLabel",
                    error: "inputLabelError",
                    focused: "inputLabelfocused"
                  }}
                >
                  <FormattedMessage id="GLConfirmPassword" />
                </InputLabel>
                <Input
                  classes={{
                    root: "input",
                    error: "fieldError",
                    focused: "fieldFocused"
                  }}
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  inputProps={{ minLength: "3", maxLength: "64" }}
                  onChange={handleOnChange}
                />
                <StyledFormHelperText id="confirmPassword-error-text">{errors.confirmPassword}</StyledFormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <WrappBtn>
            <Button
              type="button"
              variant="contained"
              color="inherit"
              className={`${"btn"} ${"closeBtn"}`}
              onClick={() => {
                onClosePopup();
              }}
            >
              <FormattedMessage id="GLgoClose" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="inherit"
              classes={{
                root: `${"btn"} ${"submitBtn"}`,
                disabled: "disabledBtn"
              }}
              disabled={disabled}
            >
              <FormattedMessage id="GLSubmit" />
            </Button>
          </WrappBtn>
        </WrappPassword>
      </Form>
    </Wrapper>
  );
};

export default ChangePasswordForm;
