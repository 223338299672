import React from "react";
import { FormattedMessage } from "react-intl";

const InlineError = ({ error, className }) => (
  <span className={className}>
    <FormattedMessage id={error} values={{ breakLine: <br /> }} />
  </span>
);

export default InlineError;
