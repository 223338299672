import React from "react";
import styled from "styled-components";
import { getBP } from "src/utils/styleUtils";
import { numberWithCommas } from "src/helpers";

const GameLimit = styled.div`
  position: absolute;
  top: 5%;
  left: 3%;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  background: rgba(17, 17, 26, 0.5);
  padding: 0 10px;
  padding-top: 1px;
  border-radius: 12px;
  transition: 0.2s linear;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) and (orientation: landscape) {
    font-size: 11px;
  }

  &:hover {
    color: #1a1a1a;
    background: rgba(255, 255, 26, 0.7);
  }

  &.smallGrid {
    font-size: 13px;
    height: 19px;
    line-height: 19px;
    padding: 0 8px;
    border-radius: 9.5px;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 5%;
    left: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625em;
    background: rgba(17, 17, 26, 0.5);
    padding: 0 1em;
    border-radius: 12px;
    transition: 0.2s linear;
    height: 16px;
  }
`;

const GameLimitComponent = ({ item, symbol, smallGrid }) => (
  <GameLimit className={"limit" + (smallGrid ? " smallGrid" : "")}>
    {symbol} {numberWithCommas(item.limitSet.minBet)}
  </GameLimit>
);

export default GameLimitComponent;
