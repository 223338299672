import React from "react";
import ActivationInfoComponent from "./ActivationInfoComponent";

const ActivationFailedComponent = ({ submit }) => {
  return (
    <ActivationInfoComponent
      translationKeys={{
        mainText: "GLActivation.ActivateFailedMain",
        subText: "GLActivation.ActivateFailedSub"
      }}
      submit={submit}
      isFail
    />
  );
};

export default ActivationFailedComponent;
