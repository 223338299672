import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Icon from "../../ui/Icon";
import StickyBottom from "../../Mobile/Components/StickyBottom";

const Button = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  height: 24px;
  width: 60px;
  border-radius: 12px;
  background: rgba(178, 178, 178, 0.9);
  text-align: center;
  cursor: pointer;
  margin-top: 22px;
`;

const ScrollTopButton = ({ isOpen }) => {
  const [showScroll, setShowScroll] = useState(false);

  const scrollTop = () => {
    const scrollMobile = document.getElementById("custom-sidebar-content-id");
    const scrollDesktop = document.getElementById("scrollList");

    if (scrollDesktop) {
      scrollDesktop.style.overflow = "hidden";
      scrollDesktop.scrollTo({ top: 0, behavior: "auto" });
      scrollDesktop.style.overflow = "auto";
    }
    if (scrollMobile) {
      scrollMobile.style.overflow = "hidden";
      // fixes scroll to top on mobile
      scrollMobile.scrollTo({ top: 0, behavior: "smooth" });
      // scrollMobile.scrollTo({ top: 0, behavior: "auto" });
      scrollMobile.style.overflow = "auto";
    }

    setShowScroll(false);
  };

  const checkScrollTop = useCallback(
    (elId) => {
      const scrollList = document.getElementById(elId);

      if (!showScroll && scrollList.scrollTop > window.innerHeight) {
        setShowScroll(true);
      }

      if (showScroll && scrollList.scrollTop <= window.innerHeight) {
        setShowScroll(false);
      }
    },
    [showScroll]
  );

  useEffect(() => {
    const elIdMobile = "custom-sidebar-content-id";
    const scrollMobile = document.getElementById(elIdMobile);
    const callbackMobile = () => checkScrollTop(elIdMobile);

    const elIdDesktop = "scrollList";
    const scrollDesktop = document.getElementById(elIdDesktop);
    const callbackDesktop = () => checkScrollTop(elIdDesktop);

    if (scrollMobile) scrollMobile.addEventListener("scroll", callbackMobile);
    if (scrollDesktop) scrollDesktop.addEventListener("scroll", callbackDesktop);
    return () => {
      if (scrollMobile) scrollMobile.removeEventListener("scroll", callbackMobile);
      if (scrollDesktop) scrollDesktop.removeEventListener("scroll", callbackDesktop);
    };
  }, [checkScrollTop]);

  return (
    <StickyBottom>
      {showScroll && !isOpen && (
        <Button onClick={scrollTop}>
          <Icon height="16" width="14" name="arrowUp" fill="#1a1a1a" />
        </Button>
      )}
    </StickyBottom>
  );
};

export default ScrollTopButton;
