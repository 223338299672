// @flow
import axios from "axios";
import { LogglyInputType, LogglyBulkType, LogglyTag, LogglyToken, LogglyUrl } from "../../configs/logger";
import type { LoggingRequest } from "../clientModels/LoggingRequest";

import * as LoggingMapper from "../mappers/LoggingMapper";

export default class LoggingProvider {
  logs: Array<*>;

  constructor() {
    this.logs = [];
  }

  request = (endPoint: string, request: *) => {
    const options = {
      method: "POST",
      url: endPoint,
      headers: { "Cache-Control": "no-cache" },
      data: request
    };

    axios(options)
      .then(response => {
        if (response.status === 200) {
          this.logs = [];

          console.log("Loggly event sent Status: " + JSON.stringify(response.status));
        } else {
          console.log("Loggly event sent Status: " + JSON.stringify(response.status));
        }
      })
      .catch(error => {
        console.log("Loggly event Error: " + JSON.stringify(error));
      });
  };

  send = (): void => {
    const logsLength = this.logs.length;

    if (logsLength > 0) {
      const url = getLoggerUrl(logsLength > 1 ? LogglyBulkType : LogglyInputType);

      let data = "";
      this.logs.reduce((counter: *, element) => data + JSON.stringify(element) + "\n");

      this.request(url, JSON.stringify(data));
    }
  };

  Warn = (request: LoggingRequest): void => {
    this.logs.push({
      ...LoggingMapper.mapLoggerRequest({ ...request, level: "Warning" })
    });
  };

  Info = (request: LoggingRequest): void => {
    this.logs.push({
      ...LoggingMapper.mapLoggerRequest({ ...request, level: "Info" })
    });
  };

  Critical = (request: LoggingRequest): void => {
    this.logs.push({
      ...LoggingMapper.mapLoggerRequest({ ...request, level: "Critical" })
    });
  };

  Error = (request: LoggingRequest): void => {
    this.logs.push({
      ...LoggingMapper.mapLoggerRequest({ ...request, level: "Error" })
    });
  };
}

const getLoggerUrl = (logType: string): string => {
  return LogglyUrl + logType + LogglyToken + LogglyTag;
};
