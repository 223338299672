// @flow
import React from "react";
import { Switch, Route } from "react-router-dom";
import GamesComponent from "../Components/GameCategories/GamesComponent";
import LoginFormComponent from "../Components/User/LoginFormComponent";
import SignUpComponent from "../Components/User/SignUpComponent";
import ChangePasswordComponent from "../Components/User/ChangePasswordComponent";
import ChangePasswordSuccessComponent from "../Components/User/ChangePasswordsSuccessComponent";
import ForgotPasswordComponent from "../Components/User/ForgotPasswordComponent";
import AccountValidationComponent from "../Components/User/AccountValidationComponent";
import AccountActivationComponent from "../Components/User/AccountActivationComponent";
import ForgotPasswordValidation from "../Components/User/ForgotPasswordValidation";
import StartComponent from "../Components/User/StartComponent";
import ErrorPage from "../pages/Error";
import WelcomePage from "../pages/Welcome";

import { PublicRoute, ProtectedRoute, LoaderRoute } from "./hocs";

const Routes = () => (
  <Switch>
    {/* ProtectedRoute */}
    <ProtectedRoute exact replace path="/category/:id" component={GamesComponent} />

    <ProtectedRoute sensitive exact replace path="/auth" component={GamesComponent} />

    <ProtectedRoute exact path="/changePasswordSuccess" component={ChangePasswordSuccessComponent} />

    {/* PublicRoute */}
    <PublicRoute exact path="/changePassword" component={ChangePasswordComponent} />

    <PublicRoute exact path="/forgotPassword" component={ForgotPasswordComponent} />

    <PublicRoute exact path="/login" component={LoginFormComponent} />

    <PublicRoute exact path="/signUpSuccess" component={AccountValidationComponent} />

    <PublicRoute exact path="/activate" component={AccountActivationComponent} />

    <Route path="/forgotPasswordSent" component={ForgotPasswordValidation} />

    <Route path="/forgotPasswordSentFailed/" component={(props) => <ForgotPasswordValidation isFail {...props} />} />

    <PublicRoute exact path="/activationFailed" component={() => <AccountValidationComponent isFail />} />

    <PublicRoute exact path="/signUp" component={SignUpComponent} />

    <PublicRoute exact path="/welcome" component={WelcomePage} />

    <LoaderRoute exact replace path="/error" component={ErrorPage} />

    <PublicRoute replace sensitive path="/" component={StartComponent} />
  </Switch>
);

export default Routes;
