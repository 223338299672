import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { changeGridType } from "src/store/components/header/actions";
import GridTypeItem from "./GridTypeItem";
import ControlBtn from "./components/ControlBtn";

const ToolBar = styled.div`
  float: right;
  margin: 10px 10px 0 0;
`;
const Area = styled.div`
  margin-left: 10px;
  display: inline-block;

  & .icon-star {
    margin-right: 20px;
    cursor: pointer;

    &:hover path {
      fill: #ffff1a;
    }
  }
`;
const ControlBtnWrapper = styled.div`
  display: inline-block;
`;

const ToolBarComponent = () => {
  const dispatch = useDispatch();
  const { gridType } = useSelector((state) => state.gameCategories);

  const bigGridSelected = `/assets/img/lobby/header/bigGrid_selected.svg`;
  const bigGrid = `/assets/img/lobby/header/bigGrid.svg`;
  const smallGridSelected = `/assets/img/lobby/header/smallGrid_selected.svg`;
  const smallGrid = `/assets/img/lobby/header/smallGrid.svg`;

  return (
    <ToolBar>
      <Area>
        <GridTypeItem
          name={"bigGrid"}
          handler={() => dispatch(changeGridType(0))}
          image={!gridType ? bigGridSelected : bigGrid}
        />
        <GridTypeItem
          name={"smallGrid"}
          handler={() => dispatch(changeGridType(1))}
          image={!!gridType ? smallGridSelected : smallGrid}
        />
      </Area>
      <ControlBtnWrapper>
        <ControlBtn />
      </ControlBtnWrapper>
    </ToolBar>
  );
};

export default ToolBarComponent;
