// @flow

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { BackgroundContext } from "src/contexts";
import { defaultBackground } from "src/Components/ui/base64/defaultBackground";

const BackgroundProvider = ({ children }) => {
  const { cdnPath, GLTheme: backgroundPath } = useSelector((state) => state.startUp);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (backgroundPath !== null) {
      if (cdnPath.length) {
        axios
          .get(`${cdnPath}GLTheme/${backgroundPath}/theme.svg`)
          .then(function (response) {
            setImage(`"data:image/svg+xml;base64,${window.btoa(decodeURIComponent(response.data))}"`);
          })
          .catch(function (error) {
            setImage(defaultBackground);
          });
      } else {
        setImage(defaultBackground);
      }
    }
  }, []);

  return <BackgroundContext.Provider value={{ image }}>{children}</BackgroundContext.Provider>;
};

export default BackgroundProvider;
