import styled from "styled-components";
import { getBP } from "src/utils/styleUtils";

export const FixedHeader = styled.header`
  position: relative;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
  }
`;
