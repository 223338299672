import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  z-index: 15;
  top: 80%;

  @media (orientation: landscape) {
    top: 70%;
  }
  @media screen and (min-width: 1024px) {
    top: inherit;
    bottom: 100px;
  }
`;

const StickyBottom = ({ children }) => {
  const getPos = () => window.innerHeight - 70;

  const usePosition = () => {
    let [pos, setPos] = useState(getPos());
    useEffect(() => {
      const resizeListener = (event) => {
        setPos(getPos());
      };

      window.visualViewport && window.visualViewport.addEventListener("resize", resizeListener);
      window.addEventListener("resize", resizeListener);
      return () => {
        window.removeEventListener("resize", resizeListener);
        window.visualViewport && window.visualViewport.removeEventListener("resize", resizeListener);
      };
    }, []);
    return pos;
  };

  const styles = {
    top: `${usePosition()}px`
  };

  return <Wrapper style={window.visualViewport ? styles : {}}>{children}</Wrapper>;
};

export default StickyBottom;
