import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSlotsFilter } from "src/store/components/gameCategories/gameActions";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { SLOT_FILTER_ITEMS } from "src/constants";
import Icon from "src/Components/ui/Icon";

const Wrap = styled.div`
  height: calc(100% - 20px);
`;

const WrapInner = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto;
  overscroll-behavior: contain;
  background: rgb(62, 59, 78);
  background: linear-gradient(
    45deg,
    rgba(62, 59, 78, 0.95) 0%,
    rgba(60, 66, 80, 0.95) 50%,
    rgba(62, 59, 78, 0.95) 100%
  );
`;

const FilterHeader = styled.div`
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 20px;
  letter-spacing: 1px !important;
  .label {
    text-transform: uppercase;
    margin-right: 5px;
  }
  .value {
    color: #b3b3b3;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0 0 100px 0;
  margin: 0;
  text-align: left;
`;

const Item = styled.li`
  padding: 6px 0;
  &:nth-child(even) {
    > span {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-right: 10px;
  .icon {
    margin: 1px 5px;
  }
`;

const Filter = styled.span`
  cursor: pointer;
  font-size: 14px;
  padding: 5px 20px;
  color: #e6e6e6;
  display: flex;
  &.active {
    color: #ffff1a;
    .icon {
      svg {
        path {
          fill: rgba(255, 255, 26, 1);
        }
      }
    }
    .check {
      border-color: #ffff1a;
    }
  }
`;

const Label = styled.span`
  line-height: 22px;
`;

const Filters = () => {
  const { slotLinesFilter, selectedCategory } = useSelector((state) => state.gameCategories);
  const dispatch = useDispatch();

  const addFilter = (value) => {
    let filterByCategory = slotLinesFilter.find((item) => item[selectedCategory]) || [];
    const lines = slotLinesFilter.filter((item) => Object.keys(item)[0] !== selectedCategory);

    let newValue = [...filterByCategory[selectedCategory], value];
    dispatch(setSlotsFilter([{ [selectedCategory]: newValue }, ...lines]));
  };

  const removeFilter = (value) => {
    let filterByCategory = slotLinesFilter.find((item) => item[selectedCategory]) || [];
    const lines = slotLinesFilter.filter((item) => Object.keys(item)[0] !== selectedCategory);
    if (filterByCategory[selectedCategory].length > 1) {
      let newValue = filterByCategory[selectedCategory].filter((item) => item.toString() !== value.toString());
      dispatch(setSlotsFilter([{ [selectedCategory]: newValue }, ...lines]));
    }
  };

  const handleScroll = (e) => {
    e.stopPropagation();
  };

  let filterByCategory = slotLinesFilter.find((item) => item[selectedCategory]) || [];

  return (
    <Wrap>
      <WrapInner onTouchMove={handleScroll}>
        <FilterHeader>
          <span className="label">
            <FormattedMessage id="MGFilter.SortLabel" defaultMessage="Sort By: " />
          </span>
          <span className="value">
            {filterByCategory[selectedCategory].length === SLOT_FILTER_ITEMS.length ? (
              <FormattedMessage id="MGFilter.All" />
            ) : (
              ` ${filterByCategory[selectedCategory].length} Checked`
            )}
          </span>
        </FilterHeader>
        <List>
          {SLOT_FILTER_ITEMS.map((filter) => {
            const isActive = filterByCategory[selectedCategory].find(
              (item) => item.toString() === filter.value.toString()
            );

            return (
              <Item key={`${filter.value.toString()}-filter`} id={`${filter}-filter`}>
                <Filter
                  className={isActive && "active"}
                  onClick={(event) => {
                    event.preventDefault();
                    isActive ? removeFilter(filter.value) : addFilter(filter.value);
                  }}
                >
                  <Checkbox className="check">
                    <Icon name="check" fill="#4c4c4c" width="10" height="8" />
                  </Checkbox>
                  <Label>
                    <FormattedMessage id={filter.text} />
                  </Label>
                </Filter>
              </Item>
            );
          })}
        </List>
      </WrapInner>
    </Wrap>
  );
};

export default Filters;
