import styled from "styled-components";
import { isMobile } from "src/helpers";

const DivWrapper = styled.div``;
const SimpleBarWrapper = styled.div`
  & {
    scrollbar-width: none;
    scrollbar-color: #403d49 #00000000;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #00000000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #403d49;
    border-radius: 10px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #838289;
  }
`;

export const ScrollWrapper = !isMobile ? SimpleBarWrapper : DivWrapper;
