import React from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { isInArray } from "src/utils/helpers";
import { useDispatch } from "react-redux";
import BackButton from "./button";
import { loginWentWrong } from "../../../store/components/user/userSlice";
import { setError } from "../../../store/components/error/errorSlice";

const Button = ({ data, history }) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    localStorage.removeItem("referrer");
    localStorage.removeItem("homeUrl");
    if (isInArray(data.key, ["referrer", "homeUrl"])) {
      window.location.replace(data.url);
    } else if (data.key === "loginForm") {
      localStorage.removeItem("token");
      dispatch(loginWentWrong(""));
      dispatch(
        setError({
          code: null,
          description: null,
          priority: null
        })
      );
      history.replace("/login");
    } else if (data.key === "history") {
      window.history.back();
    }
  };

  return (
    <BackButton handleOnClick={handleOnClick}>
      {isInArray(data.key, ["referrer", "loginForm"]) ? (
        <FormattedMessage id="GLgoToLogin">{(msg) => <span data-name="GL-goToLogin">{msg}</span>}</FormattedMessage>
      ) : (
        <FormattedMessage id="GLgoBack">{(msg) => <span data-name="GL-goBack">{msg}</span>}</FormattedMessage>
      )}
    </BackButton>
  );
};

export default withRouter(Button);
