import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const TableOpenWrapper = styled.div`
  position: absolute;
  bottom: 2px;
  z-index: 99;
  width: 100%;
`;
const TableOpen = styled.div`
  background-color: #ffff1a;
  border: 1px solid #ffff1a;
  border-radius: 2px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  width: 72px;
  height: 20px;
  color: #1a1a1a;
  font-family: Roboto, Arial;
  text-transform: uppercase;
  margin: 0 auto;
`;

const OpenedTableComponent = () => (
  <TableOpenWrapper>
    <TableOpen>
      <FormattedMessage id="GLgameOpened" values={{ breakLine: <br /> }} />
    </TableOpen>
  </TableOpenWrapper>
);

export default OpenedTableComponent;
