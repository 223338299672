// @flow
import React from "react";
import { useSelector } from "react-redux";
import { ErrorContext } from "../../contexts";

const ErrorProvider = ({ children }) => {
  const {
    error,
    startUp: { loginType }
  } = useSelector((state) => state);
  return <ErrorContext.Provider value={{ error, loginType }}>{children}</ErrorContext.Provider>;
};

export default ErrorProvider;
