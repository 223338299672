// @flow

import qs from "qs";
import axios from "axios";
import type { BalanceRequest } from "../clientModels/BalanceRequest";
import type { BalanceResponse } from "../clientModels/BalanceResponse";
import * as BalanceMapper from "../mappers/BalanceMapper";
import * as MapResponse from "../utils/MapResponse";
import { parseString } from "xml2js/lib/parser";

export default class BalanceProvide {
  request = (endPoint: string, request: *, responseResolver: *) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        url: endPoint,
        headers: { "Content-Type": "multipart/form-data" },
        data: qs.stringify(request)
      };

      axios(options)
        .then(response => {
          let responseData = {};

          parseString(response.data, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
            responseData = result;
          });

          if (response.status === 200) {
            if (parseInt(responseData.response.errorCode, 10) === 0) {
              let res = MapResponse.success(responseData.response);
              resolve(responseResolver(res));
            } else {
              console.log("server response encounter error with status: " + JSON.stringify(responseData));
              reject(MapResponse.error(responseData.response));
            }
          } else {
            console.log("server response encounter error with status: " + response.status);
            throw MapResponse.exception(response.status, response.statusText);
          }
        })
        .catch(error => {
          // TODO add here logic for error handling/showing requests exeption on UI(visual representation)
          reject(error);
          throw MapResponse.exception(error.name, error.message);
        });
    });

  getBalanceAndExtendToken = (apiClientUrl, request: BalanceRequest): Promise<BalanceResponse> => {
    const time = new Date().getTime();
    const url = `${apiClientUrl}Services/ExternalApi.svc/clientGetPlayerBalanceAndExtendLoginToken?t=${time}`;
    return this.request(
      url,
      {
        ...BalanceMapper.mapBalanceRequest(request)
      },
      BalanceMapper.resolveResponse
    );
  };
}
