import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Button } from "@material-ui/core";
import { getBP } from "src/utils/styleUtils";

const Wrapper = styled.div`
  padding: 45px 115px 40px 115px;
  @media (min-width: ${({ theme }) => getBP(theme, "mobile")}) {
    padding: 25px 40px 30px 40px;
  }

  @media (min-width: ${({ theme }) => getBP(theme, "desktop")}) {
    padding: 45px 115px 40px 115px;
  }

  .btn {
    font-size: 20px;
    margin-bottom: 20px;
    min-width: 100%;
    min-height: 50px;

    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      min-height: 40px;
      font-size: 16px;
    }

    color: #1a1a1a;
    background-color: #ffff1a;
    &:hover {
      background-color: #e5e517;
    }
    border-radius: 6px;
    cursor: pointer;
    letter-spacing: 0.75px;
    font-weight: 700;
  }
`;

const WrappBtns = styled.div`
  text-align: center;
  display: block;
  justify-content: space-around;
`;

const CommissionSwitchComponent = ({ onClosePopup }) => {
  return (
    <Wrapper>
      <WrappBtns>
        <Button type="button" variant="contained" color="inherit" className="btn" onClick={() => onClosePopup(true)}>
          <FormattedMessage id="noCommission" defaultMessage="No Commissions" />
        </Button>
        <Button type="button" variant="contained" color="inherit" className="btn" onClick={() => onClosePopup(false)}>
          <FormattedMessage id="commission" defaultMesage="Commission" />
        </Button>
      </WrappBtns>
    </Wrapper>
  );
};

export default CommissionSwitchComponent;
