// @flow

import qs from "qs";
import axios from "axios";
import type { LoginRequest } from "../clientModels/LoginRequest";
import type { LoginWithTokenRequest, LoginMGLRequest } from "../clientModels/LoginWithTokenRequest";
import type { LoginResponse } from "../clientModels/LoginResponse";
import * as LoginMapper from "../mappers/LoginMapper";
import * as MapResponse from "../utils/MapResponse";

import { parseString } from "xml2js/lib/parser";

export default class LoginProvider {
  request = (uri: string, request: *, responseResolver: *) => {
    const time = new Date().getTime();
    const endPoint = `${uri}?t=${time}`;
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        url: endPoint,
        headers: { "Content-Type": "multipart/form-data" },
        data: qs.stringify(request)
      };

      axios(options)
        .then(response => {
          let responseData = {};

          parseString(response.data, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
            responseData = result;
          });

          if (response.status === 200) {
            if (parseInt(responseData.response.errorCode, 10) === 0) {
              localStorage.removeItem("loginWithToken")
              let res = MapResponse.success(responseData.response);
              resolve(responseResolver(res));
            } else {
              console.log("server response encounter error with status: " + JSON.stringify(responseData));
              localStorage.setItem("loginWithToken", JSON.stringify(responseData.response));
              reject(MapResponse.error(responseData.response));
            }
          } else {
            console.log("server response encounter error with status: " + response.status);
            throw MapResponse.exception(response.status, response.statusText);
          }
        })
        .catch(error => {
          // TODO add here logic for error handling/showing requests exeption on UI(visual representation)
          reject(error);
          throw MapResponse.exception(error.name, error.message);
        });
    });
  };

  login = (apiClientUrl, request: LoginRequest): Promise<LoginResponse> =>
    this.request(
      apiClientUrl + "Services/ExternalApi.svc/mobileUserLogin",
      {
        ...LoginMapper.mapLoginRequest(request)
      },
      LoginMapper.resolveResponse
    );

  loginWithToken = (apiClientUrl, request: LoginWithTokenRequest): Promise<LoginResponse> => {
    return this.request(
      apiClientUrl + "Services/ExternalApi.svc/mobileUserLogin",
      {
        ...LoginMapper.mapLoginWithTokenRequest(request)
      },
      LoginMapper.resolveResponse
    );
  };

  loginMGL = (apiClientUrl, request: LoginMGLRequest): Promise<LoginResponse> => {
    return this.request(
      apiClientUrl + "Services/ExternalApi.svc/miniGamesLobbyLogin",
      {
        ...LoginMapper.mapLoginMGLRequest(request)
      },
      LoginMapper.resolveResponse
    );
  };
}
