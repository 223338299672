import React from "react";
import styled from "styled-components";

const Icon = styled.div`
  padding: 20% 2% 1% 2%;
  text-align: center;

  & img {
    width: 100%;
    max-width: 207px;
    height: auto;
  }
`;
const Title = styled.div`
  margin: 0;
  color: #fff;
  font-size: 24px;
  text-align: center;
`;
const WelcomeBox = ({ icon, title }) => {
  return (
    <>
      <Icon>{icon}</Icon>
      <Title>{title}</Title>
    </>
  );
};

export default WelcomeBox;
