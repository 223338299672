import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Icon from "../ui/Icon";
import { numberWithCommas } from "src/helpers";

const Wrapper = styled.div`
  /* position: fixed; */
  width: 100%;
  /* top: 0; */
  display: flex;
  justify-content: space-between;
  height: 20px;
  background: #9e9fa7;
  z-index: 11;
`;
const Item = styled.div`
  padding: 0 8px;
  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

  & span {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: bold;
  }

  & .icon {
    margin-right: 8px;
  }
`;

const Currency = styled.span`
  margin-right: 4px;
`;

const UserMobileComoponent = () => {
  const { userName, currencySymbol, balance } = useSelector((state) => state.user);

  return (
    <Wrapper>
      <Item>
        <Icon width="16" height="16" name="user" fill="#1a1a1a" />
        <span data-name="GL-userName">{userName}</span>
      </Item>
      <Item>
        <Icon width="16" height="16" name="balance" fill="#1a1a1a" />
        <span>
          <Currency data-name="GL-currency">{currencySymbol}</Currency>
          <span data-name="GL-balance">{numberWithCommas(balance)}</span>
        </span>
      </Item>
    </Wrapper>
  );
};

export default UserMobileComoponent;
