// @flow

import React, { PureComponent } from "react";
import HistoryProvider from "src/provider/History";

const withHistory = (ComposedComponent: Function) => {
  class History extends PureComponent<any> {
    render() {
      return (
        <HistoryProvider>
          <ComposedComponent {...this.props} />
        </HistoryProvider>
      );
    }
  }

  return History;
};

export default withHistory;
