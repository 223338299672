import React from "react";
import styled, { css } from "styled-components";
import { useDeviceState } from "src/utils/helpers";

const Colors = {
  green: "#00CF0A",
  grey: "#C6BCCC",
  yellow: "#ffff1a"
};

const LastResultWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${({ isLandscape, isMobile, isUBJ }) => (isLandscape && isMobile && isUBJ ? "0em" : "0.2em")};
    ${({ isUBJ, isMobile }) =>
      !isUBJ
        ? css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `
        : css`
            div {
              margin-top: ${({ isUBJ, isMobile, isLandscape }) => isUBJ && (!isLandscape ? ".3em" : "0em")};
              margin-right: 0.35em;
              @media screen and (max-width: 768px) {
                margin-top: 0.35em;
              }
            }
          `};
  }
`;
const ResultItem = styled.div`
  position: relative;
  width: 11.5%;
  height: 0;
  float: left;
  padding-bottom: 11.5%;
`;
const ResultItemSvg = styled.svg`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

const getCustomSVG = (key, color, seatNumber) => {
  const { isMobile, isLandscape } = useDeviceState();
  const isUBJ = seatNumber === 1;
  return (
    <ResultItem key={key} isUBJ={isUBJ}>
      <ResultItemSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 42 42"
        width={isMobile && !isLandscape && "20px"}
        height={isMobile && !isLandscape && "20px"}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path fill={color} d="M21,21A6.86,6.86,0,1,0,14.18,14v.06A6.84,6.84,0,0,0,21,21" />
            <path
              fill={color}
              d="M32.38,28.78a11.73,11.73,0,0,0-5.59-8.68L26.57,20l-.17.19a7.44,7.44,0,0,1-10.51.33l-.33-.33L15.39,20l-.21.13a11.77,11.77,0,0,0-5.6,8.68v.15l.1.11a14.18,14.18,0,0,0,19.85,2.82A14.77,14.77,0,0,0,32.35,29l.09-.11Z"
            />
            <path
              fill={color}
              d="M21,2.5A18.5,18.5,0,1,1,2.5,21,18.52,18.52,0,0,1,21,2.5M21,0A21,21,0,1,0,42,21,21,21,0,0,0,21,0Z"
            />
            <path
              fill={color}
              d="M21,4.5A16.5,16.5,0,1,1,4.5,21,16.52,16.52,0,0,1,21,4.5M21,4A17,17,0,1,0,38,21,17,17,0,0,0,21,4Z"
            />
          </g>
        </g>
      </ResultItemSvg>
    </ResultItem>
  );
};

const shuffleArray = () => {
  let array = [0, 1, 2, 3, 4, 5, 6];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

const highlightSeated = (seatedPlayers) => {
  return shuffleArray().slice(0, seatedPlayers);
};

const drawItems = (collection) => {
  let lastResultsArray = [];
  let highlightSeatedRes = highlightSeated(collection.playersNumbersInGame);
  let seatsNumber = collection.playersNumber;

  for (let i = 0; i < seatsNumber; i++) {
    let color = highlightSeatedRes.includes(i) && seatsNumber === 7 ? Colors.yellow : Colors.grey;
    lastResultsArray.push(getCustomSVG(i, color, seatsNumber));
  }

  return lastResultsArray;
};

const BlackJackLastResults = ({ collection }) => {
  const isUBJ = collection.playersNumber === 1;
  const { isMobile, isLandscape } = useDeviceState();

  return (
    <LastResultWrapper isUBJ={isUBJ} isMobile={isMobile} isLandscape={isLandscape}>
      {drawItems(collection)}
    </LastResultWrapper>
  );
};

export default BlackJackLastResults;
