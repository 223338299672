// @flow
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext, ErrorContext, LoadingContext } from "src/contexts";
import LoadingApp from "src/Components/LoadingApp";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <LoadingContext.Consumer>
      {({ isLoadingApp }) => (
        <ErrorContext.Consumer>
          {({ error }) => (
            <AuthContext.Consumer>
              {({ isAuth }) => {
                if (isLoadingApp) {
                  return <LoadingApp />;
                } else if (!isAuth) {
                  return <Redirect to="/login" />;
                } else if (error.priority === "high") {
                  return <Redirect replace to="/error" />;
                }

                return <Route {...rest} render={(props) => <Component {...props} />} />;
              }}
            </AuthContext.Consumer>
          )}
        </ErrorContext.Consumer>
      )}
    </LoadingContext.Consumer>
  );
};

export default ProtectedRoute;
