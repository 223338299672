import React, { useState } from "react";
import styled from "styled-components";
import { getBP, onlyMd } from "src/utils/styleUtils";

const DealerImage = styled.div`
  position: absolute;
  top: 4%;
  right: 3%;
  width: 110px;
  height: 110px;

  @media (max-width: ${({ theme }) => getBP(theme, "md")}) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) and (orientation: landscape) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: ${({ theme }) => getBP(theme, "md")}) and (max-width: ${({ theme }) => getBP(theme, "lg")}) {
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: 769px) {
    &.smallGrid {
      width: 60px;
      height: 60px;
      @media (min-width: ${({ theme }) => onlyMd(theme).min}) and (max-width: ${({ theme }) => onlyMd(theme).max}) {
        width: 40px;
        height: 40px;

        & img {
          width: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 2em;
    height: 2em;
  }

  border: 1px solid #86818f;
  border-radius: 50%;
  overflow: hidden;
  background: #605d67;
`;
const Image = styled.img`
  width: 100%;
  vertical-align: middle;
`;
const LoaderImg = styled.img`
  height: 70%;
  width: auto;
  margin: 0 auto;
  margin-top: 15%;
  display: block;
  vertical-align: middle;
`;

const AsyncImage = ({ source, smallGrid }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <DealerImage data-name="GL-dealer-image" className={smallGrid ? "smallGrid" : ""}>
      <Image
        src={source}
        style={!loaded ? { visibility: "hidden", display: "none" } : {}}
        onLoad={() => setLoaded(true)}
        alt="Live Dealer"
      />
      {!loaded && (
        <LoaderImg
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABQCAMAAACalFNKAAAAM1BMVEUAAACGgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY+GgY86uzVbAAAAEHRSTlMAEECfIO+/z4CvYDBw31CP/csR3AAAAoNJREFUWMOVmNt6KyEIhUcRD+NheP+n3bsxjTYewHU1/Zr+BQcWmGut8liqcsHr61jqQfojBHVGSEiD8D4hAE0F8iQcLeSkyVhaKsgID23kJQRNW2kBwu0RjicUYlRYRGAI/IkaYmW4suQRia0JVpZBkEDCorDlUh7niLhF3H2+K8i+Y317cWuIF5wmquvaQCyDGH1B+SNEnp6XAeqV+XeK9XkNYRCrQA0cIEAv2icQYWAR+zbSPqqKYPyG7QDHusXWpX+iCGx1Fs7UPIsALszEd2pZpmJu3sT1voItNQRjnWb7WyVxLdhO62uvnUsrPEHAKgg5gvTqqOWIrIY08iFirGJLpwiC5e4kH6nQm3CgJiPfT/LnTKOTbyn+y6vLqzuBmSPMdpHz2YahSCTFjtQq32/AmLxwqIa/h3a/fw63uoosEzXuQ0br94Msk3s39bIgE3X3n3p2S7G7FTPwpiUYv64nwwcEC4DdbiqmFTDrF03OdDHiO7jSR5fSJ2GVUv8PC7z/IP4loFffudeE6zHp2eaDpr8EOTMfKf81zSy67pr01GcmdT2/fj0vXCPwiJHxE3yoOS0QLme3ekUGqz+a9opHRNCvp7CwKl/DSHXFmwjAtNKF5XhLl239cK7nVcecn/JufXG3Pv42eYVh7Bxe7cN7VD7fpYAWbtV7CVj8LhKoFfXbIfcXonXN7EKhPwSM7XIL03Z+fSDmmRVAX3F3ZagRQRhrmANCQTPRxnBG08hQBmlEGNcIPQMfGmUtjXpwNHKNdCzUg4McysWhZeCMALNJUvAgibL6OkxKeNZD1YiyqT7CQHjAHpIyLZWTukSKydJENsUzQ0pgP+FkC2lpbf8ARLl/O/PWGHsAAAAASUVORK5CYII="
          alt="Live Dealer"
        />
      )}
    </DealerImage>
  );
};

export default AsyncImage;
