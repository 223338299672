// @flow
import type { GAService } from "../services/GAService";
import { gaUI } from "../../configs/servicesConfig";
window.dataLayer = window.dataLayer || [];

function gtag(...args: any) {
  // $FlowFixMe
  window.dataLayer.push(arguments);
}

export default class GAServiceProvider implements GAService {
  operatorId: number;

  constructor() {
    this.operatorId = -1;
    gtag("js", new Date());
  }

  setOperatorId = (operatorId: number): void => {
    this.operatorId = operatorId;
  };

  sendEvent(eventName: string, eventLabel: string): void {
    gtag("event", eventName, {
      event_category: "operatorID=" + this.operatorId,
      event_label: eventLabel,
      OperatorID: this.operatorId
    });
  }

  sendPageView(page: string): void {
    page
      ? gtag("config", gaUI, {
          page_title: page,
          page_path: "/" + page
        })
      : gtag("config", gaUI);
  }
}
