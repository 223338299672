import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import renderResultComponent from "./GameResults";
import { getBP, onlyMd } from "src/utils/styleUtils";
import { useDeviceState } from "src/utils/helpers";
import PlayersCountComponent from "./PlayersCountComponent";

const GameName = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) and (orientation: landscape) {
    font-size: 11px;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.75em;
  }
`;

const DealerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  @media (min-width: ${({ theme }) => onlyMd(theme).min}) and (max-width: ${({ theme }) => onlyMd(theme).max}) {
    font-size: 12px;
  }
  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) and (orientation: landscape) {
    font-size: 11px;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.625em;
  }
`;

const GameInfo = styled.div`
  position: absolute;
  bottom: 0;
  padding: 8px;
  width: 100%;
  min-height: 14px;
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => getBP(theme, "md")}) and (max-width: ${({ theme }) => getBP(theme, "lg")}) {
    padding-bottom: 2px;
  }

  &.smallGrid {
    & ${GameName} {
      @media (min-width: ${({ theme }) => getBP(theme, "md")}) and (max-width: ${({ theme }) => getBP(theme, "lg")}) {
        font-size: 12px;
      }
    }
    & ${DealerName} {
      @media (min-width: ${({ theme }) => getBP(theme, "md")}) and (max-width: ${({ theme }) => getBP(theme, "lg")}) {
        font-size: 10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0.3em;
  }
`;

const GameInfoComponent = ({ item, smallGrid }) => {
  const { isMobile } = useDeviceState();

  return (
    <GameInfo className={smallGrid ? "smallGrid" : ""}>
      {renderResultComponent(item)}
      <GameName data-name="GL-gameName">{item.gameName}</GameName>

      <DealerName data-name="GL-dealer" isMobile={isMobile}>
        <div>
          <FormattedMessage id="GLdealer" />: {item.dealerName}
        </div>
        {isMobile && <PlayersCountComponent playersCount={item.playersCount} />}
      </DealerName>
    </GameInfo>
  );
};

export default GameInfoComponent;
