import { createAsyncThunk } from "@reduxjs/toolkit";
import { addGame, switchLobbyGame, gameClose } from "./multiViewSlice";

export const openGame = createAsyncThunk("multiView/openGame", (game, { getState, dispatch }) => {
  const { multiView } = getState();
  multiView.games.length <= 3 && dispatch(addGame(game));

  const games = [...multiView.games, game];
  //Notify the opened games to hide the plus table

  if (games.length > 3) {
    games.forEach((item) => {
      const iframe = document.getElementById(item.gameId + ":" + item.limitId);
      iframe.contentWindow.postMessage({ from: "lobby", action: "hideAddTable" }, "*");
    });
  }
});

export const switchGames = createAsyncThunk("multiView/switchGames", (game, { getState, dispatch }) => {
  const { multiView } = getState();
  const { switchGame } = multiView;

  const data = multiView.games.map((item) => {
    return (item.gameId === switchGame.gameId && item.limitId === switchGame.limitId && game) || item;
  });
  dispatch(switchLobbyGame(data));
});

export const replaceGame = createAsyncThunk("multiView/replaceGame", (game, { dispatch, getState }) => {
  const { multiView } = getState();
  const { switchGame } = multiView;

  const data = multiView.games.map((item) => {
    return (item.gameId === switchGame.gameId && item.limitId === switchGame.limitId && game) || item;
  });
  dispatch(switchLobbyGame(data));
});

export const replaceGameLimit = createAsyncThunk("multiView/replaceGameLimit", (game, { dispatch, getState }) => {
  const { multiView } = getState();

  const data = multiView.games.map(item => {
    return (item.gameId === game.gameId && game) || item;
  });
  dispatch(switchLobbyGame(data));
});

export const closeGame = createAsyncThunk("multiView/closeGame", ({ game, clear }, { getState, dispatch }) => {
  const { multiView } = getState();
  let data = [];
  if (!clear) {
    data = multiView.games.filter((item) => {
      return item.gameId !== game.gameId || item.limitId !== game.limitId;
    });
  }

  dispatch(gameClose(data));

  //Notify the opened games to SHOW the plus table
  if (data.length <= 3) {
    multiView.games.forEach((item) => {
      let iframe = document.getElementById(item.gameId + ":" + item.limitId);
      if (iframe) iframe.contentWindow.postMessage({ from: "lobby", action: "showAddTable" }, "*");
    });
  }
});
