// @flow

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CompatibilityComponent from "../../common/CompatibilityComponent";
import ExternalGameWrapperComponent from "../../GameCategories/ExternalGameWrapperComponent";
import MultiViewComponent from "../../MultiView/MultiViewComponent";
import GoToLobbyComponent from "../../MultiView/GoToLobbyComponent";
import { AuthContext } from "../../../contexts";
import { useDeviceState } from "src/utils/helpers";

const AppBg = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const BasicLayout = ({ children }) => {
  const { token } = useSelector((state) => state.user);
  const isLoggedIn = !!token;
  const {isMobile} = useDeviceState()

  return (
    <AppBg isMobile={isMobile} isLocked={isLoggedIn}>
      <AuthContext.Consumer>
        {({ isAuth }) => {
          return (
            isAuth && (
              <>
                <ExternalGameWrapperComponent />
                <MultiViewComponent />
                <GoToLobbyComponent />
                <CompatibilityComponent />
              </>
            )
          );
        }}
      </AuthContext.Consumer>
      {children}
    </AppBg>
  );
};

export default BasicLayout;
