import styled from "styled-components";
import { getBP } from "src/utils/styleUtils";

const colors = {
  light: "#ffff1a",
  warning: "#f44336"
};

const buttonStyles = `
  width: 100%;
  color: black;
  border: none;
  border-radius: 3px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-color: ${colors.light};
  &:hover,
  &:hover.buttonDisabled {
    background-color: ${colors.light};
  }

  @media (max-width: ${({ theme }) => getBP(theme, "xs")}) {
    font-size: 14px;
  }
`;

const StyledChangePasswordForm = styled.div`
  .login {
    @media (max-width: ${({ theme }) => getBP(theme, "xs")}) {
      padding-bottom: 10vh;
    }
  }
  .formControl {
    width: 100%;
    &:hover .cssLabel {
      color: ${colors.light};
    }
  }
  .fieldRoot {
    color: ${colors.light};

    /* fix for google autofill on sign up page
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 99999s ease-in-out 0s;
    }
    -webkit-text-fill-color: ${colors.light} !important; */
  }
  .cssLabel {
    color: #e6e6e6;
    &:hover,
    &.cssFocused {
      color: ${colors.light};
    }
    &.cssLabelDisabled {
      color: #fff;
    }
  }
  .cssLabelDisabled {
  }
  .cssFocused {
    color: red;
  }
  .checkBoxLabel,
  .checkBoxRoot {
    color: #fff;
  }
  .checkBoxChecked {
    color: #ffff1a !important;
  }
  .labelClear {
    position: absolute;
    right: 0;
    top: 18px;
    color: ${colors.warning};
  }
  .globalError {
    font-size: 14px;
    color: ${colors.warning};
    padding-left: 10px;
  }
  .globalErrorWrapper {
    display: inline-flex;
    align-items: center;
  }
  .globalErrorIcon {
    color: ${colors.warning};
  }
  .labelError {
    font-size: 14px;
    color: ${colors.warning};
    float: left;
    width: 100%;
    top: 100%;
    position: absolute;
  }
  .fieldError {
  }
  .fieldFocused {
  }
  .fieldDisabled {
    color: ${colors.light};
  }
  .fieldUnderline {
    &::before {
      border-bottom: 1px solid #e6e6e6;
    }
    &.fieldError::before {
      border-bottom: none;
    }
    &::after,
    &.fieldFocused::before,
    &.fieldDisabled::before,
    &.fieldFocused.fieldError::after {
      border-bottom: 2px solid #ffff1a;
    }
    &:hover:not(.fieldDisabled):not(.fieldFocused):not(.fieldError)::before {
      border-bottom: 2px solid #ffff1a !important;
    }
  }
  .button {
    ${buttonStyles}
  }
  .buttonDisabled {
  }
  .buttonSnd {
    width: 100%;
    border: 1px solid #b2b2b2;
    border-radius: 3px;
    position: relative;
    color: rgb(178, 178, 178) !important;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;

    @media (min-width: ${({ theme }) => getBP(theme, "xs")}) {
      font-size: 14px;
    }

    & span {
      -webkit-transform-style: preserve-3d;
    }
  }
  .buttonsWrapper {
    margin-top: 20px;
  }
  .buttonProgress {
    position: absolute;
  }
  .icon {
    float: left;
    margin-left: 0;
  }
  .link {
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  }
`;

export const StyledInfoPage = styled.div`
  .button {
    ${buttonStyles}
  }
  .mainText {
    font-size: 24px;
  }
  .sndText {
    font-size: 14px;
  }
  .warning {
  }
  .iconWrapper {
    height: 40px;
    width: 40px;
    border: 1px solid ${colors.light};
    border-radius: 21px;
    margin: 0 auto;
    margin-top: 26px;

    &.warning {
      border-color: ${colors.warning};
    }
  }
  .icon {
    margin-top: 7px;
    color: ${colors.light};
    &.warning {
      color: ${colors.warning};
    }
  }
`;

export default StyledChangePasswordForm;
