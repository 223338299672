import React from "react";
import { createGlobalStyle } from "styled-components";
import "./default.css";
import { BackgroundContext } from "src/contexts";
import { theme } from "src/styles/theme";

const {
  myBreakpoints: { mobile, tablet }
} = theme;

const GlobalStyle = createGlobalStyle`
  #root{
      background: ${({ background }) => background && `url(${background})`};
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      @media (orientation: portrait) {
          background-attachment: fixed;
      }
  }
  body {
      @media (min-width: ${mobile}) and (max-width: ${tablet}) {
        @media (orientation: landscape) {
          height: auto;
        }
      }
  }
`;

const DefaultLayout = ({ children }) => {
  return (
    <BackgroundContext.Consumer>
      {(value) => (
        <>
          <GlobalStyle background={value.image} />
          {children}
        </>
      )}
    </BackgroundContext.Consumer>
  );
};

export default DefaultLayout;
