import React, { useState } from "react";
import { signUpSent } from "src/store/components/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { logoXpg } from "../../utils/commonUtils";
import ActivateSuccessComponent from "../Info/ActivateSuccessComponent";
import ActivationFailedComponent from "../Info/ActivationFailedComponent";
import StyledFormComponent from "../common/formComponentStyles";
import { Redirect } from "react-router-dom";
import BasicLayout from "../layout/Basic";
import { Wrapper } from "./styles";
import useWindowHeight from "src/customHooks/useWindowHeight";

const AccountValidationComponent = ({ isFail }) => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const { mobileLogoUrl } = useSelector((state) => state.startUp);
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();

  const onSubmit = (e) => {
    dispatch(signUpSent(false));
    setRedirectToLogin(true);
  };

  const TypeComponent = {
    true: <ActivationFailedComponent submit={onSubmit} />,
    false: <ActivateSuccessComponent submit={onSubmit} />
  };

  const src = mobileLogoUrl || logoXpg;

  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          <div className="screen">
            <Grid container justifyContent="center">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={src} className="img" alt={"Chips"} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {redirectToLogin && <Redirect to="/login" />}

            {TypeComponent[!!isFail]}
          </div>
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default AccountValidationComponent;
