import React, { useState } from "react";
import styled from "styled-components";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Lock from "@material-ui/icons/Lock";
import { FormattedMessage } from "react-intl";
import InlineError from "../common/InlineError";
import StyledForm from "../common/formControlStyles";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { useDispatch, useSelector } from "react-redux";
import { login } from "src/store/components/user/userActions";

const Form = styled.form`
  overflow: hidden;

  & .MuiButton-root{
    letter-spacing: initial;
  }
  & [data-name="GL-RememberMe"]{
    margin-left: -14px;
    & .checkBoxRoot {
      padding: 12px;
    }
    & .checkBoxLabel{
      font-size: 0.875rem;
    }
  }
`;

const LoginForm = () => {
  const { forgotPasswordURL, signUpURL, userName, password, rememberMe } = useSelector((state) => state.startUp);
  const { loginWentWrong } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    userName: rememberMe && userName ? userName : "",
    password: rememberMe && password ? password : "",
    rememberMe: rememberMe
  });
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
    rememberMe: false,
    global: ""
  });

  const onChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setData((prevData) => ({ ...prevData, [key]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validate(data);
    setErrors(errors);

    if (!errors.userName && !errors.password) {
      dispatch(login(data));
    }
  };

  const onForgotPasswordClick = () => {
    window.location.href = forgotPasswordURL;
  };

  const onSignUpClick = () => {
    window.location.href = signUpURL;
  };

  const handleRememberMe = () => {
    setData((prevData) => ({ ...prevData, rememberMe: !prevData.rememberMe }));
  };

  const validate = (data) => {
    const errorsCopy = { ...errors };

    errorsCopy.userName = !data.userName ? "GLCantBeBlank" : "";
    errorsCopy.password = !data.password ? "GLCantBeBlank" : "";

    return errorsCopy;
  };

  return (
    <StyledForm>
      <Grid container alignItems="center" justifyContent="center" className="login">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Form onSubmit={onSubmit} style={{ marginBottom: "8px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {loginWentWrong && (
                  <Grid item xs={12}>
                    <span className="globalErrorWrapper">
                      <InfoRounded className="globalErrorIcon" />
                      <InlineError className="globalError" error={loginWentWrong} />
                    </span>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={1}>
                        <AccountCircle
                          classes={{
                            root: "icon"
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <FormControl error={!!errors.userName} classes={{ root: "formControl" }}>
                          <InputLabel
                            htmlFor="userName"
                            classes={{
                              root: "cssLabel",
                              focused: "cssFocused"
                            }}
                          >
                            <FormattedMessage id="GLUsername" />
                          </InputLabel>
                          <Input
                            classes={{
                              root: "fieldRoot",
                              disabled: "fieldDisabled",
                              underline: "fieldUnderline",
                              focused: "fieldFocused",
                              error: "fieldError"
                            }}
                            type="text"
                            id="userName"
                            name="userName"
                            value={data.userName}
                            onChange={onChange}
                            inputProps={{
                              maxLength: 64
                            }}
                          />
                          {errors.userName && <InlineError className="labelError" error={errors.userName} />}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={1}>
                        <Lock
                          classes={{
                            root: "icon"
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <FormControl error={!!errors.password} classes={{ root: "formControl" }}>
                          <InputLabel
                            classes={{
                              root: "cssLabel",
                              focused: "cssFocused"
                            }}
                          >
                            <FormattedMessage id="GLPassword" />
                          </InputLabel>
                          <Input
                            classes={{
                              root: "fieldRoot",
                              disabled: "fieldDisabled",
                              underline: "fieldUnderline",
                              focused: "fieldFocused",
                              error: "fieldError"
                            }}
                            type="password"
                            id="password"
                            name="password"
                            value={data.password}
                            onChange={onChange}
                          />
                          {errors.password && <InlineError className="labelError" error={errors.password} />}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={!!errors.userName} classes={{ root: "formControl" }}>
                      <FormControlLabel
                        data-name="GL-RememberMe"
                        control={
                          <Checkbox
                            checked={data.rememberMe}
                            onChange={handleRememberMe}
                            value="checkedA"
                            classes={{
                              root: "checkBoxRoot",
                              checked: "checkBoxChecked"
                            }}
                          />
                        }
                        label={<FormattedMessage id="GLRememberMe" />}
                        classes={{
                          label: "checkBoxLabel"
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" className="button" onClick={onSubmit}>
                  <FormattedMessage id="GLlogin" />
                </Button>
              </Grid>
              {forgotPasswordURL && (
                <Grid item xs={signUpURL ? 6 : 12}>
                  <Button
                    type="button"
                    variant="outlined"
                    fullWidth
                    className="buttonSnd"
                    onClick={onForgotPasswordClick}
                  >
                    <FormattedMessage id="GLForgotPassword" />
                  </Button>
                </Grid>
              )}
              {signUpURL && (
                <Grid item xs={forgotPasswordURL ? 6 : 12}>
                  <Button type="button" fullWidth variant="outlined" className="buttonSnd" onClick={onSignUpClick}>
                    <FormattedMessage id="signUp" />
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

export default LoginForm;
