import React from "react";
import styled from "styled-components";

const Colors = {
  red: "#FF3333",
  white: "#FFFFFF",
  green: "#00CF0A"
};

const ResultContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #87828f;
  border-radius: 21px;
  padding: 3px;
`;

const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  font-size: 8px;
`;

const redNumbersArray = [
  "1", "3", "5", "7", "9", "12", "14", "16", "18", "19", "21", "23", "25", "27", "30", "32", "34", "36"
];

const blackNumbersArray = [
  "2", "4", "6", "8", "10", "11", "13", "15", "17", "20", "22", "24", "26", "28", "29", "31", "33", "35"
];

const getItemColor = (item) => {
  if (item === "0") return Colors.green;
  if (redNumbersArray.includes(item)) return Colors.red;
  if (blackNumbersArray.includes(item)) return Colors.white;
  return "";
};

const RouletteLastResultsMobile = ({ collection }) => {
  const collectionArr = collection.slice(0, 9);

  return (
    <ResultContainerWrapper>
      {collectionArr.map((item, index) => {
        const color = getItemColor(item);
        return (
          <Circle key={index} color={color}>
            {item}
          </Circle>
        );
      })}
    </ResultContainerWrapper>
  );
};

export default RouletteLastResultsMobile;
