export const theme = {
  myBreakpoints: {
    // from MUI
    xs: "599.95px",
    sm: "959.95px",
    md: "960px",
    lg: "1919.95px",
    xl: "1920px",

    // from styled-components-breakpoint
    mobile: "0em",
    tablet: "46.0625em",
    desktop: "74.6875em"
  }
};
