import React from "react";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import ReactModal from "react-modal";

const Wrapper = styled.div``;

const StyledModal = styled(ReactModal)`
  position: relative;
  width: 90%;
  max-width: 700px;
  max-width: ${(props) => (props["maxWidth"] !== null ? props["maxWidth"] + "px" : "700px")};
  background-color: rgba(0, 0, 0, 0.9);
  box-sizing: border-box;
  padding: 0;
  border-radius: 6px;
  border: 1px solid rgba(128, 128, 128, 1);
  overflow: hidden;
  color: rgba(230, 230, 230, 1);
  ${ifProp(
    "wide",
    css`
      max-width: 80rem;
    `
  )};

  ${ifProp(
    "transparent",
    css`
      box-shadow: none;
      background: none;
    `
  )};

  &:focus {
    outline: none;
  }

  &.ReactModal__Content {
    transition: all 300ms ease-in-out;
    transform: scale(0.5);
    opacity: 0;

    &--after-open {
      opacity: 1;
      transform: scale(1);
    }

    &--before-close {
      opacity: 0;
      transform: scale(0.5);
    }
  }
`;

const Content = styled.div``;

const CloseButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 1.6em;
  color: #888;
  font-weight: lighter;
  cursor: pointer;

  &:active {
    transform: translate(0, 1px);
  }

  &:focus {
    outline: none;
  }
`;

const Modal = (props) => (
  <StyledModal
    appElement={document.getElementById("root")}
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    contentLabel="Modal"
    closeTimeoutMS={300}
    shouldReturnFocusAfterClose={false}
    transparent={props.transparent}
    wide={props.wide}
    maxWidth={props.maxWidth}
    style={{
      overlay: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        transform: "translate3d(0, 0, 0)",
        zIndex: 1000
      }
    }}
  >
    <Wrapper>
      <Content>{props.children}</Content>
    </Wrapper>
    {props.onCloseBtn && <CloseButton onClick={props.onRequestClose}>×</CloseButton>}
  </StyledModal>
);

export default Modal;
