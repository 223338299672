import React from "react";
import ActivationInfoComponent from "./ActivationInfoComponent";

const initialTranslations = {
  mainText: "GLForgotPasswordValidation.FailedMain",
  subText: "GLForgotPasswordValidation.FailedSub"
};

const ERROR_MAPPING = {
  8: {
    mainText: "GLplayerBlocked",
    subText: "GLForgotPasswordValidation.ContactSystemAdministrator"
  }
};

const ForgotPasswordFailedComponent = ({ submit, errorCode }) => {
  const mapCodeError = ERROR_MAPPING[errorCode];
  const translations = !!mapCodeError ? mapCodeError : initialTranslations;

  return <ActivationInfoComponent translationKeys={translations} submit={submit} isFail />;
};

export default ForgotPasswordFailedComponent;
