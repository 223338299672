import React from "react";
import styled from "styled-components";
import { createRoadMapPositionArray } from "../../../../../utils/gameCategoriesUtils";

const Colors = {
  none: "none",
  red: "#d80000",
  blue: "#006199" /*"#0088b2",*/,
  green: "#007f12",
  grey: "#808080"
};

const LastResultWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 18.2%;
  margin-bottom: 2px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.25);
`;
const LastResult = styled.svg`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  float: left;
`;

const Grid = styled.path`
  fill: ${Colors.grey};
`;

const getResultColor = (result) => {
  switch (result) {
    case 1:
      return Colors.red;
    case 2:
      return Colors.blue;
    case 3:
      return Colors.green;
    default:
      return Colors.none;
  }
};

const drawCircles = (collection, grid) => {
  const results = [];

  for (let row = 0; row < 6; row++) {
    for (let column = 0; column < 30; column++) {
      const element = collection[row][column];

      if (element === null) {
        continue;
      }

      results.push(
        <g key={"result" + row + column}>
          {element.result !== 3 ? (
            <circle
              cx={grid[row][column].y}
              cy={grid[row][column].x}
              r={4}
              strokeWidth={2}
              stroke={getResultColor(element.result)}
              fill="none"
            />
          ) : (
            <line
              x1={grid[row][column].y + 5}
              y1={grid[row][column].x - 5}
              x2={grid[row][column].y - 5}
              y2={grid[row][column].x + 5}
              stroke={Colors.green}
              strokeWidth={2}
            />
          )}
          {element.isBankerPair && (
            <circle
              key={"banker" + row + column}
              cx={grid[row][column].y - 3}
              cy={grid[row][column].x - 3}
              r={2}
              fill={Colors.red}
            />
          )}
          {element.isPlayerPair && (
            <circle
              key={"player" + row + column}
              cx={grid[row][column].y + 3}
              cy={grid[row][column].x + 3}
              r={2}
              fill={Colors.blue}
            />
          )}
        </g>
      );
    }
  }

  return results;
};
const positionArray = createRoadMapPositionArray();

const BaccaratLastResults = ({ collection }) => {
  return (
    <LastResultWrapper>
      <LastResult version={1.1} viewBox={[0, 0, 422, 77]}>
        <g>
          <Grid d="M0,12v1h422v-1H0z M0,26h422v-1H0V26z M0,39h422v-1H0V39z M0,52h422v-1H0V52z M0,65h422v-1H0V65z" />
        </g>
        <g>
          <Grid
            d="M12,77h1V0h-1V77z M25,77h1V0h-1V77z M38,77h1V0h-1V77z M51,77h1V0h-1V77z M64,77h1V0h-1V77z M77,77h1V0h-1
			V77z M90,77h1V0h-1V77z M103,77h1V0h-1V77z M116,77h1V0h-1V77z M129,77h1V0h-1V77z M142,77h1V0h-1V77z M155,77h1V0h-1V77z M168,77
			h1V0h-1V77z M181,77h1V0h-1V77z M194,77h1V0h-1V77z M207,77h1V0h-1V77z M220,77h1V0h-1V77z M233,77h1V0h-1V77z M246,77h1V0h-1V77z
			 M259,77h1V0h-1V77z M272,77h1V0h-1V77z M285,77h1V0h-1V77z M298,77h1V0h-1V77z M311,77h1V0h-1V77z M324,77h1V0h-1V77z M337,77h1
			V0h-1V77z M350,77h1V0h-1V77z M363,77h1V0h-1V77z M376,77h1V0h-1V77z M389,77h1V0h-1V77z M402,77h1V0h-1V77z M415,0v77h1V0H415z"
          />
        </g>

        {drawCircles(collection, positionArray)}
      </LastResult>
    </LastResultWrapper>
  );
};

export default BaccaratLastResults;
