import React, { useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu, setActiveTabMenu, togglePanelMenu } from "src/store/components/mobile/menu/menuSlice";
import SidebarContent from "./SidebarContent";
import styled from "styled-components";
import ButtonMenu from "./ButtonMenu";
import TabsMenu from "../TabsMenu";
import ScrollTopButton from "../../ScrollTopButton";
import { getBP } from "src/utils/styleUtils";

const Wrapper = styled.div`
  height: 100vh;
  .custom-sidebar-class {
    top: 20px;
    background-color: rgba(17, 17, 26, 0.95);

    @media (min-width: ${({ theme }) => getBP(theme, "mobile")}) {
      width: 100%;
      @media (orientation: landscape) {
        width: 50%;
      }
    }
    @media (min-width: ${({ theme }) => getBP(theme, "tablet")}) {
      width: 50%;
    }
  }

  #custom-sidebar-content-id {
    height: 100%;
    overflow-y: ${({ multiViewGames, hideLobby }) =>
      multiViewGames.length > 0 && !hideLobby ? "hidden !important" : "auto !important"};

    position: ${({ multiViewGames, hideLobby }) =>
      multiViewGames.length > 0 && !hideLobby ? "relative !important" : "fixed !important"};

    @media (min-width: ${({ theme }) => getBP(theme, "sm")}) {
      overflow-y: hidden !important;
    }
  }
`;

const styleSidebar = {
  sidebar: {
    top: "20px",
    zIndex: 15,
    height: "100vh",
    transition: "transform .2s linear",
    WebkitTransition: "-webkit-transform .2s linear",
    willChange: "transform",
    overflowY: "auto",
    position: "absolute"
  },
  overlay: {
    zIndex: 14,
    top: "20px"
  },
  dragHandle: {
    zIndex: 14
  },
  content: {
    position: "fixed"
  }
};

const SidebarMenu = ({ isMobile, children }) => {
  const dispatch = useDispatch();
  const { isOpen, activeTab, isOpenPanel } = useSelector((state) => state.menu);
  const { lobbyMenuItems, MGLMenuItems, canChangePass, loginType, deviceType } = useSelector((state) => state.startUp);
  const { hideLobby, games: multiViewGames } = useSelector((state) => state.multiView);
  const { lobbyType, selectedCategory } = useSelector((state) => state.gameCategories);
  const isLobbyExternal = lobbyType === "External";

  const [touch, setTouch] = useState(isMobile);

  useEffect(() => {
    if (isOpen || isOpenPanel) {
      document.body.setAttribute("style", "position:fixed; overflow:hidden;");
      document.getElementById("custom-sidebar-content-id").style.webkitOverflowScrolling = "auto";
    } else {
      document.body.removeAttribute("style");
      document.getElementById("custom-sidebar-content-id").style.webkitOverflowScrolling = "touch";
    }
  });

  useEffect(() => {
    setTouch(isMobile);

    if (!isMobile) {
      dispatch(toggleMenu(false));
      dispatch(togglePanelMenu(false));
      dispatch(setActiveTabMenu(null));
    }
  }, [isMobile]);

  useEffect(() => {
    document.getElementById("custom-sidebar-content-id").scrollTo({ top: 0, behavior: "auto" });
  }, [selectedCategory]);

  const handleToggleOpen = () => {
    if (!isOpen) {
      dispatch(togglePanelMenu(false));
      dispatch(setActiveTabMenu(null));
    }
    dispatch(toggleMenu(!isOpen));
  };

  const menuItems = canChangePass ? lobbyMenuItems : lobbyMenuItems.filter((item) => item.key !== "changepassword");
  const homeUrl = localStorage.getItem("homeUrl") || "";
  const sidebar = (
    <SidebarContent
      lobbyType={isLobbyExternal}
      MGLMenuItems={MGLMenuItems}
      menuItems={menuItems}
      loginType={loginType}
      homeUrl={homeUrl}
      onToggleMenu={handleToggleOpen}
      onOpenTabMenu={(prop) => dispatch(setActiveTabMenu(prop))}
      onTogglePanelMenu={(prop) => dispatch(togglePanelMenu(prop))}
    />
  );
  const sidebarProps = {
    sidebar,
    shadow: true,
    pullRight: true,
    transitions: true,
    docked: false,
    touchHandleWidth: 0,
    dragToggleDistance: 30,
    sidebarClassName: "custom-sidebar-class",
    contentId: "custom-sidebar-content-id",
    touch,
    onSetOpen: handleToggleOpen,
    rootId: "#root",
    open: isOpen,
    styles: styleSidebar
  };
  return (
    <Wrapper multiViewGames={multiViewGames} hideLobby={hideLobby}>
      <Sidebar {...sidebarProps}>{children}</Sidebar>
      <TabsMenu activeTab={activeTab} isMobile={isMobile} />
      {isMobile && deviceType === "Mobile" && (
        <ButtonMenu typeBtn={isOpen ? "close" : "open"} onToggleMenu={handleToggleOpen} isOpenPanel={isOpenPanel} />
      )}
      <ScrollTopButton isOpen={isOpen} />
    </Wrapper>
  );
};

export default SidebarMenu;
