import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getGamePath } from "../../utils/commonUtils";
import aspectRatio from "../../utils/multiViewUtils";
import { useSelector } from "react-redux";

const MultiView = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  z-index: 20;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &.hide {
    z-index: -1;
    height: 0;
    overflow: hidden;
  }
`;
const Game = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.half {
    width: 50%;
    height: 50%;
    float: left;
    border: 0;
  }
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  float: left;
  border: 0;
`;

const getIframeWrapperStyles = (width, height, index, multiViewGames) => ({
  width,
  height,
  marginRight: 0,
  marginTop: 0,
  float: index % 2 ? "left" : "right"
});

const MultiViewComponent = () => {
  const { operatorId, deviceType, language: languageId } = useSelector((state) => state.startUp);
  const { hideLobby, games: multiViewGames } = useSelector((state) => state.multiView);
  const { token } = useSelector((state) => state.user);

  const [sizes, setSizes] = useState(aspectRatio(1, []));
  const [gamesOpened, setGamesOpened] = useState(0);
  const [games, setGames] = useState([]);

  useEffect(() => {
    setSizes(aspectRatio(multiViewGames.length, multiViewGames));
    setGamesOpened(multiViewGames.length);
    setGames(multiViewGames);
  }, [multiViewGames]);

  const updateDimensions = () => {
    setSizes(aspectRatio(gamesOpened, games));
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [gamesOpened, games]);

  const dataUrl = {
    operatorId,
    token,
    languageId,
    deviceType
  };
  const { width, height } = sizes;
  return (
    <MultiView className={((multiViewGames.length === 0 || hideLobby) && " hide") || ""}>
      {multiViewGames.map((item, index) => (
        <Game className={(multiViewGames.length > 1 && " half") || ""} key={`${item.gameId}:${item.limitId}`}>
          <div style={getIframeWrapperStyles(width, height, index, multiViewGames)}>
            <Iframe
              title={item.gameName}
              id={`${item.gameId}:${item.limitId}`}
              src={getGamePath(dataUrl, item)}
              key={`${item.gameId}:${item.limitId}`}
              allowFullScreen={true}
              webkitallowfullscreen={true}
              mozallowfullscreen={true}
              oallowfullscreen={true}
              msallowfullscreen={true}
            />
          </div>
        </Game>
      ))}
    </MultiView>
  );
};

export default MultiViewComponent;
