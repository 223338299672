import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { login } from "src/store/components/user/userActions";
import { getBP } from "src/utils/styleUtils";
import { numberWithCommas } from "src/helpers";

const StyledUser = styled.div`
  float: left;
`;
const Info = styled.div`
  color: #86818e;
  float: left;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    margin: 16px 0 0 0;
    padding-bottom: 6px;
    border-bottom: 1px solid #33313a;
    width: 100%;
  }
`;
const Item = styled.div`
  float: left;
  padding: 0 10px;
  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    margin: 0 0 10px 20px;
    height: 16px;
    width: 100%;
  }
`;
const Icon = styled.div`
  width: 14px;
  height: 14px;
  float: left;
  margin-right: 10px;

  & img {
    width: inherit;
    height: inherit;
  }
`;
const Txt = styled.div`
  float: left;
  font-size: 12px;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    font-size: 13px;
    color: #fff;
  }

  line-height: 21px;
`;

const UserComponent = () => {
  const { loginWentWrong, token, userName, balance, currencySymbol } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date().toLocaleTimeString());

  const tick = () => {
    setDate(new Date().toLocaleTimeString());
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      tick();
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <StyledUser>
      {loginWentWrong && (
        <button key="login" onClick={() => dispatch(login())}>
          Login
        </button>
      )}
      {!loginWentWrong && token && (
        <Info>
          <Item>
            <Icon>
              <img src={`/assets/img/lobby/header/time_ic.svg`} alt={"Time Icon"} />
            </Icon>
            <Txt>{date}</Txt>
          </Item>
          <Item>
            <Icon>
              <img src={`/assets/img/lobby/header/user_ic.svg`} alt={"User Icon"} />
            </Icon>
            <Txt>{userName}</Txt>
          </Item>
          <Item>
            <Icon>
              <img src={`/assets/img/lobby/header/money_ic.svg`} alt={"Balance Icon"} />
            </Icon>
            <Txt>
              {numberWithCommas(balance)} {currencySymbol}
            </Txt>
          </Item>
        </Info>
      )}
    </StyledUser>
  );
};

export default UserComponent;
