// @flow
import axios from "axios";

import type { ApiParamsRequest } from "../clientModels/ApiParamsRequest";
import type { ApiParamsResponse } from "../clientModels/ApiParamsResponse";
import { retryMechanismDelayMs, ApiParamsUrls } from "../../configs/servicesConfig";

import * as ApiParamsMapper from "../mappers/ApiParamsMapper";
import * as MapResponse from "../utils/MapResponse";

export default class ApiParamsProvider {
  async requestWithRetry(urls: Array<string>, request: *, responseResolver: *, retryIndex: number) {
    try {
      const options = {
        method: "GET",
        url: urls[retryIndex],
        data: request,
        headers: { "Cache-Control": "no-cache" }
      };
      const response = await axios(options);
      if (response.status === 200) {
        return responseResolver(MapResponse.success(response.data));
      } else {
        console.error("server response encounter error with status: " + response.status);
        throw MapResponse.exception(response.status, response.statusText);
      }
    } catch (error) {
      if (retryIndex === urls.length + 1) {
        throw new Error('Max retries exceeded');
      }
      console.error(`Request failed for ${urls[retryIndex]}. Retrying...`);
      await new Promise(resolve => setTimeout(resolve, retryMechanismDelayMs));
      return this.requestWithRetry(urls, request, responseResolver, retryIndex + 1);
    }
  }

  getApiParams = async (request: ApiParamsRequest): Promise<ApiParamsResponse> => {
    const time = new Date().getTime();
    const mappedUrls = [...ApiParamsUrls].map(url => `${url + ApiParamsMapper.mapApiParamsRequest(request).url}?t=${time}`)
    const urls = [...mappedUrls, ...mappedUrls];
    return this.requestWithRetry(urls, {}, ApiParamsMapper.resolveResponse, 0);
  };
}
