import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Icon from "src/Components/ui/Icon";
import Logout from "src/Components/Header/components/ControlBtn/Settings/Logout";
import { GAMES_RULES_LINK } from "src/constants";

const List = styled.ul`
  font-size: 14px;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  white-space: nowrap;
  .item-menu {
    text-decoration: none;
    color: #e6e6e6;
    padding: 10px 0;
    display: flex;
    flex: 1;
    align-items: center;
    span {
      margin: 0 20px;
      &.icon-home {
        margin: 0 18px;
      }
    }
  }
`;
const Hyperlink = styled.a``;
const LinkMenu = styled.div``;

const LobbyMenuItems = ({ menuItems, onToggleMenu, onTogglePanelMenu, onOpenTabMenu, loginType, homeUrl }) => (
  <List>
    {menuItems.map((item) => (
      <Item data-name={`GLMenu-${item.key}`} key={`menu-${item.key}`}>
        {(() => {
          if (item.key === "gamerules") {
            return (
              <Hyperlink className="item-menu" href={GAMES_RULES_LINK} target="_blank">
                <Icon name={item.key} width="25" height="24" />
                <FormattedMessage id={`GLMenu.${item.key}`} defaultMessage={item.key} />
              </Hyperlink>
            );
          } else if (item.route) {
            return (
              <Link className="item-menu" to={`/${item.key}`}>
                <Icon name={item.key} width="25" height="24" />
                <FormattedMessage id={`GLMenu.${item.key}`} defaultMessage={item.key} />
              </Link>
            );
          }
          return (
            <LinkMenu
              className="item-menu"
              onClick={() => {
                onToggleMenu(false);
                onTogglePanelMenu(true);
                onOpenTabMenu(item.key);
              }}
            >
              <Icon name={item.key} width="25" height="24" />
              <FormattedMessage id={`GLMenu.${item.key}`} defaultMessage={item.key} />
            </LinkMenu>
          );
        })()}
      </Item>
    ))}
    {homeUrl && (
      <Item>
        <Hyperlink className="item-menu" href={homeUrl}>
          <Icon name="home" width="28" height="24" />
          <FormattedMessage id="GLMenu.home" />
        </Hyperlink>
      </Item>
    )}
    {loginType === 0 && (
      <Item data-name={`GLMenu-logout`} key={`menu-logout`}>
        <Logout onToggleMenu={onToggleMenu}>
          <Icon name="logout" width="25" height="24" />
          <FormattedMessage id="GLLogout" />
        </Logout>
      </Item>
    )}
  </List>
);
export default LobbyMenuItems;
