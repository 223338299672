import React from "react";
import styled from "styled-components";

const colors = {
  player8: "#009BD4",
  player9: "#FFFF00",
  player10: "#00D83D",
  player11: "#E53935"
};

const Wrapper = styled.div`
  margin-bottom: 3%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Player = styled.svg`
  width: 24%;
  display: flex;
  align-items: center;
`;

const TTCardsStatistics = ({ resultStatistics }) => {
  const playerList = [8, 9, 10, 11];
  return (
    <Wrapper>
      {playerList.map((item) => (
        <Player
          id="player_8"
          data-name="player 8"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 42"
          key={`item-svg-${JSON.stringify(item)}`}
        >
          <g
            id="Rectangle_229"
            data-name="Rectangle 229"
            fill="rgba(26,26,26,0.7)"
            stroke={colors[`player${item}`]}
            strokeWidth="1"
          >
            <rect width="98" height="42" rx="21" stroke="none" />
            <rect x="0.5" y="0.5" width="98" height="41" rx="20.5" fill="none" />
          </g>
          <g
            id="Rectangle_233"
            data-name="Rectangle 233"
            transform="translate(6 6)"
            fill="none"
            stroke={colors[`player${item}`]}
            strokeWidth="1"
          >
            <rect width="30" height="30" rx="15" stroke="none" />
            <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" fill="none" />
          </g>
          <text
            id="_8"
            data-name="8"
            transform="translate(21 28)"
            fill={colors[`player${item}`]}
            fontSize="18"
            fontFamily="Roboto-Regular, Roboto"
          >
            <tspan x="0" y="0" textAnchor="middle">
              {item}
            </tspan>
          </text>
          <text
            id="_18_"
            data-name="18%"
            transform="translate(50 28)"
            fill={colors[`player${item}`]}
            fontSize="18"
            fontFamily="Roboto-Regular, Roboto"
          >
            <tspan x="0" y="0">
              {resultStatistics[`player${item}`]}%
            </tspan>
          </text>
        </Player>
      ))}
    </Wrapper>
  );
};

export default TTCardsStatistics;
