import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: #ffff1a;
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  border: none;
  border-radius: 6px;
  padding: 12px 66px 10px;
  text-transform: uppercase;
  font-family: inherit;
  &:hover {
    box-shadow: 0 10px 19px 1px rgba(255, 255, 26, 0.1);
    cursor: pointer;
    transition: 0.2s;
  }
  &:focus {
    outline: none;
  }
`;

const BackButton = ({ handleOnClick, children }) => <Button onClick={handleOnClick}>{children}</Button>;

export default BackButton;
