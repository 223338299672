import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import { Button } from "@material-ui/core";

const Wrapper = styled.div`
  padding: 75px 60px 0 60px;
  text-align: center;

  .btn {
    font-size: 16px;
    min-width: 180px;
    min-height: 50px;
    border-radius: 6px;
    cursor: pointer;
    letter-spacing: 0.75px;
    font-weight: 700;
  }
  .okBtn {
    color: rgba(26, 26, 26, 1);
    background-color: rgba(255, 255, 26, 1);

    &:hover {
      background-color: #e0e016;
    }
  }
`;
const Label = styled.p`
  font-size: 16px;
  color: #ffffff;
  margin: 25px 0 67px 0;
`;
const WrappBtn = styled.div`
  margin: 45px 0;
  display: flex;
  justify-content: space-around;
`;
const Img = styled.img`
  width: 46px;
  height: 46px;
`;

const SuccessMessage = ({ onClosePopup }) => {

  return (
    <Wrapper>
      <Img src={`/assets/img/icons/success-change.gif?v=${Date.now()}`} alt="" />
      <Label>
        <FormattedMessage id="GLchangedPasswordSuccessfully" />
      </Label>
      <WrappBtn>
        <Button
          type="button"
          variant="contained"
          color="inherit"
          onClick={() => {
            onClosePopup();
          }}
          className="btn okBtn"
        >
          <FormattedMessage id="GLok" />
        </Button>
      </WrappBtn>
    </Wrapper>
  );
};

export default SuccessMessage;
