import user from "./components/user/userSlice";
import gameCategories from "./components/gameCategories/gameSlice";
import startUp from "./components/startUp/startUpSlice";
import multiView from "./components/multiView/multiViewSlice";
import error from "./components/error/errorSlice";
import menu from "./components/mobile/menu/menuSlice";
import app from "./app/appSlice";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    user,
    gameCategories,
    startUp,
    multiView,
    error,
    menu,
    app
  },
  devTools: true
});
