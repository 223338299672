import React from "react";
import { FormattedMessage } from "react-intl";

const MessageView = ({ loginWentWrong, ipNotAuthorized, forgotPasswordSent, signUpReceived, welcome, className }) => {
  const messageId =
    (loginWentWrong && loginWentWrong) ||
    (ipNotAuthorized && "GLipNotAuthorized") ||
    (forgotPasswordSent && "GLForgotPasswordSent") ||
    (signUpReceived && "GLSignUpSent") ||
    (welcome && "GLwelcome");

  return (
    <>
      {messageId && (
        <div className={className}>
          <FormattedMessage id={messageId} values={{ breakLine: <br /> }} />
        </div>
      )}
    </>
  );
};

export default MessageView;
