// @flow
export function updateCategoryInLocalStorage(categoryId: number, items: Array<any>) {
  const storageGames = localStorage.getItem("games");
  if (storageGames) {
    const games = JSON.parse(storageGames);
    if (!games[categoryId]) {
      localStorage.setItem("games", JSON.stringify({ ...games, [categoryId]: items }));
    } else {
      localStorage.setItem("games", JSON.stringify({ [categoryId]: items }));
    }
  } else {
    localStorage.setItem("games", JSON.stringify({ [categoryId]: items }));
  }

  const gamesExpiryTime = localStorage.getItem("gamesDataExpiryDate");
  const expiry = new Date();

  // TODO make this configurable from ApiParams
  expiry.setSeconds(expiry.getSeconds() + 30);

  if (gamesExpiryTime) {
    const expiries = JSON.parse(gamesExpiryTime);
    if (!expiries[categoryId]) {
      localStorage.setItem("gamesDataExpiryDate", JSON.stringify({ ...expiries, [categoryId]: expiry }));
    } else {
      localStorage.setItem("gamesDataExpiryDate", JSON.stringify({ [categoryId]: expiry }));
    }
  } else {
    localStorage.setItem("gamesDataExpiryDate", JSON.stringify({ [categoryId]: expiry }));
  }
}

export function getGamesByCategoryId(categoryId: number) {
  const storageGames = localStorage.getItem("games");
  const gamesExpiryTime = localStorage.getItem("gamesDataExpiryDate");

  if (gamesExpiryTime) {
    const expiries = JSON.parse(gamesExpiryTime);
    if (!expiries[categoryId]) {
      return [];
    } else {
      const catExpiryTime = Date.parse(expiries[categoryId]);
      if (new Date(catExpiryTime).getTime() >= new Date().getTime()) {
        if (storageGames) {
          const games = JSON.parse(storageGames);
          if (games[categoryId]) {
            return games[categoryId];
          }
        }
      }
    }
  }

  return [];
}

export function createEmptyArray(rows: number, columns: number) {
  let x: any = new Array(rows);
  for (let i = 0; i < rows; i++) {
    x[i] = new Array(columns);
  }

  return x;
}

export function createRoadMapPositionArray() {
  let posArray = createEmptyArray(6, 30);

  let startXY = 6;
  let offset = 13;
  for (let row = 0; row < 6; row++) {
    for (let column = 0; column < 30; column++) {
      posArray[row][column] = {
        x: startXY + row * offset,
        y: startXY + column * offset
      };
    }
  }

  return posArray;
}

export type ExternalGameData = { url: string, error: string };
