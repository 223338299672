import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeGame, openGame, replaceGame, replaceGameLimit } from "../components/multiView/multiViewActions";
import { goToLobby, goToMultiView, updateLobbyMode } from "../components/multiView/multiViewSlice";
import { loginMGL, loginWithToken } from "../components/user/userActions";
import { loginWentWrong, stopLogin } from "../components/user/userSlice";
import { extractHostname } from "../../utils/helpers";
import { GAService } from "../../services";
import Cookies from "js-cookie";
import { setError } from "../components/error/errorSlice";
import { getGameCategories } from "../components/gameCategories/gameActions";

export const getCategories = createAsyncThunk("common/getCategories", (_, { dispatch }) => {
  dispatch(getGameCategories());
});

export const blockIp = createAsyncThunk("common/blockIp", (_, { dispatch }) => {
  dispatch(stopLogin());
});

export const showLobby = createAsyncThunk("common/showLobby", ({ lobbyMode, switchGame }, { dispatch }) => {
  const iframe = window.document.getElementById(`${switchGame.gameId}:${switchGame.limitId}`);
  if(iframe && iframe.ownerDocument.webkitIsFullScreen) {
    iframe.ownerDocument.webkitCancelFullScreen();
  }
  switchGame = lobbyMode === 1 ? {} : switchGame;
  dispatch(updateLobbyMode({ lobbyMode, switchGame }));
  dispatch(goToLobby());
});

export const hideLobby = createAsyncThunk("common/hideLobby", (_, { dispatch }) => {
  GAService.sendEvent("MultiView", "ShowMultiView");
  dispatch(goToMultiView());
});

export const selectGame = createAsyncThunk("common/selectGame", (game, { dispatch }) => {
  GAService.sendEvent("MultiView", "OpenGame");
  dispatch(openGame(game));
});

export const switchGame = createAsyncThunk("common/switchGame", (game, { dispatch }) => {
  GAService.sendEvent("MultiView", "SwitchGame");
  dispatch(replaceGame(game));
});

export const switchGameLimit = createAsyncThunk("common/switchGameLimit", (game, { dispatch }) => {
  GAService.sendEvent("MultiView", "SwitchGameLimit");
  dispatch(replaceGameLimit(game));
});

export const gameClose = createAsyncThunk("common/gameClose", ({ gameId, limitId }, { dispatch }) => {
  GAService.sendEvent("MultiView", "CloseGame");
  const game = { gameId, limitId };
  dispatch(closeGame({ game }));
});

export const storeRememberMeFields = createAsyncThunk("common/storeRememberMeFields", (fields) => fields);

export const tokenLogin = createAsyncThunk("common/tokenLogin", (params, { dispatch }) => {
  dispatch(loginWithToken(params));
});

export const mglLogin = createAsyncThunk("common/mglLogin", (params, { dispatch }) => {
  dispatch(loginMGL(params));
});

export const updateOperatorId = createAsyncThunk("common/updateOperatorId", (operatorId) => {
  GAService.setOperatorId(operatorId);
  return operatorId;
});

export const updateLoginType = createAsyncThunk("common/updateLoginType", (loginType) => {
  const { referrer } = document;
  if (loginType === 1 && referrer.length > 0 && window.location.hostname !== extractHostname(referrer)) {
    localStorage.setItem("referrer", referrer);
  } else if (loginType === 1 && referrer.length === 0) {
    localStorage.removeItem("referrer");
  }
  localStorage.setItem("loginType", loginType.toString());
  return loginType;
});

function clearLocalStorage(state) {
  const { startUp } = state;
  if (!startUp.rememberMe) {
    localStorage.removeItem("userName");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMe");
  }

  localStorage.removeItem("operatorId");
  localStorage.removeItem("token");
  localStorage.removeItem("playerId");
  localStorage.removeItem("games");
  localStorage.removeItem("gridType");
  localStorage.removeItem("gamesDataExpiryDate");
  localStorage.removeItem("languageId");
  localStorage.removeItem("brandName");
  Cookies.remove("loginParams");
}

export const tokenLoginFailed = createAsyncThunk(
  "common/tokenLoginFailed",
  ({ error, isNotToken = true } = {}, { dispatch, getState }) => {
    if (error.errorCode) {
      clearLocalStorage(getState());
      if (isNotToken || error.errorCode !== "21") {
        dispatch(
          setError({
            code: error.errorCode || "404",
            description: error.description || "404",
            priority: "high"
          })
        );
      }
    }

    dispatch(goToLobby());
  }
);

export const loginFailed = createAsyncThunk("common/loginFailed", (error, { dispatch, getState }) => {
  clearLocalStorage(getState());

  switch (error.errorCode) {
    case "8":
      dispatch(loginWentWrong("GLplayerBlocked"));
      break;
    case "6":
      dispatch(loginWentWrong("GLsessionExpired"));
      break;
    default:
      return dispatch(loginWentWrong("GLloginWentWrong"));
  }

  dispatch(goToLobby());
});
