import React from "react";
import styled from "styled-components";
import CompatibilityComponent from "../../common/CompatibilityComponent";
import ExternalGameWrapperComponent from "../../GameCategories/ExternalGameWrapperComponent";
import MultiViewComponent from "../../MultiView/MultiViewComponent";
import GoToLobbyComponent from "../../MultiView/GoToLobbyComponent";
import { AuthContext } from "../../../contexts";

const Wrapper = styled.div``;

const BasicLayout = ({ children }) => {
  return (
    <Wrapper>
      <AuthContext.Consumer>
        {({ isAuth }) => {
          return (
            isAuth && (
              <>
                <ExternalGameWrapperComponent />
                <MultiViewComponent />
                <GoToLobbyComponent />
                <CompatibilityComponent />
              </>
            )
          );
        }}
      </AuthContext.Consumer>
      {children}
    </Wrapper>
  );
};

export default BasicLayout;
