import React from "react";
import AsyncGameImage from "../../common/AsyncGameImage";
import styled from "styled-components";

const StyledAsyncGameImage = styled(AsyncGameImage)`
  width: 100%;
  float: left;

  & img {
    width: 100%;
    float: left;
  }
`;

const GameImageComponent = ({ path }) => <StyledAsyncGameImage source={path} />;

export default GameImageComponent;
