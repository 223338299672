/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { selectCategory } from "src/store/components/gameCategories/gameActions";
import { sidebarMenu } from "src/hocs/defaultHOCs";
import { useHistory } from "react-router-dom";
import { getGamePath } from "../../utils/commonUtils";
import { GAService } from "../../services";
import HeaderComponent from "../Header/HeaderComponent";
import CategoriesComponent from "./CategoriesComponent";
import GamesLayout from "../layout/Basic/GamesLayout";
import CommissionModal from "./GameItemComponents/CommissionModal";
import GamesContainer from "./GamesContainer";
import { FixedHeader } from "./FixedHeader";
import { isLobbyInIframe } from "../../utils/helpers";

function GamesComponentTest() {
  const { categories, loading: isLoadingGames } = useSelector((state) => state.gameCategories);
  const { token } = useSelector((state) => state.user);
  const { operatorId, deviceType } = useSelector((state) => state.startUp);
  const history = useHistory();
  const dispatch = useDispatch();

  const [action, setAction] = useState(() => () => {});
  const [catName, setCatName] = useState("");
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [item, setItem] = useState(null);

  const redirect = async () => {
    const openGameUrl = localStorage.getItem("openGameUrl");
    const root = document.getElementById("root");

    const loginParams = JSON.parse(Cookies.get("loginParams") || "{}");
    if (openGameUrl) {
      localStorage.removeItem("openGameUrl");
      Cookies.remove("openGameUrl");
      root.style.visibility = "hidden";

      if (loginParams.alert) {
        // eslint-disable-next-line no-alert
        alert(window.location.href);
      }

      window.location.href = openGameUrl;
    }
  };

  const toggleModal = (param) => {
    setIsModalOpened(!isModalOpened);
    setItem(!isModalOpened ? param : null);
  };

  const gameSelection = (_item, _action) => {
    const game = {
      gameId: _item.gameId,
      limitId: _item.limitSet.limitSetId,
      gameType: _item.gameType,
      isSuperSix: _item.isSuperSix
    };

    if (deviceType === "Web") {
      GAService.sendEvent("SelectWebGame", `gameid:${game.gameId}`);
      dispatch(_action(game));
    } else {
      GAService.sendEvent("SelectMobileGame", `gameid:${game.gameId}`);
      const languageId = localStorage.getItem("languageId") || "2057";
      const dataUrl = {
        operatorId,
        token,
        languageId,
        deviceType
      };
      if(isLobbyInIframe()){
        return window.location.replace(getGamePath(dataUrl, game));
      }
      window.location.href = getGamePath(dataUrl, game);
    }
  };

  useEffect(() => {
    if (token && !isLoadingGames) {
      let categoryName = localStorage.getItem("selectedCategory");
      if (!!token && categories.length) {
        const categoryExists = categories.find((category) => category.Name.toLowerCase() === categoryName);
        if (!categoryExists) {
          categoryName = categories[0].Name.toLowerCase();
        }
        if (catName !== categoryName) {
          history.replace({
            pathname: `/category/${categoryName}`,
            search: `?operatorId=${operatorId}`,
            state: {
              category: categoryName
            }
          });
          dispatch(selectCategory(categoryName));
          setCatName(categoryName);
          redirect();
        }
      }
    }
  });

  return (
    <GamesLayout>
      <FixedHeader>
        <HeaderComponent />
        <CategoriesComponent />
      </FixedHeader>
      <CommissionModal
        maxWidth={deviceType === "Web" ? 500 : 335}
        toggleModal={toggleModal}
        gameSelection={gameSelection}
        action={action}
        isModalOpened={isModalOpened}
        item={item}
      />
      <GamesContainer
        isLoadingGames={isLoadingGames}
        toggleModal={toggleModal}
        gameSelection={gameSelection}
        setAction={setAction}
      />
    </GamesLayout>
  );
}

export default sidebarMenu(GamesComponentTest);
