import React, { useEffect, useState } from "react";
import { loginFromChangePassword } from "src/store/components/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import ChangePasswordForm from "../ChangePassword/ChangePasswordForm";
import { logoXpg, getUrlParams } from "../../utils/commonUtils";
import LoaderComponent from "../common/LoaderComponent";
import StyledFormComponent from "../common/formComponentStyles";
import BasicLayout from "../layout/Basic";
import { ErrorCodes } from "src/utils/userUtils";

import MessageView from "../common/MessageView";
import { Redirect } from "react-router-dom";
import { Wrapper } from "./styles";
import useWindowHeight from "src/customHooks/useWindowHeight";

const ChangePasswordComponent = () => {
  const { ipNotAuthorized, mobileLogoUrl } = useSelector((state) => state.startUp);
  const { loginFromChangePasswordStatus, loginFromChangePasswordResponse } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();

  const [redirectToForgotPasswordFailed, setRedirectToForgotPasswordFailed] = useState(false);
  const [redirectToActivationFailed, setRedirectToActivationFailed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isOpenedFromMail, setIsOpenedFromMail] = useState(false);
  const [errorCode, setErrorCode] = useState(-1);

  useEffect(() => {
    const rawUrlParams = getUrlParams("/changepassword");

    console.log("Url raw params: " + JSON.stringify(rawUrlParams));

    if (!!rawUrlParams.registerCode && !!rawUrlParams.username) {
      setShowLoader(true);
      dispatch(
        loginFromChangePassword({
          userName: rawUrlParams.username,
          token: rawUrlParams.registerCode
        })
      );
    }
  }, []);

  useEffect(() => {
    if (loginFromChangePasswordStatus === "success") {
      switch (loginFromChangePasswordResponse.errorCode) {
        case ErrorCodes.NO_ERROR:
          setIsOpenedFromMail(true);
          break;
        case ErrorCodes.PLAYER_BANNED:
        case ErrorCodes.FORGOTTEN_PASSWORD_TOKEN_EXISTS:
          setRedirectToForgotPasswordFailed(true);
          setErrorCode(Number(loginFromChangePasswordResponse.errorCode));
          break;
        case ErrorCodes.PLAYER_NOT_ACTIVATED:
          setRedirectToActivationFailed(true);
          break;
        default:
          setRedirectToForgotPasswordFailed(true);
      }
      setShowLoader(false);
    }
  }, [loginFromChangePasswordStatus]);

  const src = mobileLogoUrl || logoXpg;
  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          {showLoader ? (
            <LoaderComponent message={<FormattedMessage id="GLloading" />} />
          ) : (
            <div className="screen">
              <Grid container justifyContent="center">
                <Grid item lg={4} md={6} sm={8} xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item xs={6}>
                      <img src={src} className="img" alt="logo" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <MessageView ipNotAuthorized={ipNotAuthorized} className="message" />
              <h2>
                <FormattedMessage id="GLChangePassword" />
              </h2>
              {redirectToForgotPasswordFailed && (
                <Redirect to={{ pathname: "/forgotPasswordSentFailed", state: errorCode }} />
              )}

              {redirectToActivationFailed && <Redirect to="/activationFailed" />}

              <ChangePasswordForm isOpenedFromMail={isOpenedFromMail} />
            </div>
          )}
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default ChangePasswordComponent;
