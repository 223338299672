import React from "react";
import styled from "styled-components";
import ButtonClose from "./ButtonClose";
import Heading from "./Heading";
import { getBP } from "src/utils/styleUtils";

const OverlayWrap = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 17;
  height: 100vh;
  background: rgba(17, 17, 26, 0.95);
  box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 4px;
  @media (min-width: ${({ theme: t }) => getBP(t, "mobile")}) {
    width: 100%;
    @media (orientation: landscape) {
      width: 94%;
    }
  }
  @media (min-width: ${({ theme: t }) => getBP(t, "tablet")}) {
    width: 94%;
  }
`;

const Overlay = ({ title, icon, children }) => (
  <OverlayWrap>
    {title && <Heading title={title} icon={icon} />}
    {children}
    <ButtonClose />
  </OverlayWrap>
);

export default Overlay;
