// @flow
import React from "react";

export const AuthContext = React.createContext({});
export const ErrorContext = React.createContext({});
export const ParamContext = React.createContext({});
export const LoadingContext = React.createContext({
  name: "Loading"
});

export const QueryContext = React.createContext({
  state: {
    data: null,
    loading: false,
    error: null
  },
  actions: {}
});
export const BackgroundContext = React.createContext({});
