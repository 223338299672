import type { ExternalGameDataResponse } from "../clientModels/ExternalGameDataResponse";
import type {
  ExternalGameDataRequest,
  SignUpDataRequest
} from "../clientModels/ExternalGameDataRequest";
import type { LCExternalGameDataResponse } from "../serverModels/LCExternalGameDataResponse";
import type { LCExternalGameDataRequest } from "../serverModels/LCExternalGameDataRequest";

import type { ServiceResponse } from "../ResponseTypes";

export const mapExternalGameUrl = (
  request: ExternalGameDataRequest
): LCExternalGameDataRequest => ({
  operatorID: request.operatorId,
  accessPassword: request.token,
  gameID: request.gameId,
  username: request.userName,
  language: request.language,
  gameProvider: request.gameProvider
});

export const mapSignUpFields = (
  request: SignUpDataRequest
): LCSignUpDataRequest => ({
  operatorID: request.operatorId,
  username: request.userName,
  nickname: request.userName,
  userPassword: request.passwordVerification,
  email: request.email,
  firstName: request.firstName,
  lastName: request.lastName
});

export const resolveSignUpFields = (data: any) => ({
  errorCode: parseInt(data.errorCode, 10)
});

const mapExternalGameUrlResponse = (
  data: LCExternalGameDataResponse
): ExternalGameDataResponse => ({
  url: data.gameUrl
});

export const resolveResponse = (
  response: ServiceResponse<LCExternalGameDataResponse>
): ExternalGameDataResponse => {
  return mapExternalGameUrlResponse(response.data);
};
