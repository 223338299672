// Transform n arg. in procent, 16==100%
export const fontSize = (n: number): string => `${(100 * n) / 16}%`;

// The chunk method breaks the collection into multiple, smaller collections of a given size
export const chunks = (arr: Array<*>, size: number) => {
  const chunked = [];

  Object.keys(arr).forEach((ele) => {
    const last = chunked[chunked.length - 1];
    if (!last || last.length === size) {
      chunked.push([ele]);
    } else {
      last.push(ele);
    }
  });
  return chunked;
};

// The isEmpty method check is empty object
export const isEmpty = (arg: Object) => {
  for (const item in arg) {
    return false;
  }
  return true;
};

export const isInArray = (value, array) => array.indexOf(value) > -1;

export const findObjectByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return true;
    }
  }
  return false;
};

export const getObjectByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return array[i];
    }
  }
  return null;
};

export const checkUrlHTTP = (url) => {
  if (!url.match(/^http/i)) {
    return (url = "http://" + url);
  }
  return url;
};

export const extractHostname = (url) => {
  let hostname;

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
};

export const openFullscreen = () => {
  const element = document.querySelector("body");
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen(); /* Firefox */
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen(); /* Chrome, Safari and Opera */
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen(); /* IE/Edge */
  }
};

export const isFullScreenBrowser = () =>
  document.fullScreen ||
  document.mozFullScreen ||
  document.webkitIsFullScreen ||
  document.msFullscreenElement != null ||
  window.screen.height <= window.innerHeight;

export const isLobbyInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getLocStorage = (key: string) => JSON.parse(localStorage.getItem(key));

export const setLocStorage = (key: string, value: Object) => localStorage.setItem(key, JSON.stringify(value || "{}"));

export const iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
// The isEmpty method check is empty object
// export const isEmpty = (obj: Object = {}) => {
//   Object.values(obj).forEach(
//     prop => !Object.prototype.hasOwnProperty.call(obj, prop)
//   );
//   return true;
// };
export const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

export const numberWithCommas = (n) => {
  if (n % 1000 === 0 && n.toString().length < 5) {
    return n;
  }
  return n.toLocaleString("en-US");
};
