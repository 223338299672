import React from "react";
import { useSelector } from "react-redux";
import { LoadingContext } from "../../contexts";

const LoadingProvider = ({ children }) => {
  const { loadingAppStep } = useSelector((state) => state.app);
  const isLoading = loadingAppStep !== "finish";

  return <LoadingContext.Provider value={{ isLoadingApp: isLoading }}>{children}</LoadingContext.Provider>;
};

export default LoadingProvider;
