import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CountryErrorImage } from "src/Components/ui/Icon/svg/country-not-available.svg";

const CountryErrorSVG = () => <CountryErrorImage />;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #0d0d0d;
  color: #d7d7d9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin-top: 20px;
    text-align: center;
    line-height: 2rem;
    font-size: 1.5rem;
  }
`;

const ErrorScreenCountry = () => (
  <Wrapper id="error">
    <CountryErrorSVG />
    <p>
      <FormattedMessage id="IpCountryError" defaultMessage="Not available in your country" />
    </p>
  </Wrapper>
);
export default ErrorScreenCountry;
