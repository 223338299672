import React from "react";
import styled from "styled-components";

const Item = styled.div`
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  margin: 10px;
`;

const GridTypeItem = ({ image, name, handler }) => {
  return (
    <Item onClick={() => handler()}>
      <Icon src={image} alt={name} />
    </Item>
  );
};

export default GridTypeItem;
