import React from "react";
import WelcomeBox from "src/Components/Welcome";
import { FormattedMessage } from "react-intl";
import BasicLayout from "src/Components/layout/Basic";
import { useSelector } from "react-redux";
import { logoXpg } from "src/utils/commonUtils";

const WelcomePage = ({ description }) => {
  const { mobileLogoUrl } = useSelector((state) => state.startUp);

  const src = mobileLogoUrl || logoXpg;
  return (
    <BasicLayout>
      <WelcomeBox
        icon={<img src={src} alt="" />}
        title={description ? description : <FormattedMessage id="welcome.title" defaultMessage="Welcome" />}
      />
    </BasicLayout>
  );
};

export default WelcomePage;
