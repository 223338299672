import React from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ScrollWrapper } from "src/Components/ui/simpleBar";

const GamesScrollList = ({ children, classNameData }) => {
  const { pathname } = useLocation();
  const gamesScrollList = useRef(null);

  useEffect(() => {
    gamesScrollList.current.scrollTop = 0;
  }, [pathname]);

  return (
    <ScrollWrapper id="scrollList" ref={gamesScrollList} className={classNameData}>
      {children}
    </ScrollWrapper>
  );
};

export default GamesScrollList;
