// @flow

export const LANGUAGES = {
  "2057": "en-gb",
  "2052": "zh-cn",
  "1042": "ko",
  "1057": "id",
  "1054": "th",
  "1049": "ru",
  "1038": "hu",
  "1055": "tr",
  "1040": "it-it",
  "1032": "el",
  "3082": "es",
  "1036": "fr-fr",
  "1031": "de-de",
  "1046": "pt-br",
  "1043": "nl-nl",
  "1030": "da",
  "1086": "ms-my",
  "1041": "ja",
  "1065": "fa-ir"
};

export const GAMES_RULES_LINK = "http://manual.xpg.live/";

export const MENU_ITEMS_DEFAULT = [
  { key: "history", route: false },
  { key: "languages", route: false },
  { key: "gamerules", route: false },
  { key: "changepassword", route: true },
  { key: "filter", route: false }
];


export const SLOT_FILTER_ITEMS=[
  {value:[0,5], text: "MGFilter.0-5slots"},
  {value:[5,10], text: "MGFilter.5-10slots"},
  {value:[10,20], text: "MGFilter.10-20slots"},
  {value:[20,9999], text: "MGFilter.20+slots"},
  {value:[-1,-1], text: "MGFilter.Jackpot"},
];

export const GAME_TYPE = {
  roulette: 1,
  blackjack: 2,
  baccarat: 4,
  singlePoker: 8,
  dragonTiger: 12,
  sicBo: 16,
  carribeanPoker: 18,
  multiPoker: 20,
  wheelOfFortune: 22,
  andarBahar: 24,
  teenPatti: 26
};
