import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";

import { StyledInfoPage } from "../../../../../common/formControlStyles";
import IconConfirmComponent from "src/Components/Info/IconConfirmComponent";
import { useHistory } from "react-router-dom";

const ChangePasswordSuccessMessage = () => {
  const history = useHistory();

  return (
    <StyledInfoPage>
      <Grid container alignItems="center" justifyContent="center" className="login">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Grid container alignItems="center" justifyContent="center" className="login" spacing={1}>
            <Grid item xs={12}>
            <IconConfirmComponent />
            </Grid>
            <Grid item xs={12}>
              <div className="mainText">
              <FormattedMessage id="GLchangedPasswordSuccessfully" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                classes={{
                  root: "button"
                }}
                onClick={() => history.goBack()}
              >
                {<FormattedMessage id="GLok" />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledInfoPage>
  );
};

export default ChangePasswordSuccessMessage;
