// @flow

import qs from "qs";
import axios from "axios";
import { parseString } from "xml2js/lib/parser";

import type { GameDataResponse } from "../clientModels/GameDataResponse";
import type { GamesCategoryInformationRequest } from "../clientModels/GamesCategoryInformationRequest";
import type { GamesCategoryInformationResponse } from "../clientModels/GamesCategoryInformationResponse";
import type { ExternalGameDataResponse } from "../clientModels/ExternalGameDataResponse";
import type { ExternalGameDataRequest } from "../clientModels/ExternalGameDataRequest";
import * as GameDataMapper from "../mappers/GameDataMapper";
import * as GamesCategoryInformationMapper from "../mappers/GamesCategoryInformationMapper";
import * as ExternalGameUrlMapper from "../mappers/ExternalGameUrlMapper";
import * as MapResponse from "../utils/MapResponse";

export default class GamesProvider {
  request = (uri: string, request: any, responseResolver: any) => {
    const time = new Date().getTime();
    const endPoint = `${uri}?t=${time}`;

    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        url: endPoint,
        headers: { "Content-Type": "multipart/form-data" },
        data: qs.stringify(request)
      };

      axios(options)
        .then(response => {
          let responseData = {};

          parseString(response.data, { explicitArray: false, ignoreAttrs: true }, (err, result) => {
            responseData = result;
          });

          if (response.status === 200) {
            if (parseInt(responseData.response.errorCode, 10) === 0) {
              let res = MapResponse.success(responseData.response);
              resolve(responseResolver(res));
            } else {
              console.log("server response encounter error with status: " + JSON.stringify(responseData));
              reject(MapResponse.error(responseData.response));
            }
          } else {
            console.log("server response encounter error with status: " + response.status);
            throw MapResponse.exception(response.status, response.statusText);
          }
        })
        .catch(error => {
          // TODO add here logic for error handling/showing requests exeption on UI(visual representation)
          reject(error);
          throw MapResponse.exception(error.name, error.message);
        });
    });
  };

  getCategoryGameData = (apiClientUrl, request: GameDataRequest): Promise<GameDataResponse> =>
    this.request(
      apiClientUrl + "Services/ExternalApi.svc/getCategoryGameData",
      {
        ...GameDataMapper.mapGameDataRequest(request)
      },
      GameDataMapper.resolveResponse
    );

  getCategoryInformation = (
    apiClientUrl,
    request: GamesCategoryInformationRequest
  ): Promise<GamesCategoryInformationResponse> =>
    this.request(
      apiClientUrl + "Services/ExternalApi.svc/getCategoryInformation",
      {
        ...GamesCategoryInformationMapper.mapCategoryInformationRequest(request)
      },

      (data: any) => {
        return GamesCategoryInformationMapper.resolveResponse(request.deviceType, data);
      }
    );

  getExternalGameUrl = (apiClientUrl, request: ExternalGameDataRequest): Promise<ExternalGameDataResponse> =>
    this.request(
      apiClientUrl + "Services/ExternalApi.svc/getExternalGameUrl",
      { ...ExternalGameUrlMapper.mapExternalGameUrl(request) },

      (data: any) => {
        return ExternalGameUrlMapper.resolveResponse(data);
      }
    );

  signUp = (apiClientUrl, request: any): Promise<any> => {
    return this.request(
      apiClientUrl + "Services/ExternalApi.svc/createAccount",
      { ...ExternalGameUrlMapper.mapSignUpFields(request) },
      (data: any) => ExternalGameUrlMapper.resolveSignUpFields(data)
    );
  };
}
