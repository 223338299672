import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Icon from "src/Components/ui/Icon";
import { GAMES_RULES_LINK } from "src/constants";

const List = styled.ul`
  font-size: 14px;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  white-space: nowrap;
  .item-menu {
    text-decoration: none;
    color: #e6e6e6;
    padding: 10px 0;
    display: flex;
    flex: 1;
    align-items: center;
    span {
      margin: 0 20px;
      &.icon-home {
        margin: 0 18px;
      }
    }
  }
`;

const MGLMenuItems = ({ MGLMenuItems, onToggleMenu, onOpenTabMenu, onTogglePanelMenu }) => (
  <List>
    {MGLMenuItems.map((item) => (
      <Item key={`menu-${item.key}`}>
        {(() => {
          if (item.key === "gamerules") {
            return (
              <a className="item-menu" href={GAMES_RULES_LINK} target="_blank" rel="noopener noreferrer">
                <Icon name={item.key} width="25" height="24" />
                <FormattedMessage id={`GLMenu.${item.key}`} defaultMessage={item.key} />
              </a>
            );
          } else if (item.route) {
            return (
              <Link className="item-menu" to={`/${item.key}`}>
                <Icon name={item.key} width="25" height="24" />
                <FormattedMessage id={`GLMenu.${item.key}`} defaultMessage={item.key} />
              </Link>
            );
          }
          return (
            <div
              className="item-menu"
              onClick={() => {
                onToggleMenu(false);
                onTogglePanelMenu(true);
                onOpenTabMenu(item.key);
              }}
            >
              <Icon name={item.key} width="25" height="24" />
              <FormattedMessage id={`GLMenu.${item.key}`} defaultMessage={item.key} />
            </div>
          );
        })()}
      </Item>
    ))}
  </List>
);

export default MGLMenuItems;
