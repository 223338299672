// @flow
import type {
  ChangePasswordRequest,
  ForgotPasswordRequest,
  ForgotPasswordByUserNameRequest,
  ActivateAccountRequest
} from "../clientModels/UserRequest";
import type {
  LCChangePasswordRequest,
  LCForgotPasswordRequest,
  LCForgotPasswordByUserNameRequest,
  LCActivationAccountRequest
} from "../serverModels/LCUserRequest";
import type { ServiceResponse } from "../ResponseTypes";
import type {
  ChangePasswordResponse,
  ForgotPasswordResponse,
  ForgotPasswordByUsernameResponse,
  ActivationAccountResponse
} from "../clientModels/UserResponse";
import type {
  LCChangePasswordResponse,
  LCForgotPasswordResponse,
  LCForgotPasswordByUserNameResponse,
  LCActivationAccountResponse
} from "../serverModels/LCUserResponse";

export const mapChangePasswordRequest = (
  request: ChangePasswordRequest
): LCChangePasswordRequest => ({
  operatorID: request.operatorId,
  registerToken: request.token,
  newPassword: request.newPassword,
  isPasswordHashed: request.isPasswordHashed
});

export const mapForgotPasswordRequest = (
  request: ForgotPasswordRequest
): LCForgotPasswordRequest => ({
  operatorID: request.operatorId,
  eMail: request.email
});

export const mapForgotPasswordByUserNameRequest = (
  request: ForgotPasswordByUserNameRequest
): LCForgotPasswordByUserNameRequest => ({
  operatorId: request.operatorId,
  userName: request.username
});

export const mapActivationAccountRequest = (
  request: ActivateAccountRequest
): LCActivationAccountRequest => ({
  operatorId: request.operatorId,
  registerCode: request.activationToken
});

export const resolveChangePasswordResponse = (
  response: ServiceResponse<LCChangePasswordResponse>
): ChangePasswordResponse => {
  return mapChangePasswordResponse(response.data);
};

const mapChangePasswordResponse = (
  data: LCChangePasswordResponse
): ChangePasswordResponse => ({});

export const resolveForgotPasswordResponse = (
  response: ServiceResponse<LCForgotPasswordResponse>
): ForgotPasswordResponse => {
  return mapForgotPasswordResponse(response.data);
};

const mapForgotPasswordResponse = (
  data: LCForgotPasswordResponse
): ForgotPasswordResponse => ({});

export const resolveForgotPasswordByUserNameResponse = (
  response: ServiceResponse<LCForgotPasswordResponse>
): ForgotPasswordByUsernameResponse => {
  return mapForgotPasswordByUsernameResponse(response.data);
};

const mapForgotPasswordByUsernameResponse = (
  data: LCForgotPasswordByUserNameResponse
): ForgotPasswordByUsernameResponse => ({});

export const resolveActivationAccountResponse = (
  response: ServiceResponse<LCActivationAccountResponse>
): ActivationAccountResponse => {
  return mapActivationAccountResponse(response.data);
};

const mapActivationAccountResponse = (
  data: LCActivationAccountResponse
): ActivationAccountResponse => {
  let response = data.description.split(";");
  return {
    token: response[0],
    userName: response[1],
    playerId: parseInt(response[2], 10),
    externalSessionId: response[3],
    errorCode: data.errorCode
  };
};
