import React from "react";
import { BrowserRouter } from "react-router-dom";
import defaultHOCs from "src/hocs/defaultHOCs";
import DefaultLayout from "src/Components/layout/Default/DefaultLayout";
import AuthProvider from "./provider/Auth";
import ErrorProvider from "./provider/Error";
import LoadingProvider from "./provider/Loading";
import Routes from "./routes";
import BackgroundProvider from "./provider/Background";
import { ThemeProvider } from "styled-components";
import { theme } from "src/styles/theme";

const App = () => (
  <BackgroundProvider>
    <DefaultLayout>
      <LoadingProvider>
        <ErrorProvider>
          <AuthProvider>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </BrowserRouter>
          </AuthProvider>
        </ErrorProvider>
      </LoadingProvider>
    </DefaultLayout>
  </BackgroundProvider>
);

export default defaultHOCs(App);
