import React, { useEffect, useState } from "react";
import { resetForgotPassword } from "src/store/components/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import ForgotPasswordForm from "../ForgotPassword/ForgotPasswordForm";
import { Redirect } from "react-router-dom";
import { logoXpg } from "../../utils/commonUtils";
import StyledFormComponent from "../common/formComponentStyles";

import MessageView from "../common/MessageView";
import BasicLayout from "../layout/Basic";
import { ErrorCodes } from "src/utils/userUtils";
import { forgotPassword } from "src/store/components/user/userActions";
import { Wrapper } from "./styles";
import useWindowHeight from "src/customHooks/useWindowHeight";

const ForgotPasswordComponent = () => {
  const { ipNotAuthorized, mobileLogoUrl } = useSelector((state) => state.startUp);
  const { forgotPasswordSent, forgotPasswordStatus, forgotPasswordResponse } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();

  const [redirectToForgotPasswordFailed, setRedirectToForgotPasswordFailed] = useState(false);
  const [redirectToActivationFailed, setRedirectToActivationFailed] = useState(false);
  const [errorCode, setErrorCode] = useState(-1);

  const onSubmit = (data) => {
    dispatch(forgotPassword(data));
  };

  useEffect(() => {
    if (forgotPasswordStatus === "error") {
      switch (forgotPasswordResponse.errorCode) {
        case ErrorCodes.PLAYER_BANNED:
        case ErrorCodes.FORGOTTEN_PASSWORD_TOKEN_EXISTS:
          setRedirectToForgotPasswordFailed(true);
          setErrorCode(Number(forgotPasswordResponse.errorCode));
          break;
        case ErrorCodes.PLAYER_NOT_ACTIVATED:
          setRedirectToActivationFailed(true);
          break;
        default:
      }
    }
  }, [forgotPasswordStatus]);

  useEffect(() => {
    return () => dispatch(resetForgotPassword());
  }, []);

  const src = mobileLogoUrl || logoXpg;
  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          <div className="screen">
            <Grid container justifyContent="center">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <img src={src} className="img" alt="logo" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <MessageView ipNotAuthorized={ipNotAuthorized} className="message" />
            <h2>
              <FormattedMessage id="GLForgotPassword" />
            </h2>
            {redirectToForgotPasswordFailed && (
              <Redirect to={{ pathname: "/forgotPasswordSentFailed", state: errorCode }} />
            )}
            {redirectToActivationFailed && <Redirect to="/activationFailed" />}
            {forgotPasswordSent && <Redirect to="/forgotPasswordSent" />}
            <ForgotPasswordForm submit={onSubmit} />
          </div>
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default ForgotPasswordComponent;
