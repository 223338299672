import React from "react";
import styled from "styled-components";
import Modal from "../../../ui/Modal";
import Switch from "./CommissionSwitchComponent";
import { getBP } from "src/utils/styleUtils";

const Header = styled.div`
  text-transform: uppercase;
  padding-top: 43px;
  text-align: center;
  color: rgba(230, 230, 230, 1);
  font-size: 20px;
  letter-spacing: 1px;
  @media (min-width: ${({ theme }) => getBP(theme, "mobile")}) {
    padding-top: 25px;
    font-size: 16px;
  }
  @media (min-width: ${({ theme }) => getBP(theme, "desktop")}) {
    padding-top: 43px;
    font-size: 20px;
  }
`;

const CommissionModal = ({ maxWidth, action, gameSelection, toggleModal, isModalOpened, item }) => {
  const setCommission = (isCommission) => {
    if (isCommission !== null) {
      const commissionItem = { ...item, isSuperSix: isCommission ? 1 : 0 };
      gameSelection(commissionItem, action);
    }

    toggleModal();
  };

  return (
    <Modal isOpen={isModalOpened} maxWidth={maxWidth} onRequestClose={() => setCommission(null)}>
      <Header>{!!item && item.gameName}</Header>
      <Switch onClosePopup={setCommission} />
    </Modal>
  );
};

export default CommissionModal;
