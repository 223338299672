import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStartUp } from "src";

const Gif = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80%;
    max-width: 64rem;
  }
`;

export default function withCookies(ComposedComponent) {
  function areCookiesAllowed() {
    try {
      return !!localStorage || !!sessionStorage;
    } catch (e) {
      return false;
    }
  }

  return function Cookies(props) {
    const [allow, setAllow] = useState(null);
    useEffect(() => {
      if (allow) {
        useStartUp();
      }
    }, [allow]);

    useEffect(() => {
      setAllow(areCookiesAllowed());
    }, []);

    return allow ? (
      <ComposedComponent {...props}></ComposedComponent>
    ) : (
      allow !== null && (
        <Gif>
          <img src="/assets/img/lobby/block-cookie.gif" alt="cookie" />
        </Gif>
      )
    );
  };
}
