import React, { useEffect, useState } from "react";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Lock from "@material-ui/icons/Lock";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import { changePassword } from "src/store/components/user/userActions";
import { sendChangePasswordFailed, resetChangePassword } from "src/store/components/user/userSlice";
import InlineError from "../common/InlineError";
import StyledChangePasswordForm from "../common/formControlStyles";

const ChangePasswordForm = ({ isOpenedFromMail }) => {
  const { changePasswordInProgress, changePasswordFailed, changePasswordStatus, changePasswordError } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: ""
  });
  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
    global: ""
  });

  const history = useHistory();

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateClient = (data) => {
    const validationErrors = {};

    let newPass = null;
    let confirmPass = null;

    if (!data.newPassword) {
      newPass = "GLCantBeBlank";
    } else if (errors.newPassword) {
      newPass = errors.newPassword;
    }
    validationErrors.newPassword = newPass;

    if (!data.confirmPassword) {
      confirmPass = "GLCantBeBlank";
    } else if (data.confirmPassword !== data.newPassword) {
      confirmPass = "GLPasswordNotMatch";
    } else if (errors.confirmPassword) {
      confirmPass = errors.confirmPassword;
    }
    validationErrors.confirmPassword = confirmPass;

    const newErrors = { ...errors, ...validationErrors };
    setErrors(newErrors);
    return Object.values(newErrors).every((item) => !item);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validClient = validateClient(data);
    if (validClient) {
      setDisabled(true);
      dispatch(changePassword(data));
    }
  };

  const validateServer = (err) => {
    const errors = {};
    if (+err.errorCode === 29) {
      errors.newPassword = "GLpasswordTooShort";
      errors.confirmPassword = "GLpasswordTooShort";
      setErrors((prev) => ({ ...prev, ...errors }));
    }
    return true;
  };

  useEffect(() => {
    if (changePasswordStatus === "success") {
      history.replace('/changePasswordSuccess');
    } else if (changePasswordStatus === "error") {
      validateServer(changePasswordError);
      setDisabled(false);
    }
  }, [changePasswordStatus]);

  useEffect(() => () => dispatch(resetChangePassword()), []);

  const goBack = () => {
    window.history.back();
    dispatch(sendChangePasswordFailed(""));
  };

  return (
    <StyledChangePasswordForm>
      <Grid container alignItems="center" justifyContent="center" className="login">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
              {changePasswordFailed && changePasswordFailed !== "GLpasswordTooShort" && (
                <Grid item xs={12}>
                  <span className="globalErrorWrapper">
                    <InfoRounded className="globalErrorIcon" />
                    <InlineError className="globalError" error={changePasswordFailed} />
                  </span>
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={1}>
                        <Lock
                          classes={{
                            root: "icon"
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <FormControl
                          error={!!errors.newPassword}
                          classes={{ root: "formControl" }}
                          disabled={changePasswordInProgress}
                        >
                          <InputLabel
                            htmlFor="newPassword"
                            classes={{
                              root: "cssLabel",
                              focused: "cssFocused",
                              disabled: "cssLabelDisabled"
                            }}
                          >
                            <FormattedMessage id="GLNewPassword" />
                          </InputLabel>
                          <Input
                            classes={{
                              root: "fieldRoot",
                              disabled: "fieldDisabled",
                              underline: "fieldUnderline",
                              focused: "fieldFocused",
                              error: "fieldError"
                            }}
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={data.newPassword.replace(/ /g, "")}
                            onChange={onChange}
                          />
                          {errors.newPassword && <InlineError className="labelError" error={errors.newPassword} />}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={1}>
                        <Lock
                          classes={{
                            root: "icon"
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <FormControl
                          error={!!errors.confirmPassword}
                          classes={{ root: "formControl" }}
                          disabled={changePasswordInProgress}
                        >
                          <InputLabel
                            htmlFor="confirmPassword"
                            classes={{
                              root: "cssLabel",
                              focused: "cssFocused",
                              disabled: "cssLabelDisabled"
                            }}
                          >
                            <FormattedMessage id="GLConfirmPassword" />
                          </InputLabel>
                          <Input
                            classes={{
                              root: "fieldRoot",
                              disabled: "fieldDisabled",
                              underline: "fieldUnderline",
                              focused: "fieldFocused",
                              error: "fieldError"
                            }}
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={data.confirmPassword}
                            onChange={onChange}
                          />
                          {errors.confirmPassword && (
                            <InlineError className="labelError" error={errors.confirmPassword} />
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="buttonsWrapper">
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      classes={{
                        root: "button",
                        disabled: "buttonDisabled"
                      }}
                      onClick={onSubmit}
                      disabled={changePasswordInProgress}
                    >
                      <FormattedMessage id="GLSubmit" />
                      {disabled && <CircularProgress size={24} className="buttonProgress" />}
                    </Button>
                  </Grid>
                  {!isOpenedFromMail && (
                    <Grid item xs={12}>
                      <Button className="buttonSnd" onClick={goBack}>
                        <FormattedMessage id="GLgoBack" />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </StyledChangePasswordForm>
  );
};

export default ChangePasswordForm;
