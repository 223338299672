// @flow

import type { ApiParamsRequest } from "../clientModels/ApiParamsRequest";
import type { LCApiParamsRequest } from "../serverModels/LCApiParamsRequest";
import type { ServiceResponse } from "../ResponseTypes";
import type { ApiParamsResponse } from "../clientModels/ApiParamsResponse";
import type { LCApiParamsResponse } from "../serverModels/LCApiParamsResponse";
import { MENU_ITEMS_DEFAULT } from "src/constants";
import { getObjectByKey } from "src/utils/helpers";

export const mapApiParamsRequest = (request: ApiParamsRequest): LCApiParamsRequest => ({
  url: "/" + request.operatorId + "/" + request.gameTypeId
});

export const resolveResponse = (response: ServiceResponse<LCApiParamsResponse>): ApiParamsResponse => {
  return response.data.IpNotAuthorized ? response.data : mapApiParamsResponse(response.data);
};

const mapApiParamsResponse = (data: LCApiParamsResponse): ApiParamsResponse => {
  const menuMapper = (key) => {
    if (data[key]) {
      return data[key]
        .toLowerCase()
        .split(",")
        .map((el) => getObjectByKey(MENU_ITEMS_DEFAULT, "key", el.trim()))
        .filter(Boolean);
    }
  };
  const pCountFiltered = data.PlayersCount.split("-");
  const isPCountValid = !isNaN(pCountFiltered[0]) && !isNaN(pCountFiltered[1]);
  const playersCount =
    pCountFiltered[1] && isPCountValid ? { min: Number(pCountFiltered[0]), max: Number(pCountFiltered[1]) } : null;
  return {
    clientSessionLength: parseInt(data.clientSessionLength, 10),
    extendTokenInterval: parseInt(data.extendTokenInterval, 10),
    loginTokenExtendTime: parseInt(data.loginTokenExtendTime, 10),
    operatorId: data.operatorId,
    languages: data.mobileLanguage && data.mobileLanguage.split(";"),
    ipNotAuthorized: data.IpNotAuthorized === "1",
    cdnPath: data.cdnURLResourcesPath,
    translationServerCommURL:
      !!data.translationServerCommURL &&
      data.translationServerCommURL.toLowerCase().replace("gateway.aspx", "") + "GetTraslatedDirctionary",
    forgotPasswordURL: data.ForgotPasswordURL,
    signUpURL: data.MobileRegisterURL,
    mobileLogoUrl: data.MobileLogoURL,
    canChangePass: !!+data.mobileChangePass,
    lobbyMenuItems: menuMapper("lobbyMenuItems") || [],
    MGLMenuItems: menuMapper("MiniGamesLobbyMenuItems") || [],
    apiClientUrl: data.mobileApiUrl,
    playersCount,
    GLTheme: data.GLTheme
  };
};
