import React from "react";
import styled from "styled-components";
import Icon from "src/Components/ui/Icon";
import { useDeviceState } from "src/utils/helpers";

const Players = styled.div`
  position: absolute;
  right: 10px;
  bottom: ${({isMobile}) => isMobile ? "none" : "7px"};
`;
const PlayersIconWrapper = styled.div`
  float: left;
  margin-right: 5px;
`;
const PlayersCount = styled.div`
  float: left;
  font-size: ${({isMobile}) => isMobile ? "10px" : "13px"};
  padding-top: ${({isMobile}) => isMobile ? "0" : "3px"};
`;

const PlayersCountComponent = ({ playersCount }) => {
  const { isMobile } = useDeviceState();
  return (
    <Players isMobile={isMobile}>
      <PlayersIconWrapper data-name="GL-playersCount-image">
        <Icon name="player" fill="#fff" width={`${isMobile ? "9px" : "10px"}`} height={`${isMobile ? "9px" : "10px"}`} baseline />
      </PlayersIconWrapper>
      <PlayersCount data-name="GL-playersCount-number" isMobile={isMobile}>{playersCount}</PlayersCount>
    </Players>
  );
};

export default PlayersCountComponent;
