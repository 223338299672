import React from "react";
import { StyledInfoPage } from "../common/formControlStyles";
import Warning from "@material-ui/icons/Warning";

const IconFailedComponent = () => {
  return (
    <StyledInfoPage>
      <div className={"iconWrapper warning"}>
        <Warning className={"icon warning"} />
      </div>
    </StyledInfoPage>
  );
};

export default IconFailedComponent;
