import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Icon from "../../../ui/Icon";

const AddTableWrapper = styled.div`
  position: relative;
  margin-top: -32px;
  float: left;
  z-index: 99;
  width: 100%;
  cursor: pointer;
  text-align: center;
`;
const AddTable = styled.div`
  top: 16px;
  position: relative;
  background-color: rgba(13, 13, 13, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 7px;
  font-size: 16px;
  line-height: 30px;
  display: inline-block;
  height: 30px;
  color: #e6e6e6;
  font-family: Roboto, Arial;
  text-transform: uppercase;
  padding-right: 13px;
  transition: 0.2s linear;

  &:hover {
    border: 1px solid #ffff1a;
  }
`;
const AddTableIconWrapper = styled.div`
  width: 14px;
  height: 14px;
  float: left;
  margin: 0 8px 0 13px;
  padding-top: 1px;
`;

const AddTableComponent = ({ item, onSelect: onSelectAction }) => {
  const onSelect = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelectAction(item);
  };

  return (
    <AddTableWrapper onClick={(e) => onSelect(item, e)}>
      <AddTable>
        <AddTableIconWrapper>
          <Icon name="addTable" fill="#fff" height="14" width="14" baseline />
        </AddTableIconWrapper>
        <FormattedMessage id="GLaddTable" values={{ breakLine: <br /> }} />
      </AddTable>
    </AddTableWrapper>
  );
};

export default AddTableComponent;
