import React, { Fragment } from "react";

import { createGlobalStyle } from "styled-components";
import { useSelector } from "react-redux";
import ErrorBox from "src/Components/Error";

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #1a1a1a;
        background-image: none !important;
    }
`;

const ErrorPage = () => {
  const { error } = useSelector((state) => state);
  const errorProp = error || {};

  return (
    <Fragment>
      <GlobalStyle />
      <ErrorBox error={errorProp} />
    </Fragment>
  );
};

export default ErrorPage;
