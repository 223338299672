import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import LoaderComponent from "../common/LoaderComponent";
import GamesScrollList from "./GamesScrollList";
import ExternalGame from "./GameItemComponents/ExternalGame";
import Game from "./GameItemComponents/Game";
import { useSelector } from "react-redux";
import { selectGame, switchGame, switchGameLimit } from "src/store/common/commonActions";
import { getBP } from "src/utils/styleUtils";
import { voidAction } from "src/store/components/multiView/multiViewSlice";
import { useDeviceState } from "src/utils/helpers";
import { filterGames } from "./utils/filterGames";

const StyledGamesComponent = styled.div`
  .games {
    padding: 15px 30px 0 30px;
    flex-grow: 1;
    z-index: 10;
    position: relative;
    height: calc(100vh - 189px);
    -webkit-overflow-scrolling: auto;

    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      height: ${({ isLoadingGames }) => (isLoadingGames ? "100vh" : "auto")};
      padding: ${({ isLoadingGames }) => (isLoadingGames ? "0px 15px 0 15px" : "115px 10px 0 10px")};
    }
  }

  #scrollList {
    overflow: auto !important;
  }

  .root {
    flex-grow: 1;
  }
`;

const CustomGrid = styled.div`
  @media (orientation: portrait) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
`;

const GamesContainer = ({ isLoadingGames, toggleModal, gameSelection, setAction }) => {
  const { isMobile, isLandscape } = useDeviceState();
  const { lobbyMode, games: openedGames, hideLobby } = useSelector((state) => state.multiView);
  const {
    games,
    externalGames: { games: externalGames }
  } = useSelector((state) => state.gameCategories);

  const isSameGame = (param) => openedGames.find((game) => game.gameId === param.gameId);

  const getAction = (param) => (!!isSameGame(param) && switchGameLimit) || (lobbyMode === 0 ? switchGame : selectGame);

  const switchLimit = (param) => {
    gameSelection(param, switchGameLimit);
  };

  const gameClick = (param) => {
    const actionFun = isSameGame(param) ? voidAction : getAction(param);
    setAction(() => (prop) => actionFun(prop));

    if (param.gameType === 4 && !isSameGame(param)) {
      toggleModal(param);
    } else gameSelection(param, actionFun);
  };

  const addGame = (param) => {
    setAction(() => (prop) => selectGame(prop));
    if (param.gameType === 4) {
      toggleModal(param);
    } else gameSelection(param, selectGame);
  };
  const sortedGames = filterGames(games);

  return (
    <StyledGamesComponent isLoadingGames={isLoadingGames}>
      <GamesScrollList classNameData={"games " + ((openedGames.length > 0 && !hideLobby && " hide") || "")}>
        {isLoadingGames ? (
          <LoaderComponent message={<FormattedMessage id="GLloadingGames" />} />
        ) : isMobile && !isLandscape ? (
          <CustomGrid>
            {sortedGames.length > 0 &&
              sortedGames.map((game, i) => (
                <Game game={game} key={`game-${i}`} selectGame={gameClick} addGame={addGame} switchGame={switchLimit} />
              ))}

            {!!externalGames.length &&
              externalGames.map((externalGame, i) => <ExternalGame key={`externalgame-${i}`} item={externalGame} />)}
          </CustomGrid>
        ) : (
          <Grid container className="root">
            {sortedGames.length > 0 &&
              sortedGames.map((game, i) => (
                <Game game={game} key={`game-${i}`} selectGame={gameClick} addGame={addGame} switchGame={switchLimit} />
              ))}

            {!!externalGames.length &&
              externalGames.map((externalGame, i) => <ExternalGame key={`externalgame-${i}`} item={externalGame} />)}
          </Grid>
        )}
      </GamesScrollList>
    </StyledGamesComponent>
  );
};

export default GamesContainer;
