// @flow

import React, { useCallback, useEffect, useState } from "react";
import SidebarMenu from "src/Components/Mobile/Components/Menu/Sidebar";

const mql = window.matchMedia(`(max-width: 959px)`);

const withSidebarMenu = (ComposedComponent) => {
  const Sidebar = (props) => {
    const [isMobile, setIsMobile] = useState(true);

    const mediaQueryChanged = useCallback(() => {
      setIsMobile(mql.matches);
    }, []);

    useEffect(() => {
      if (mql && mql.addEventListener) {
        mql.addEventListener("change", mediaQueryChanged);
      } else {
        mql.addListener(mediaQueryChanged);
      }
      mediaQueryChanged();

      return () => {
        if (mql && mql.removeEventListener) {
          mql.removeEventListener("change", mediaQueryChanged);
        } else {
          mql.removeListener(mediaQueryChanged);
        }
      };
    }, []);

    return (
      <SidebarMenu isMobile={isMobile}>
        <ComposedComponent {...props} />
      </SidebarMenu>
    );
  };

  return Sidebar;
};

export default withSidebarMenu;
