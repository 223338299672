import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import '@formatjs/intl-pluralrules/polyfill'
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/zh";
import "@formatjs/intl-pluralrules/locale-data/da";
import "@formatjs/intl-pluralrules/locale-data/de";
import "@formatjs/intl-pluralrules/locale-data/el";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-pluralrules/locale-data/hu";
import "@formatjs/intl-pluralrules/locale-data/it";
import "@formatjs/intl-pluralrules/locale-data/ja";
import "@formatjs/intl-pluralrules/locale-data/ko";
import "@formatjs/intl-pluralrules/locale-data/nl";
import "@formatjs/intl-pluralrules/locale-data/pt";
import "@formatjs/intl-pluralrules/locale-data/ru";
import "@formatjs/intl-pluralrules/locale-data/th";
import "@formatjs/intl-pluralrules/locale-data/tr";
import "@formatjs/intl-pluralrules/locale-data/id";
import "@formatjs/intl-pluralrules/locale-data/es";
import "@formatjs/intl-pluralrules/locale-data/ms";
import "@formatjs/intl-pluralrules/locale-data/fa";

import DefaultMessages from "../translations/en.json";
import localesId from "src/utils/commonUtils";

const msg = {
  en: DefaultMessages
};

const withIntl = (ComposedComponent) => {
  const ReactIntl = (props) => {
    const { language, translations } = useSelector((state) => state.startUp);

    const [locale, setLocale] = useState("en");
    const [messages, setMessages] = useState(msg.en);
    const [key, setKey] = useState("none");

    useEffect(() => {
      const isLanguageConfigured = !!localesId[language];
      if (Object.getOwnPropertyNames(translations).length > 0 && language > 0) {
        setLocale(localesId[language] || "en");
        setMessages(isLanguageConfigured ? Object.assign({}, msg.en, translations) : msg.en);
        setKey(Object.getOwnPropertyNames(translations).length > 0 ? localesId[language] || "en" : "none");
      }
    }, [language, translations]);

    return (
      <IntlProvider locale={locale} key={key} messages={messages} textComponent={React.Fragment}>
        <ComposedComponent {...props} />
      </IntlProvider>
    );
  };

  return ReactIntl;
};

export default withIntl;
