const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  code: null,
  description: null,
  priority: null
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      state.code = action.payload.code;
      state.description = action.payload.description;
      state.priority = action.payload.priority;
    }
  }
});

const { reducer, actions } = errorSlice;
export const { setError } = actions;
export default reducer;
