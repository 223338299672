import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { FormattedMessage } from "react-intl";
import InlineError from "../common/InlineError";
import { emailRegExp } from "../../utils/commonUtils";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import StyledChangePasswordForm from "../common/formControlStyles";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { useSelector } from "react-redux";

const ForgotPasswordFrom = ({ submit }) => {
  const { sendForgotPasswordInProgress: forgotPasswordInProgress, sendForgotPasswordFailed: forgotPasswordFailed } =
    useSelector((state) => state.user);

  const [data, setData] = useState({ email: "" });
  const [errors, setErrors] = useState({
    email: "",
    global: ""
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validatedErrors = validate(data);
    setErrors(validatedErrors);
    !validatedErrors.email && submit(data);
  };

  const validate = (data) => {
    const validatedErrors = { ...errors };

    validatedErrors.email = !data.email ? "GLCantBeBlank" : !data.email.match(emailRegExp) ? "GLInvalidEmail" : "";

    return validatedErrors;
  };

  return (
    <StyledChangePasswordForm>
      <Grid container alignItems="center" justifyContent="center" className="login">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2}>
              {forgotPasswordFailed && (
                <Grid item xs={12}>
                  <span className="globalErrorWrapper">
                    <InfoRounded className="globalErrorIcon" />
                    <InlineError className="globalError" error={forgotPasswordFailed} />
                  </span>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={1}>
                        <AccountCircle
                          classes={{
                            root: "icon"
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <FormControl error={!!errors.email} classes={{ root: "formControl" }}>
                          <InputLabel
                            htmlFor="email"
                            classes={{
                              root: "cssLabel",
                              focused: "cssFocused"
                            }}
                          >
                            <FormattedMessage id="GLEmail" />
                          </InputLabel>
                          <Input
                            classes={{
                              root: "fieldRoot",
                              disabled: "fieldDisabled",
                              underline: "fieldUnderline",
                              focused: "fieldFocused",
                              error: "fieldError"
                            }}
                            type="text"
                            id="email"
                            name="email"
                            value={data.email}
                            onChange={onChange}
                          />
                          {errors.email && <InlineError className="labelError" error={errors.email} />}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className="buttonsWrapper">
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      classes={{
                        root: "button",
                        disabled: "buttonDisabled"
                      }}
                      onClick={onSubmit}
                      disabled={forgotPasswordInProgress}
                    >
                      <FormattedMessage id="GLSubmit" />
                      {forgotPasswordInProgress && <CircularProgress size={24} className="buttonProgress" />}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/login" className="link">
                      <Button className="buttonSnd">
                        <FormattedMessage id="GLlogin" />
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </StyledChangePasswordForm>
  );
};

export default ForgotPasswordFrom;
