// @flow
import React from "react";
import { Route } from "react-router-dom";
import { LoadingContext } from "src/contexts";
import LoadingApp from "src/Components/LoadingApp";

const LoaderRoute = ({ component: Component, ...rest }) => {
  return (
    <LoadingContext.Consumer>
      {({ isLoadingApp }) => {
        if (isLoadingApp) {
          return <LoadingApp />;
        }
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      }}
    </LoadingContext.Consumer>
  );
};

export default LoaderRoute;
