import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { resetLogin } from "src/store/components/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LoginForm from "../Login/LoginForm";
import { logoXpg } from "../../utils/commonUtils";
import MessageView from "../common/MessageView";
import StyledFormComponent from "../common/formComponentStyles";
import BasicLayout from "../layout/Basic";
import { Wrapper } from "./styles";
import useWindowHeight from "src/customHooks/useWindowHeight";

const LoginFormComponent = () => {
  const { token, loginWentWrong, forgotPasswordSent, loginStatus, loginResponse } = useSelector((state) => state.user);
  const { ipNotAuthorized, mobileLogoUrl } = useSelector((state) => state.startUp);
  const isAuth = token && !loginWentWrong;
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();
  localStorage.removeItem("loginWithToken")
  const [redirectToActivationFailed, setRedirectToActivationFailed] = useState(false);

  useEffect(() => {
    if (loginStatus === "error") {
      if (loginResponse && loginResponse.errorCode === "33") {
        setRedirectToActivationFailed(true);
      }
    }
  }, [loginStatus]);

  useEffect(() => () => dispatch(resetLogin()), []);

  const src = mobileLogoUrl || logoXpg;

  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          <div className="screen">
            <Grid container justifyContent="center">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={src} className="img" alt="logo" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <MessageView
              ipNotAuthorized={ipNotAuthorized}
              forgotPasswordSent={forgotPasswordSent}
              className="message"
            />
            {!forgotPasswordSent && <LoginForm />}
            {isAuth && <Redirect replace to="/auth" />}
            {redirectToActivationFailed && <Redirect to="/activationFailed" />}
          </div>
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default LoginFormComponent;
