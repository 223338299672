export type UrlParams = {
  operatorId: number,
  token: string,
  userName: string,
  homeUrl: string,
  defaultCategory: string,
  languageId: number,
  brandName: string,
  password: string,
  rememberMe: boolean,
  loginType: number,
  action: string
};

export function parseUrlParams(params: Object): UrlParams {
  let parsedParams = {};
  // convert keys to lower case
  Object.keys(params)
    .map(x => ({ [x.toLowerCase()]: params[x] }))
    .forEach(x => {
      parsedParams = { ...parsedParams, ...x };
    });
  const typedObject: UrlParams = {
    operatorId: (parsedParams.operatorid && parseInt(parsedParams.operatorid, 10)) || "",
    token: parsedParams.token || parsedParams.logintoken || "",
    userName: parsedParams.username || "",
    homeUrl: parsedParams.homeurl || "",
    defaultCategory: parsedParams.defaultcategory || "",
    languageId: parsedParams.languageid || "",
    brandName: parsedParams.brandname || "",
    gameId: +parsedParams.gameid || null,
    gameType: +parsedParams.gametype || null,
    limitId: +parsedParams.limitid || null,
    alert: parsedParams.alert || null,
    isSuperSix: +parsedParams.issupersix || null,
    loginType: parseInt(parsedParams.logintype, 10),
    action: parsedParams.action || ""
  };

  return typedObject;
}
