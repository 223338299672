import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { logoXpg } from "../../utils/commonUtils";
import StyledFormComponent from "../common/formComponentStyles";
import BasicLayout from "../layout/Basic";
import { Wrapper } from "./styles";
import useWindowHeight from "src/customHooks/useWindowHeight";
import ChangePasswordSuccessMessage from "../Header/components/ControlBtn/Settings/ChangePassword/ChangePasswordSuccessMessage";

const ChangePasswordSuccessComponent = ({ isFail }) => {
  const { mobileLogoUrl } = useSelector((state) => state.startUp);
  const windowHeight = useWindowHeight();

  const src = mobileLogoUrl || logoXpg;

  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          <div className="screen">
            <Grid container justifyContent="center">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={src} className="img" alt={"Chips"} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <ChangePasswordSuccessMessage/>
          </div>
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default ChangePasswordSuccessComponent;
