import React from "react";
import Grid from "@material-ui/core/Grid";
import { selectExternalGame } from "src/store/components/gameCategories/gameActions";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getBP } from "src/utils/styleUtils";

const slotStyle = `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #403f4d;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: 0.2s linear;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  padding: 3% 10%;
  & img {
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

const StyledExternalGameGrid = styled(Grid)`
  position: relative;
  padding: 12px;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) and (orientation: landscape) {
    padding: 3px;
  }

  & .icon-star {
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    & path:nth-of-type(2) {
      fill: #ffff1a;
    }
    &:hover path {
      fill: #ffff1a;
    }
  }

  .slotItem {
    ${slotStyle}
    height: 190px;

    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) and (orientation: landscape) {
      height: 100px;
    }
  }
  .slotItemSmall {
    ${slotStyle}
    height: "137px"
  }
`;

const ExternalGame = ({ item }) => {
  const { gridType } = useSelector((state) => state.gameCategories);
  const dispatch = useDispatch();

  const externalGameClick = (item) => {
    dispatch(selectExternalGame(item));
  };

  return (
    <StyledExternalGameGrid
      item
      xs={12}
      sm={3}
      md={4 - gridType}
      lg={2 - gridType}
      key={`${item.gameId}_${item.gameProvider}`}
      className="item"
    >
      <div onClick={() => externalGameClick(item)} className={gridType ? "slotItemSmall" : "slotItem"}>
        <img src={item.imageUrl} alt={item.gameName} />
      </div>
    </StyledExternalGameGrid>
  );
};

export default ExternalGame;
