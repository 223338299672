// @flow

import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { LANGUAGES } from "src/constants";
import { updateLanguage } from "src/store/components/startUp/startUpActions";

const Wrap = styled.div`
  position: absolute;
  right: -51px;
  top: 100%;
  padding-top: 10px;
  z-index: 12;
  display: none;
  width: 260px;
`;
const WrapInner = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(204, 204, 204, 0.3);
  padding: 17px 0px;
  border-radius: 6px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const Item = styled.li``;
const Lang = styled.span`
  cursor: pointer;
  font-size: 14px;
  padding: 4px 20px;
  color: rgba(230, 230, 230, 1);
  display: block;
  &:hover {
    background-color: rgba(255, 255, 26, 0.07);
    color: rgba(255, 255, 26, 1);
  }
  &.active {
    background-color: rgba(255, 255, 26, 0.2);
    color: rgba(230, 230, 230, 1);
  }
`;

const Img = styled.img`
  width: 24px;
  height: 14px;
  margin-right: 10px;
`;
const Label = styled.span``;

const Languages = ({ languages, currentLangId }) => {
  const dispatch = useDispatch();

  const handleChangeLanguage = (lang) => {
    if (lang !== currentLangId) {
      dispatch(updateLanguage(lang));
    }
  };

  return (
    <Wrap>
      <WrapInner>
        <List>
          {languages.map((lang) => (
            <Item key={`${lang}-lang`} id={`${lang}-lang`}>
              <Lang
                className={+lang === currentLangId && "active"}
                onClick={(event) => {
                  event.preventDefault();
                  handleChangeLanguage(lang);
                }}
              >
                <Img src={`/assets/img/icons/languages/${LANGUAGES[lang]}.svg`} alt="" />
                <Label>
                  <FormattedMessage id={lang} defaultMessage="Not Found" />
                </Label>
              </Lang>
            </Item>
          ))}
        </List>
      </WrapInner>
    </Wrap>
  );
};

export default Languages;
