import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Modal from "src/Components/ui/Modal";
import Form from "./Form";
import SuccessMessage from "./SuccessMessage";

const Header = styled.div`
  text-transform: uppercase;
  padding: 25px 0;
  margin: 0 60px;
  text-align: center;
  color: rgba(230, 230, 230, 1);
  border-bottom: 1px solid rgba(128, 128, 128, 1);
  font-size: 20px;
  letter-spacing: 1px;
`;

const ModalChangePassword = ({ isOpen, onModalToggle, save, onSaveSuccess }) => (
  <Modal isOpen={isOpen} onRequestClose={onModalToggle}>
    <Header>
      <FormattedMessage id="GLChangePassword" />
    </Header>
    {save ? (
      <SuccessMessage onClosePopup={onModalToggle} onSaveSuccess={onSaveSuccess} />
    ) : (
      <Form onClosePopup={onModalToggle} onSaveSuccess={onSaveSuccess} />
    )}
  </Modal>
);

export default ModalChangePassword;
