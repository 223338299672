import { useEffect, useState } from "react";

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const callback = () => {
      // using timeout as a workaround on firefox ios, the innerHeight is calculated slower when closing keyboard
      setTimeout(() => {
        setWindowHeight(window.innerHeight);
      }, 50);
    };
    window.addEventListener("resize", callback);
    window.addEventListener("orientationchange", callback);

    return () => {
      window.removeEventListener("resize", callback);
      window.removeEventListener("orientationchange", callback);
    };
  }, []);

  return windowHeight;
};

export default useWindowHeight;
