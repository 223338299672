import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";

const ItemMenu = styled.div``;

const ChangePassword = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [save, setSave] = useState(false);

  const handleModalToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSave(false);
      }, 1000);
    }
  }, [isOpen]);

  return (
    <>
      <ItemMenu className="item-menu" onClick={handleModalToggle}>
        {children}
      </ItemMenu>
      <Modal isOpen={isOpen} save={save} onSaveSuccess={setSave} onModalToggle={handleModalToggle} />
    </>
  );
};

export default ChangePassword;
