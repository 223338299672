import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import ifvisible from "ifvisible";
import { BalanceService, UserService, GamesService, LoginService } from "../../../services";
import AllCodeErrorMapper, { ErrorCodes } from "../../../utils/userUtils";
import {
  updateLoginType,
  updateOperatorId,
  getCategories,
  tokenLoginFailed,
  storeRememberMeFields,
  loginFailed,
  selectGame
} from "../../common/commonActions";
import { getGamePath } from "../../../utils/commonUtils";
import { setError } from "../error/errorSlice";
import { loadingAppProgress } from "../../app/appSlice";
import { getLocStorage } from "../../../utils/helpers";
import {
  loginSuccess,
  loginWentWrong,
  sendChangePassword,
  sendChangePasswordFailed,
  sendForgotPassword,
  sendForgotPasswordFailed,
  setSignUpResponse,
  signUpSent,
  startLogin,
  stopLogin,
  updateBalance
} from "./userSlice";
import { ApiParamsUrl } from "src/services/configs/servicesConfig";

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const { email } = data;
    const { startUp } = getState();

    try {
      const res = await UserService.forgotPassword(startUp.apiClientUrl, {
        operatorId: startUp.operatorId,
        email
      });
      dispatch(sendForgotPassword(true));

      return res;
    } catch (err) {
      if (
        err.errorCode !== ErrorCodes.FORGOTTEN_PASSWORD_TOKEN_EXISTS &&
        err.errorCode !== ErrorCodes.PLAYER_NOT_ACTIVATED &&
        err.errorCode !== ErrorCodes.PLAYER_BANNED
      ) {
        dispatch(sendChangePassword(false));
        const errorKey = AllCodeErrorMapper[err.errorCode];
        dispatch(sendForgotPasswordFailed(errorKey || "requestDenied"));
      }

      return rejectWithValue(err);
    }
  }
);

const updateBalanceAndExtendToken = async (token, operatorId, apiClientUrl, dispatch) => {
  try {
    const res = await BalanceService.getBalanceAndExtendToken(apiClientUrl, {
      registerToken: token,
      operatorId
    });
    dispatch(updateBalance(res));
  } catch (error) {
    dispatch(tokenLoginFailed({ error }));
    console.log(error);
  }
};

let interval;
export const getBalanceAndExtendToken = createAsyncThunk(
  "user/getBalanceAndExtendToken",
  (_, { getState, dispatch }) => {
    const { user, startUp } = getState();

    if (user.token && startUp.operatorId)
      updateBalanceAndExtendToken(user.token, startUp.operatorId, startUp.apiClientUrl, dispatch);

    ifvisible.setIdleDuration(startUp.clientSessionLength);
    interval = ifvisible.onEvery(startUp.extendTokenInterval, () => {
      const { user, startUp } = getState();

      if (!!user.token && startUp.operatorId) {
        updateBalanceAndExtendToken(user.token, startUp.operatorId, startUp.apiClientUrl, dispatch);
      }

      // is called only after 60seconds pass when updateBalance req is called
      ifvisible.on("wakeup", () => {
        interval.stop();

        const { user, startUp } = getState();
        if (!!user.token && startUp.operatorId) {
          updateBalanceAndExtendToken(user.token, startUp.operatorId, startUp.apiClientUrl, dispatch);
        }

        interval = ifvisible.onEvery(startUp.extendTokenInterval, () => {
          const { user, startUp } = getState();
          if (!!user.token && startUp.operatorId) {
            updateBalanceAndExtendToken(user.token, startUp.operatorId, startUp.apiClientUrl, dispatch);
          }
        });
      });
    });
  }
);

export const activate = createAsyncThunk("user/activate", async (activationToken, { dispatch, getState }) => {
  const { startUp } = getState();

  try {
    const res = await UserService.activateAccount(startUp.apiClientUrl, {
      activationToken: activationToken,
      operatorId: startUp.operatorId
    });

    localStorage.setItem("operatorId", startUp.operatorId.toString());
    localStorage.setItem("token", res.token.toString());
    localStorage.setItem("userName", res.userName.toString());
    localStorage.setItem("playerId", res.playerId.toString());
    localStorage.setItem("password", res.token.toString());
    localStorage.setItem("loginType", "0");

    dispatch(updateLoginType(0));
    dispatch(updateOperatorId(startUp.operatorId));
    dispatch(loginSuccess(res));
    dispatch(getCategories());
    dispatch(getBalanceAndExtendToken());

    return res;
  } catch (err) {
    switch (err.errorCode) {
      case "100":
      case "2":
      case "19":
        dispatch(loginWentWrong("GLloginWentWrong"));
        break;
      default:
        return err;
    }
  }
});

export const signUp = createAsyncThunk("user/signUp", async (data, { getState, dispatch, rejectWithValue }) => {
  const { operatorId, deviceType, apiClientUrl } = getState().startUp;

  try {
    const res = await GamesService.signUp(apiClientUrl, { ...data, operatorId });
    if (res.errorCode === 0) {
      dispatch(signUpSent(true));
    }

    return res;
  } catch (err) {
    const errorCode = +err.errorCode;
    const value = AllCodeErrorMapper[errorCode];
    if (errorCode !== 29) {
      dispatch(setSignUpResponse({ key: errorCode, value } || { value: "requestDenied" }));
    }

    if (deviceType === "Mobile") {
      // scroll to top only for server errors
      window.scrollTo(0, 0);
    }

    return rejectWithValue(err);
  }
});

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (data, { dispatch, getState, rejectWithValue }) => {
    const { newPassword } = data;
    const { startUp, user } = getState();

    dispatch(sendChangePassword(true));
    dispatch(sendChangePasswordFailed(""));

    try {
      const res = await UserService.changePassword(startUp.apiClientUrl, {
        operatorId: startUp.operatorId,
        token: user.token,
        newPassword,
        isPasswordHashed: 0
      });
      dispatch(sendChangePassword(false));
      return res;
    } catch (err) {
      dispatch(sendChangePassword(false));
      const errorKey = AllCodeErrorMapper[err.errorCode];
      dispatch(sendChangePasswordFailed(errorKey || "requestDenied"));
      return rejectWithValue(err);
    }
  }
);

export const login = createAsyncThunk("user/login", async (data, { dispatch, getState, rejectWithValue }) => {
  const { userName, password, rememberMe } = data;
  const { startUp } = getState();
  dispatch(startLogin());
  dispatch(storeRememberMeFields({ userName, password, rememberMe }));
  dispatch(setError({ code: null, description: null, priority: null }));

  try {
    const res = await LoginService.login(startUp.apiClientUrl, {
      userName: userName,
      password: password,
      operatorId: startUp.operatorId
    });

    localStorage.setItem("operatorId", startUp.operatorId.toString());
    localStorage.setItem("token", res.token.toString());
    localStorage.setItem("userName", res.userName.toString());
    localStorage.setItem("playerId", res.playerId.toString());
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("password", password);
    localStorage.setItem("loginType", "0");

    dispatch(updateLoginType(0));
    dispatch(updateOperatorId(startUp.operatorId));
    dispatch(loginSuccess(res));
    dispatch(getCategories());
    dispatch(getBalanceAndExtendToken());
    dispatch(loadingAppProgress("finish"));

    return res;
  } catch (err) {
    dispatch(loadingAppProgress("finish"));
    if (err.errorCode !== "33") dispatch(loginFailed(err));
    dispatch(stopLogin());
    dispatch(loadingAppProgress("finish"));
    return rejectWithValue(err);
  }
});

export const loginFromChangePassword = createAsyncThunk(
  "user/loginFromChangePassword",
  async (user, { dispatch, getState }) => {
    const { startUp } = getState();
    dispatch(startLogin());

    try {
      const res = await LoginService.loginWithToken(startUp.apiClientUrl, {
        ...user,
        operatorId: startUp.operatorId
      });

      localStorage.setItem("operatorId", startUp.operatorId.toString());
      localStorage.setItem("token", res.token.toString());
      localStorage.setItem("userName", res.userName.toString());
      localStorage.setItem("playerId", res.playerId.toString());

      dispatch(updateOperatorId(startUp.operatorId));
      dispatch(loginSuccess(res));
      dispatch(getCategories());
      dispatch(getBalanceAndExtendToken());

      return res;
    } catch (error) {
      dispatch(stopLogin());

      if (error.errorCode !== "33") {
        try {
          await UserService.forgotPasswordByUserName(startUp.apiClientUrl, {
            username: user.userName,
            operatorId: startUp.operatorId
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
);

function setOpenGameUrl(data, dispatch) {
  const urlParams = getLocStorage("urlParams");
  let game = {
    gameId: urlParams.gameId,
    gameType: urlParams.gameType,
    limitId: urlParams.limitId,
    directLink: data.directLink
  };

  game = game.gameType === 4 && !!urlParams.isSuperSix ? { ...game, isSuperSix: urlParams.isSuperSix } : game;

  const g = Object.values(game).every((item) => item);
  if (g) {
    if (data.deviceType === "Mobile") {
      const openGameUrl = getGamePath(data, game);
      Cookies.set("loginParams", JSON.stringify(data), { expires: 7 });
      Cookies.set("openGameUrl", openGameUrl, { expires: 7 });
      localStorage.setItem("openGameUrl", openGameUrl);
    } else {
      dispatch(selectGame(game));
    }
  }
}

export const loginWithToken = createAsyncThunk("user/loginWithToken", async (params, { getState, dispatch }) => {
  const { apiClientUrl, homeUrl, gameType } = getState().startUp;
  dispatch(startLogin());

  try {
    const res = await LoginService.loginWithToken(apiClientUrl, params);

    const dataUrl = {
      token: res.token,
      operatorId: params.operatorId,
      brandName: params.brandName || "",
      languageId: getLocStorage("languageId"),
      deviceType: params.deviceType,
      userName: res.userName,
      playerId: res.playerId,
      alert: params.alert,
      homeUrl: homeUrl,
      directLink: gameType > 0
    };
    
    const urlParams = getLocStorage("urlParams");
    if(ApiParamsUrl.includes("services.xpgnet") && urlParams.gameId === 85 && params.deviceType === "Web"){
  }
  else{
     setOpenGameUrl(dataUrl, dispatch);
  }

    localStorage.setItem("operatorId", params.operatorId.toString());
    localStorage.setItem("token", res.token.toString());
    localStorage.setItem("userName", res.userName.toString());
    localStorage.setItem("playerId", res.playerId.toString());

    // Todo rethink this logic regarding brandName
    localStorage.setItem("brandName", params.brandName ? params.brandName : "");
    dispatch(updateOperatorId(params.operatorId));
    dispatch(loginSuccess(res));
    dispatch(getCategories());
    dispatch(getBalanceAndExtendToken());
    dispatch(loadingAppProgress("finish"));
  } catch (error) {
    dispatch(tokenLoginFailed({ error, isNotToken: gameType > 0 }));
    dispatch(loadingAppProgress("finish"));
    dispatch(loginWentWrong(""));
  }
});

export const loginMGL = createAsyncThunk("user/loginMGL", async (params, { dispatch, getState }) => {
  const { apiClientUrl } = getState().startUp;
  dispatch(startLogin());

  try {
    const res = await LoginService.loginMGL(apiClientUrl, params);

    const dataUrl = {
      token: res.token,
      operatorId: params.operatorId
    };

    setOpenGameUrl(dataUrl, dispatch);

    localStorage.setItem("operatorId", params.operatorId.toString());
    localStorage.setItem("token", res.token.toString());

    // Todo rethink this logic regarding brandName
    dispatch(updateOperatorId(params.operatorId));
    dispatch(loginSuccess(res));
    dispatch(getCategories());
    dispatch(getBalanceAndExtendToken());
    dispatch(loadingAppProgress("finish"));
  } catch (error) {
    dispatch(tokenLoginFailed({ error }));
    dispatch(loadingAppProgress("finish"));
    dispatch(loginWentWrong(""));
    console.log("loginWithToken", error);
  }
});
