import { compose } from "@reduxjs/toolkit";

import withIntl from "./withIntl";
import withHistory from "./withHistory";
import withSidebarMenu from "./withSidebarMenu";
import withCookies from "src/hocs/withCookies";

const defaultHOCs = compose(withCookies, withIntl, withHistory);

export const sidebarMenu = compose(withSidebarMenu);

export default defaultHOCs;
