// @flow
import type { ServiceResponse } from "../ResponseTypes";
import type { TranslationsRequest } from "../clientModels/TranslationsRequest";
import type { TranslationsResponse } from "../clientModels/TranslationsResponse";
import type { LCTranslationsRequest } from "../serverModels/LCTranslationsRequest";
import type { LCTranslationsResponse } from "../serverModels/LCTranslationsResponse";

export const mapTranslationsRequest = (
  request: TranslationsRequest
): LCTranslationsRequest => ({
  url: request.url + "/" + request.gameType + "/" + request.languageID
});

const mapTranslationsResponse = (
  data: LCTranslationsResponse
): TranslationsResponse => ({
  translations: data
});

export const resolveResponse = (
  response: ServiceResponse<LCTranslationsResponse>
): TranslationsResponse => {
  return mapTranslationsResponse(response.data);
};
