export const filterGames = (games) => {
  // Function to extract unique game orders from the example games list
  const extractUniqueGameOrders = () => {
    const uniqueGameOrders = [];
    games.forEach((game) => {
      if (!uniqueGameOrders.includes(game.gameOrder)) {
        uniqueGameOrders.push(game.gameOrder);
      }
    });
    return uniqueGameOrders;
  };

  // Extract unique game orders and store them in an array
  const uniqueGameOrders = extractUniqueGameOrders();

  // Initialize an array to store games grouped by their order
  const gamesGroupedByOrder = [];

  // Function to group games by their game order
  const groupGamesByOrder = () => {
    uniqueGameOrders.forEach((gameOrder) => {
      const gamesWithSameOrder = games.filter((game) => game.gameOrder === gameOrder);
      gamesGroupedByOrder.push(gamesWithSameOrder);
    });
  };

  // Group games by their order
  groupGamesByOrder();

  // Initialize an array to store the display array
  const displayGamesArray = [];

  // Function to process each group of games and extract the desired game for display
  const processGamesByOrder = () => {
    for (let i = 0; i < gamesGroupedByOrder.length; i++) {
      const gamesInCurrentOrder = gamesGroupedByOrder[i];
      if (gamesInCurrentOrder.length > 0) {
        const gameToDisplay = gamesInCurrentOrder.reduce((prev, curr) => {
          if (prev.limitSet.minBet < curr.limitSet.minBet) {
            return prev;
          } else if (prev.limitSet.minBet > curr.limitSet.minBet) {
            return curr;
          } else {
            return prev.limitSet.maxBet < curr.limitSet.maxBet ? prev : curr;
          }
        });

        displayGamesArray.push(gameToDisplay);

        const foundIndex = gamesInCurrentOrder.findIndex(
          (game) => game.limitSet.limitSetId === gameToDisplay.limitSet.limitSetId
        );
        if (foundIndex !== -1) {
          gamesInCurrentOrder.splice(foundIndex, 1);
        }

        if (gamesInCurrentOrder.length === 0) {
          gamesGroupedByOrder.splice(i, 1);
          i--; // Adjust index after splicing
        }
      }
    }
  };

  // Process games until all groups have been processed
  while (gamesGroupedByOrder.length !== 0) {
    processGamesByOrder();
  }

  return displayGamesArray;
};
