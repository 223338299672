import React, { useState, useEffect } from "react";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InlineError from "../common/InlineError";
import { FormattedMessage } from "react-intl";
import StyledForm from "../common/formControlStyles";
import { Link } from "react-router-dom";
import { emailRegExp, nameRegExp } from "../../utils/commonUtils";
import InfoRounded from "@material-ui/icons/InfoRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "src/store/components/user/userActions";
import { resetSignUp } from "src/store/components/user/userSlice";

const FormData = ["firstName", "lastName", "email", "userName", "password", "passwordVerification"];

const FieldType = {
  password: "password",
  passwordVerification: "password",
  firstName: "text",
  lastName: "text",
  email: "text",
  userName: "text"
};

const SignUpForm = () => {
  const { signUpStatus, signUpError, signUpResponse } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    password: "",
    passwordVerification: ""
  });
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    userName: null,
    password: null,
    passwordVerification: null
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const validClient = validateClient(data);
    if (validClient) {
      setDisabled(true);
      dispatch(signUp(data));
    }
  };

  useEffect(() => {
    if (signUpStatus === "error") {
      validateServer(signUpError);
      setDisabled(false);
    }
  }, [signUpStatus]);

  useEffect(() => {
    return () => dispatch(resetSignUp());
  }, []);

  const validateClient = (data) => {
    let pass = null;
    let passConfirm = null;
    const validationErrors = {};
    validationErrors.firstName = !data.firstName
      ? "GLCantBeBlank"
      : !data.firstName.match(nameRegExp)
      ? "GLInvalidFirstName"
      : null;
    validationErrors.lastName = !data.lastName
      ? "GLCantBeBlank"
      : !data.lastName.match(nameRegExp)
      ? "GLInvalidLastName"
      : null;
    validationErrors.userName = !data.userName ? "GLCantBeBlank" : null;
    validationErrors.userName =
      data.userName.split(" ").length > 1 ? "GLContainsEmptySpace" : validationErrors.userName;
    validationErrors.email = !data.email ? "GLCantBeBlank" : !data.email.match(emailRegExp) ? "GLInvalidEmail" : null;

    if (!data.password) {
      pass = "GLCantBeBlank";
    } else if (validationErrors.password) {
      pass = validationErrors.password;
    }
    validationErrors.password = pass;

    if (!data.passwordVerification) {
      passConfirm = "GLCantBeBlank";
    } else if (data.passwordVerification !== data.password) {
      passConfirm = "GLPasswordNotMatch";
    } else if (validationErrors.passwordVerification) {
      passConfirm = validationErrors.passwordVerification;
    }
    validationErrors.passwordVerification = passConfirm;

    setErrors(validationErrors);
    return Object.values(validationErrors).every((item) => !item);
  };

  const validateServer = (err) => {
    const serverErrors = {};
    if (+err.errorCode === 29) {
      serverErrors.password = "GLpasswordTooShort";
      serverErrors.passwordVerification = "GLpasswordTooShort";
      setErrors({ ...errors, ...serverErrors });
    }
    return true;
  };

  return (
    <StyledForm>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            {Object.keys(signUpResponse).length > 0 && (
              <Grid item xs={12}>
                <span className="globalErrorWrapper">
                  <InfoRounded className="globalErrorIcon" />
                  <InlineError className="globalError" error={signUpResponse.value} />
                </span>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {FormData.map((item, i) => {
                  const inputValue = item === "password" ? data[item].replace(/ /g, "") : data[item];
                  return (
                    <Grid key={item} item xs={12}>
                      <FormControl error={!!errors[item]} classes={{ root: "formControl" }}>
                        <InputLabel
                          htmlFor={item}
                          classes={{
                            root: "cssLabel",
                            focused: "cssFocused"
                          }}
                        >
                          <FormattedMessage id={"GLSignUp." + item} />
                        </InputLabel>
                        <Input
                          classes={{
                            root: "fieldRoot",
                            disabled: "fieldDisabled",
                            underline: "fieldUnderline",
                            focused: "fieldFocused",
                            error: "fieldError"
                          }}
                          type={FieldType[item]}
                          id={`signup-${item}`}
                          name={item}
                          value={inputValue}
                          onChange={onChange}
                          inputProps={{
                            maxLength: item !== "email" ? 64 : 256
                          }}
                        />
                        {errors[item] && <InlineError className="labelError" error={errors[item]} />}
                      </FormControl>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={12} className="buttonsWrapper">
              <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Button type="submit" className="button" onClick={onSubmit}>
                    <FormattedMessage id="signUp" />
                    {disabled && <CircularProgress size={24} className="buttonProgress" />}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Link to="/login" className="link">
                    <Button className="buttonSnd">
                      <FormattedMessage id="GLlogin" />
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

export default SignUpForm;
