// @flow
import axios from "axios";
import type { TranslationsRequest } from "../clientModels/TranslationsRequest";
import type { TranslationsResponse } from "../clientModels/TranslationsResponse";
import * as TranslationsMapper from "../mappers/TranslationsMapper";
import * as MapResponse from "../utils/MapResponse";

export default class TranslationsProvider {
  request = (endPoint: string, request: *, responseResolver: *) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "GET",
        url: endPoint,
        headers: { "Cache-Control": "no-cache" },
        data: request
      };
      axios(options)
        .then(response => {
          if (response.status === 200) {
            resolve(responseResolver(MapResponse.success(response.data)));
          } else {
            console.log("server response encounter error with status: " + response.status);
            throw MapResponse.exception(response.status, response.statusText);
          }
        })
        .catch(error => {
          // TODO add here logic for error handling/showing requests exeption on UI(visual representation)
          reject(error);
          throw MapResponse.exception(error.name, error.message);
        });
    });

  getTranslations = (request: TranslationsRequest): Promise<TranslationsResponse> => {
    const time = new Date().getTime();
    const url = `${TranslationsMapper.mapTranslationsRequest(request).url}?t=${time}`;
    return this.request(url, {}, TranslationsMapper.resolveResponse);
  };
}
