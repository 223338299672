import React from "react";
import Grid from "@material-ui/core/Grid";
import SignUpForm from "../SignUp/SignUpForm";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import MessageView from "../common/MessageView";
import { logoXpg } from "../../utils/commonUtils";
import StyledFormComponent from "../common/formComponentStyles";
import BasicLayout from "../layout/Basic";
import useWindowHeight from "src/customHooks/useWindowHeight";
import { Wrapper } from "./styles";

const SignUpComponent = () => {
  const { ipNotAuthorized, mobileLogoUrl } = useSelector((state) => state.startUp);
  const { signUpSent } = useSelector((state) => state.user);
  const src = mobileLogoUrl || logoXpg;
  const windowHeight = useWindowHeight();

  return (
    <StyledFormComponent>
      <BasicLayout>
        <Wrapper windowHeight={windowHeight}>
          <div className="screen">
            <Grid container justifyContent="center">
              <Grid item lg={4} md={6} sm={8} xs={12}>
                <Grid container justifyContent="center">
                  <Grid item xs={6}>
                    <img src={src} className="img" alt="logo" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <MessageView ipNotAuthorized={ipNotAuthorized} className="message" />

            <h2>
              <FormattedMessage id="signUp" />
            </h2>

            {signUpSent && <Redirect to="/signUpSuccess" />}

            <SignUpForm />
          </div>
        </Wrapper>
      </BasicLayout>
    </StyledFormComponent>
  );
};

export default SignUpComponent;
