import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./store";

import { unregister } from "./registerServiceWorker";
import MobileDetect from "mobile-detect";
import { closeFullscreen, isFullScreenBrowser } from "./utils/helpers";
import { showLobby, gameClose } from "./store/common/commonActions";
import { getUrlParams } from "./utils/commonUtils";
import { parseUrlParams } from "./utils/startUpUtils";
import { getApiParams } from "./store/components/startUp/startUpActions";
import { changeGridType } from "./store/components/header/actions";

export const useStartUp = () => {
  const root = document.getElementById("root");
  root.style.visibility = "visible";

  const rawUrlParams = getUrlParams("/");

  // console.log(`Url raw params: ${JSON.stringify(rawUrlParams)}`);

  const urlParams = parseUrlParams(rawUrlParams);

  // GET API PARAMS
  store.dispatch(getApiParams(urlParams));

  const md = new MobileDetect(window.navigator.userAgent);
  if (md.is("iPhone") || md.is("iPad")) {
    window.onpageshow = (e) => {
      if (e.persisted || (window.performance && window.performance.navigation.type === 2)) {
        window.location.reload();
      }
    };
  }

  // addEventListener support for IE8
  function bindEvent(element, eventName, eventHandler) {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent(`on${eventName}`, eventHandler);
    }
  }

  // Listen to message from child window
  bindEvent(window, "message", (e) => {
    if (urlParams.action) {
      if (urlParams.action === "lobby") {
        store.dispatch(
          showLobby({
            lobbyMode: 0,
            switchGame: {
              gameId: parseInt(urlParams.gameId, 10),
              limitId: parseInt(urlParams.limitId, 10)
            }
          })
        );
        urlParams.action = '';
      }
      if (urlParams.action === "addTable") {
        store.dispatch(
          showLobby({
            lobbyMode: 1,
            switchGame: {
              gameId: parseInt(urlParams.gameId, 10),
              limitId: parseInt(urlParams.limitId, 10)
            }
          })
        );
        urlParams.action = '';
      }
    }

    if (e.data.from) {
      if (isFullScreenBrowser()) {
        closeFullscreen();
      }
      if (e.data.action === "lobby") {
        store.dispatch(
          showLobby({
            lobbyMode: 0,
            switchGame: {
              gameId: parseInt(e.data.gameId, 10),
              limitId: parseInt(e.data.limitId, 10)
            }
          })
        );
      }

      if (e.data.action === "addTable") {
        store.dispatch(
          showLobby({
            lobbyMode: 1,
            switchGame: {
              gameId: parseInt(e.data.gameId, 10),
              limitId: parseInt(e.data.limitId, 10)
            }
          })
        );
      }

      if (e.data.action === "close") {
        store.dispatch(
          gameClose({
            gameId: parseInt(e.data.gameId, 10),
            limitId: parseInt(e.data.limitId, 10)
          })
        );
      }

      if (e.data.action === "gameLoaded") {
        const {multiView} = store.getState();
        const games = [...multiView.games];

        if(games.length === 4) {
          const iframe = document.getElementById(e.data.gameId + ":" + e.data.limitId);
          iframe.contentWindow.postMessage({ from: "lobby", action: "hideAddTable" }, "*");
        }
      }
    }
  });

  const gridType = parseInt(localStorage.getItem("gridType"), 10);
  if (gridType) {
    store.dispatch(changeGridType(gridType));
  }
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

unregister();
