import React from "react";
import styled from "styled-components";
import Icon from "src/Components/ui/Icon";
import Languages from "./Languages";
import Settings from "./Settings";
import FullScreen from "./FullScreen";
import { LANGUAGES } from "src/constants";
import { useSelector } from "react-redux";

const List = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 38px;
  height: 38px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 2px;
  margin-left: 10px;
  flex-direction: column;
  &:hover {
    background-color: rgba(229, 229, 229, 0.7);
    > span {
      svg {
        path {
          fill: #1a1a1a;
        }
      }
    }
    > div {
      display: block;
      position: absolute;
    }
  }
`;

const Img = styled.img`
  width: 18px;
  height: 11px;
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

const ControlBtn = () => {
  const { languages, language: langId } = useSelector((state) => state.startUp);

  return (
    <List>
      {languages.length > 1 && (
        <Item>
          <Icon name="languages" />
          <Img src={`/assets/img/icons/languages/${LANGUAGES[langId || "2057"]}.svg`} alt="" />
          <Languages languages={languages} currentLangId={langId} />
        </Item>
      )}
      <Item>
        <Icon name="settings" />
        <Settings />
      </Item>
      <Item style={{ display: "none" }}>
        <Icon name="fullScreen" />
        <FullScreen />
      </Item>
    </List>
  );
};

export default ControlBtn;
