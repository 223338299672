import React from "react";
import styled from "styled-components";
import { getBP } from "src/utils/styleUtils";

export const SimpleIcon = styled.div`
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  width: 100%;
  height: 100%;
`;
export const ColoredIcon = styled.div`
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
`;
export const Icon = styled.div`
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
  margin-bottom: 6px !important;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    width: 25px;
    height: 25px;
  }

  &.selected {
    transform: scale(1.25);
    & ${SimpleIcon} {
      opacity: 0;
    }
    & ${ColoredIcon} {
      opacity: 1;
    }
  }
`;

const CategoryIcon = ({ selectedCategory, lowerName, lobbyType }) => {
  const path = `/assets/img/${lobbyType === "External" ? "external" : "lobby"}/${lowerName.replace(
    /\s+/g,
    ""
  )}`;
  return (
    <Icon className={`${selectedCategory !== lowerName ? "" : "selected"}`}>
      <SimpleIcon style={{ backgroundImage: `url('${path}.svg')` }} />
      <ColoredIcon style={{ backgroundImage: `url('${path}_selected.svg')` }} />
    </Icon>
  );
};
export default CategoryIcon;
