import React from "react";
import styled from "styled-components";

const Colors = {
  red: "#FF3333",
  white: "white",
  green: "#00CF0A"
};

const ResultContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 10%;
  margin-bottom: 5px;
`;
const ResultContainer = styled.svg`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
`;
const ResultContainerStroke = styled.path`
  fill: #87828f;
`;
const ResultContainerBackground = styled.path`
  fill: rgba(0, 0, 0, 0.6);
`;

const redNumbersArray = [
  "1", "3", "5", "7", "9", "12", "14", "16", "18", "19", "21", "23", "25", "27", "30", "32", "34", "36"
];

const blackNumbersArray = [
  "2", "4", "6", "8", "10", "11", "13", "15", "17", "20", "22", "24", "26", "28", "29", "31", "33", "35"
];

const getItemColor = (item) => {
  if (item === "0") return Colors.green;
  if (redNumbersArray.includes(item)) return Colors.red;
  if (blackNumbersArray.includes(item)) return Colors.white;
  return "";
};

const RouletteLastResultsDesktop = ({ collection }) => {
  return (
    <ResultContainerWrapper>
      <ResultContainer xmlns="http://www.w3.org/2000/svg" viewBox="0 0 424 42.25">
        <g id="Layer_2" data-name="Layer 2">
          <ResultContainerBackground d="M402.88,0H21.12a21.13,21.13,0,0,0,0,42.25H402.88a21.13,21.13,0,0,0,0-42.25Z" />
          <ResultContainerStroke d="M402.88,0H21.12a21.13,21.13,0,0,0,0,42.25H402.88a21.13,21.13,0,0,0,0-42.25ZM21.12,41.25A20.13,20.13,0,0,1,21.12,1H402.88a20.13,20.13,0,0,1,0,40.25Z" />

          {collection.slice(0,12).map((item, index) => {
            const color = getItemColor(item);
            return (
              <g key={"g" + index}>
                <circle
                  key={"circle" + index}
                  cx={index * (27.58 + 7) + 21.5}
                  cy="21.12"
                  r="13.79"
                  stroke={color}
                  fill="none"
                />
                <text
                  key={"text" + index}
                  x={index * (27.58 + 7) + 21.5}
                  y="21.12"
                  fill={color}
                  textAnchor="middle"
                  dy=".35em"
                >
                  {item}
                </text>
              </g>
            );
          })}
        </g>
      </ResultContainer>
    </ResultContainerWrapper>
  );
};

export default RouletteLastResultsDesktop;
