import LoggingProvider from "./api/providers/LoggingProvider";
import LoginProvider from "./api/providers/LoginProvider";
import LogoutProvider from "./api/providers/LogoutProvider";
import GamesProvider from "./api/providers/GamesProvider";
import BalanceProvider from "./api/providers/BalanceProvider";
import TranslationsProvider from "./api/providers/TranslationsProvider";
import ApiParamsProvider from "./api/providers/ApiParamsProvider";
import GAServiceProvider from "./api/providers/GAServiceProvider";
import UserProvider from "./api/providers/UserProvider";

export const LoginService = new LoginProvider();
export const LogoutService = new LogoutProvider();
export const LogService = new LoggingProvider();
export const BalanceService = new BalanceProvider();
export const TranslationsService = new TranslationsProvider();
export const ApiParamsService = new ApiParamsProvider();
export const GamesService = new GamesProvider();
export const GAService = new GAServiceProvider();
export const UserService = new UserProvider();
