import React from "react";
import { useDeviceState } from "src/utils/helpers";
import RouletteLastResultsMobile from "./RouletteLastResultsMobile";
import RouletteLastResultsDesktop from "./RouletteLastResultsDesktop";

const RouletteLastResults = ({ collection }) => {
  const { isMobile, isLandscape } = useDeviceState();

  return isMobile && !isLandscape ? (
    <RouletteLastResultsMobile collection={collection} />
  ) : (
    <RouletteLastResultsDesktop collection={collection} />
  );
};

export default RouletteLastResults;
