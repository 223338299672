import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Icon from "src/Components/ui/Icon";
import { CSSTransition } from "react-transition-group";
import { FormattedMessage } from "react-intl";
import GamesHistory from "./GamesHistory";
import Languages from "./Languages";
import Filters from "./Filters";
import Overlay from "../Overlay";
import { setActiveTabMenu, togglePanelMenu } from "src/store/components/mobile/menu/menuSlice";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 18;
  transform: translateX(100%);
  transition: transform 500ms ease;

  &.tabs-menu-enter-active,
  &.tabs-menu-enter-done {
    transform: translateX(0);
  }
`;

const tabsComponent = [
  { com: <GamesHistory />, key: "history" },
  { com: <Languages />, key: "languages" },
  { com: <Filters />, key: "filter" }
];

const Tabs = ({ activeTab }) => {
  const { isOpenPanel } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const handleOnExited = () => {
    dispatch(setActiveTabMenu(null));
  };

  return (
    <CSSTransition in={isOpenPanel} timeout={500} classNames="tabs-menu" unmountOnExit onExited={handleOnExited}>
      <Wrapper
        onClick={(e) => {
          const { id } = e.target;
          if (id === "overlay-tabs-menu") {
            dispatch(togglePanelMenu(false));
          }
        }}
        id="overlay-tabs-menu"
      >
        {tabsComponent.map(
          (tab) =>
            tab.key === activeTab && (
              <Overlay
                key={`tab-${tab.key}`}
                icon={<Icon name={tab.key} width="17" height="16" />}
                title={<FormattedMessage id={`GLMenu.${tab.key}`} />}
              >
                {tab.com}
              </Overlay>
            )
        )}
      </Wrapper>
    </CSSTransition>
  );
};

export default Tabs;
