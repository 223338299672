import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  textalign: center;
  zindex: 100;
  &.loader {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    marginbottom: 15px;
    animation: spin infinite 5s linear;
  }
  &.message {
    color: #ffff1a;
  }
`;

const LoadingApp = () => (
  <Wrapper>
    <div className="loader" id="loader-app">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmZmMWE7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5Bc3NldCAyPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIwLDQwQTIwLDIwLDAsMSwxLDQwLDIwLDIwLDIwLDAsMCwxLDIwLDQwWk0yMCwxLjA2QTE4Ljk0LDE4Ljk0LDAsMSwwLDM4Ljk0LDIwLDE5LDE5LDAsMCwwLDIwLDEuMDZaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjAsMzQuNjNBMTQuNjMsMTQuNjMsMCwxLDEsMzQuNjMsMjAsMTQuNjUsMTQuNjUsMCwwLDEsMjAsMzQuNjNaTTIwLDYuNDRBMTMuNTYsMTMuNTYsMCwxLDAsMzMuNTYsMjAsMTMuNTcsMTMuNTcsMCwwLDAsMjAsNi40NFoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMCwzMi4zNkExMi4zNiwxMi4zNiwwLDEsMSwzMi4zNiwyMCwxMi4zNywxMi4zNywwLDAsMSwyMCwzMi4zNlpNMjAsOC43QTExLjMsMTEuMywwLDEsMCwzMS4zLDIwLDExLjMxLDExLjMxLDAsMCwwLDIwLDguN1oiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMCw1LjlhMTQsMTQsMCwwLDEsNiwxLjM0bDIuMjctNC44OGExOS40OSwxOS40OSwwLDAsMC0xNi40NiwwbDIuMjgsNC44OUExNCwxNCwwLDAsMSwyMCw1LjlaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMzcuNjUsMTEuNzdsLTQuODksMi4yOGExMy45LDEzLjksMCwwLDEsMCwxMS45MWw0Ljg4LDIuMjdBMTkuNDksMTkuNDksMCwwLDAsMzcuNjUsMTEuNzdaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjAsMzQuMWExNCwxNCwwLDAsMS02LTEuMzRsLTIuMjcsNC44OGExOS40OSwxOS40OSwwLDAsMCwxNi40NiwwTDI2LDMyLjc2QTE0LDE0LDAsMCwxLDIwLDM0LjFaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNNS45LDIwYTE0LDE0LDAsMCwxLDEuMzQtNkwyLjM2LDExLjc3YTE5LjQ5LDE5LjQ5LDAsMCwwLDAsMTYuNDZMNy4yNCwyNkExNCwxNCwwLDAsMSw1LjksMjBaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMjUuNzgsMTguNTJoMGwwLDAsMCwwLDAsMEwyMCwxMi42NWwtNS42OCw1Ljc2LDAsMCwwLDAsMCwwaDBhMy4zNywzLjM3LDAsMSwwLDUuMSw0LjR2LjQ2YzAsMy4yNS0xLjg5LDMuMjUtMS44OSwzLjI1di43Mmg1LjE0di0uNzJzLTEuODcsMC0xLjg5LTMuMjF2LS40OWEzLjM4LDMuMzgsMCwxLDAsNS4xLTQuNDFaIi8+PC9nPjwvZz48L3N2Zz4="
        alt="loading"
      />
    </div>
    <div className="message">
      <FormattedMessage id="GLloading" />
    </div>
  </Wrapper>
);

export default LoadingApp;
