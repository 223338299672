import { createSlice } from "@reduxjs/toolkit";
// import { changeGridType } from "../header/actions";
// import { selectExternalGame, selectCategory, getGameCategories, setSlotsFilter } from "./gameActions";

const initialState = {
  categories: [],
  selectedCategory: 0,
  games: [],
  externalGames: { games: [], providers: [] },
  externalGame: { url: "", error: "" },
  loading: false,
  gridType: 0,
  lobbyType: "",
  slotLinesFilter: []
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    closeExternalGame(state) {
      state.externalGame = { url: "", error: "" };
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setGames: (state, action) => {
      state.games = action.payload;
      state.externalGames = initialState.externalGames;
    },
    setExternalGames: (state, action) => {
      state.games = [];
      state.externalGames = { ...state.externalGames, ...action.payload };
    },
    toggleLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  // doesnt work normally, throws error, "fulfilled" is undefined
  extraReducers: {
    "game/selectExternalGame/fulfilled": (state, action) => {
      state.externalGame = { url: action.payload, error: "" };
    },
    "game/selectExternalGame/rejected": (state, action) => {
      state.externalGame = { url: "", error: action.payload };
    },
    "game/selectCategory/fulfilled": (state, action) => {
      state.selectedCategory = action.payload;
    },
    "game/getGameCategories/fulfilled": (state, action) => {
      state.lobbyType = action.payload.lobbyType;
      state.categories = action.payload.categories;
    },
    "game/setSlotsFilter/fulfilled": (state, action) => {
      state.slotLinesFilter = action.payload;
    },
    "header/changeGridType/fulfilled": (state, action) => {
      state.gridType = action.payload;
    }
  }
});

const { reducer, actions } = gameSlice;
export const { closeExternalGame, setSelectedCategory, setGames, setExternalGames, toggleLoading } = actions;
export default reducer;
