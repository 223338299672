import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLocStorage } from "../../utils/helpers";

const StartComponent = () => {
  const { token, loginWentWrong } = useSelector((state) => state.user);
  const { operatorId } = useSelector((state) => state.startUp);
  const isAuth = token && !loginWentWrong;
  const isBlocked = JSON.parse(process.env.REACT_APP_BLOCKED_OPERATORID).some((id) => id === operatorId);
  const errorCode = getLocStorage("loginWithToken") && getLocStorage("loginWithToken").errorCode;
  if(isBlocked) return (<Redirect replace to="/error" />)
  return (
    !errorCode
      ? (isAuth ? <Redirect replace to="/auth" /> : <Redirect replace to="/login" />)
      : <Redirect replace to="/error" />
  )
};

export default StartComponent;
