import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AuthContext } from "../../contexts";

const AuthProvider = ({ children }) => {
  const { token: isLoggedIn, loginWentWrong } = useSelector((state) => state.user);
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem("token"));

  useEffect(() => {
    const loggedUser = isLoggedIn && !loginWentWrong;
    setIsAuth(loggedUser);
  }, [isLoggedIn, loginWentWrong]);

  return (
    <AuthContext.Provider
      value={{
        isAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
