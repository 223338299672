// @flow
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ErrorContext, LoadingContext } from "src/contexts";
import LoadingApp from "src/Components/LoadingApp";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <LoadingContext.Consumer>
      {({ isLoadingApp }) => (
        <ErrorContext.Consumer>
          {({ error }) => {
            if (isLoadingApp) {
              return <LoadingApp />;
            } else if (error.priority === "high") {
              return <Redirect replace to="/error" />;
            }
            return <Route {...rest} render={(props) => <Component {...props} />} />;
          }}
        </ErrorContext.Consumer>
      )}
    </LoadingContext.Consumer>
  );
};

export default PublicRoute;
