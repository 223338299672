import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Icon from "src/Components/ui/Icon";
import { GAMES_RULES_LINK } from "src/constants";
import Logout from "./Logout";
import ChangePassword from "./ChangePassword";

const Wrap = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  padding-top: 10px;
  z-index: 12;
  display: none;
  width: 260px;
`;
const WrapInner = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(204, 204, 204, 0.3);
  padding: 19px 0px;
  border-radius: 6px;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
`;

const Item = styled.li`
  .item-menu {
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
    vertical-align: middle;
    color: rgba(230, 230, 230, 1);
    text-decoration: none;
    padding: 8px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .icon {
      margin-right: 10px;
      &.icon-home {
        margin: 0 6px 0 -3px;
      }
    }
    svg {
      path {
        transition: fill 0.5s ease;
      }
    }
  }

  &:hover {
    background-color: rgba(255, 255, 26, 0.07);
    .item-menu {
      color: rgba(255, 255, 26, 1);
      svg {
        path {
          fill: rgba(255, 255, 26, 1);
        }
      }
    }
  }
`;

const Settings = () => {
  const { lobbyType } = useSelector((state) => state.gameCategories);
  const { loginType, canChangePass } = useSelector((state) => state.startUp);

  const homeUrl = localStorage.getItem("homeUrl") || "";
  const isLobbyTypeExternal = lobbyType === "External";

  return (
    <Wrap>
      <WrapInner>
        {isLobbyTypeExternal ? (
          <List>
            <Item>
              <a className="item-menu" href={GAMES_RULES_LINK} target="_blank" rel="noopener noreferrer">
                <Icon name="gamerules" width="12" height="16" />
                <FormattedMessage id="GLGameRules" />
              </a>
            </Item>
          </List>
        ) : (
          <List>
            {canChangePass && (
              <Item>
                <ChangePassword>
                  <Icon name="lock" width="12" height="16" />
                  <FormattedMessage id="GLChangePassword" />
                </ChangePassword>
              </Item>
            )}
            <Item>
              <a className="item-menu" href={GAMES_RULES_LINK} target="_blank" rel="noopener noreferrer">
                <Icon name="gamerules" width="12" height="16" />
                <FormattedMessage id="GLGameRules" />
              </a>
            </Item>
            {homeUrl && (
              <Item>
                <a className="item-menu" href={homeUrl}>
                  <Icon name="home" width="19" height="16" />
                  <FormattedMessage id="GLHome" />
                </a>
              </Item>
            )}
            {loginType === 0 && (
              <Item>
                <Logout>
                  <Icon name="logout" width="12" height="16" />
                  <FormattedMessage id="GLLogout" />
                </Logout>
              </Item>
            )}
          </List>
        )}
      </WrapInner>
    </Wrap>
  );
};

export default Settings;
