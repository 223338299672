const { createSlice } = require("@reduxjs/toolkit");

const initialState = {};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loadingAppProgress: (state, action) => {
      state.loadingAppStep = action.payload;
    }
  }
});

const { reducer, actions } = appSlice;
export const { loadingAppProgress } = actions;
export default reducer;
