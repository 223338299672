// @flow
import type { ServiceResponse } from "../ResponseTypes";
import type { GamesCategoryInformationRequest } from "../clientModels/GamesCategoryInformationRequest";
import type { LCGamesCategoryInformationRequest } from "../serverModels/LCGamesCategoryInformationRequest";
import type { LCGamesCategoryInformationResponse } from "../serverModels/LCGamesCategoryInformationResponse";
import type { GamesCategoryInformationResponse } from "../clientModels/GamesCategoryInformationResponse";
import { LobbyClient } from "../../configs/servicesConfig";

const lobbyConfigs = LobbyClient;

export const mapCategoryInformationRequest = (
  request: GamesCategoryInformationRequest
): LCGamesCategoryInformationRequest => ({
  operatorID: request.operatorId,
  accessPassword: request.registerToken,
  clientId: lobbyConfigs.Id
});

export const mapCategoryInformationResponse = (
  deviceType: string,
  data: LCGamesCategoryInformationResponse
): GamesCategoryInformationResponse => {
  let categories = {};
  if (data.CategoryInfo) {
    let masterCategory = null;

    const type = lobbyConfigs.masterCategory[deviceType];
    if (Array.isArray(data.CategoryInfo.MasterCategory)) {
      masterCategory = data.CategoryInfo.MasterCategory.find((item) => {
        return item.Name === type;
      });
    } else {
      if (data.CategoryInfo.MasterCategory.Name === type) {
        masterCategory = data.CategoryInfo.MasterCategory;
      }
    }

    if (masterCategory && masterCategory.SubCategories) {
      categories = Array.isArray(masterCategory.SubCategories.Category)
        ? masterCategory.SubCategories.Category
        : [masterCategory.SubCategories.Category];
    }
  }

  return {
    categoryInfo: categories,
    clientId: lobbyConfigs.Id
  };
};

export const resolveResponse = (
  deviceType: string,
  response: ServiceResponse<LCGamesCategoryInformationResponse>
): GamesCategoryInformationResponse => {
  return mapCategoryInformationResponse(deviceType, response.data);
};
