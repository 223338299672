import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLOT_FILTER_ITEMS } from "../../../constants";
import { GamesService } from "../../../services";
import { tokenLoginFailed } from "../../common/commonActions";
import { getLocStorage, setLocStorage } from "src/utils/helpers";
import { getGamesByCategoryId, updateCategoryInLocalStorage } from "../../../utils/gameCategoriesUtils";
import { setGames, setExternalGames, toggleLoading } from "./gameSlice";

export const selectExternalGame = createAsyncThunk(
  "game/selectExternalGame",
  async (game, { rejectWithValue, getState }) => {
    const { user, startUp } = getState();
    try {
      const resp = await GamesService.getExternalGameUrl(startUp.apiClientUrl, {
        operatorId: startUp.operatorId,
        userName: user.userName,
        token: user.token,
        gameId: game.gameId,
        language: startUp.language,
        gameProvider: game.gameProvider
      });
      const url = decodeURIComponent(resp.url);
      return url;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const selectCategory = createAsyncThunk("game/selectCategory", async (categoryName, { dispatch, getState }) => {
  const { gameCategories, user, startUp } = getState();
  const category = gameCategories.categories.find((item) => item.Name.toLowerCase() === categoryName);

  const categoryId = parseInt(category.Id, 10);

  const gamesByCategoryId = getGamesByCategoryId(categoryId);
  if (gamesByCategoryId.length > 0) {
    if (categoryName.toLowerCase() !== "slots") {
      dispatch(setGames(gamesByCategoryId));
    } else {
      dispatch(setExternalGames(gamesByCategoryId));
    }
  } else {
    dispatch(toggleLoading(true));
    try {
      const resp = await GamesService.getCategoryGameData(startUp.apiClientUrl, {
        operatorId: startUp.operatorId,
        userName: user.userName,
        registerToken: user.token,
        categoryId,
        clientGames: []
      });

      updateCategoryInLocalStorage(categoryId, resp.liveGames || []);
      dispatch(setGames(resp.liveGames || []));
      dispatch(toggleLoading(false));

      if (resp.externalGames) {
        updateCategoryInLocalStorage(categoryId, resp.externalGames);
        dispatch(setExternalGames(resp.externalGames));
        dispatch(toggleLoading(false));
      }
    } catch (error) {
      dispatch(toggleLoading(false));
      dispatch(tokenLoginFailed({ error }));
    }
  }
  return categoryName;
});

export const getGameCategories = createAsyncThunk("game/getGameCategories", async (_, { getState, dispatch }) => {
  const { user, startUp } = getState();
  let lines = SLOT_FILTER_ITEMS.map((item) => item.value);

  try {
    const resp = await GamesService.getCategoryInformation(startUp.apiClientUrl, {
      operatorId: startUp.operatorId,
      registerToken: user.token,
      deviceType: startUp.deviceType
    });

    const checkIfArrContainsArr = (arr1, arr2) =>
      arr1.every((r) => {
        let result = false;
        arr2.forEach((obj) => {
          if (Object.keys(obj)[0] === Object.keys(r)[0]) {
            result = true;
            return;
          }
        });
        return result;
      });

      
      if (resp.categoryInfo && resp.categoryInfo.length) {
      const mappedCategoriesFilters = resp.categoryInfo.map((item) => ({ [item.Name.toLowerCase()]: lines }));
      const lsFilters = getLocStorage("slotLinesFilter");
      let slotsFilterData = [];
      if (
        resp.clientId === 5 &&
        lsFilters &&
        lsFilters.slots &&
        checkIfArrContainsArr(lsFilters.slots, mappedCategoriesFilters) &&
        checkIfArrContainsArr(mappedCategoriesFilters, lsFilters.slots)
      ) {
        slotsFilterData = lsFilters["slots"];
      } else {
        slotsFilterData = mappedCategoriesFilters;
      }

      dispatch(setSlotsFilter(slotsFilterData));
    }

    return {
      lobbyType: resp.clientId === 5 ? "External" : resp.clientId === 6 ? "Main" : "",
      categories: resp.categoryInfo || []
    };
  } catch (error) {
    console.log(error);
  }
});

export const setSlotsFilter = createAsyncThunk("game/setSlotsFilter", (filter) => {
  setLocStorage("slotLinesFilter", { ...getLocStorage("slotLinesFilter"), slots: [...filter] });
  return filter;
});
