import { forgotPassword, signUp, changePassword, activate, loginFromChangePassword, login } from "./userActions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  token: "",
  userName: "",
  playerId: 0,
  balance: 0,
  currencySymbol: "",
  loginWentWrong: "",
  loginInProgress: false,
  signUpSent: false,
  signUpResponse: {},
  signUpInProgress: false,
  forgotPasswordSent: false,
  sendForgotPasswordInProgress: false,
  sendForgotPasswordFailed: "",
  changePasswordInProgress: false,
  changePasswordFailed: "",
  changePasswordStatus: null,
  changePasswordError: null,
  activationStatus: null,
  activationResponse: null,
  loginFromChangePasswordStatus: null,
  loginFromChangePasswordResponse: null,
  forgotPasswordStatus: null,
  forgotPasswordResponse: null,
  loginStatus: null,
  loginResponse: null,
  signUpStatus: null,
  signUpError: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload.token;
      state.userName = action.payload.userName;
      state.playerId = action.payload.playerId;
      state.loginWentWrong = "";
      state.loginInProgress = false;
    },
    startLogin: (state) => {
      state.loginInProgress = true;
    },
    loginWentWrong: (state, action) => {
      state.loginWentWrong = action.payload;
      state.token = "";
      state.loginInProgress = false;
    },
    updateBalance: (state, action) => {
      state.balance = action.payload.balance;
      state.currencySymbol = action.payload.currencySymbol;
    },
    stopLogin: (state) => {
      state.loginInProgress = false;
    },
    signUpSent: (state, action) => {
      state.signUpInProgress = false;
      state.signUpSent = action.payload;
    },
    setSignUpResponse: (state, action) => {
      state.signUpResponse = action.payload;
    },
    sendForgotPassword: (state, action) => {
      state.forgotPasswordSent = action.payload;
    },
    sendForgotPasswordFailed: (state, action) => {
      state.sendForgotPasswordFailed = action.payload;
      state.sendForgotPasswordInProgress = false;
    },
    sendChangePassword: (state, action) => {
      state.changePasswordInProgress = action.payload;
    },
    sendChangePasswordFailed: (state, action) => {
      state.changePasswordFailed = action.payload;
    },
    resetForgotPassword: (state) => {
      state.forgotPasswordStatus = null;
      state.forgotPasswordResponse = null;
    },
    resetSignUp: (state) => {
      state.signUpStatus = null;
      state.signUpError = null;
    },
    resetChangePassword: (state) => {
      state.changePasswordStatus = null;
      state.changePasswordError = null;
    },
    resetActivation: (state) => {
      state.activationStatus = null;
      state.activationResponse = null;
    },
    resetLogin: (state) => {
      state.loginStatus = null;
      state.loginResponse = null;
    }
  },
  extraReducers: {
    [signUp.pending]: (state) => {
      state.signUpInProgress = true;
      state.signUpStatus = null;
      state.signUpError = null;
    },
    [signUp.rejected]: (state, action) => {
      state.signUpInProgress = false;
      state.signUpStatus = "error";
      state.signUpError = action.payload;
    },

    [forgotPassword.pending]: (state) => {
      state.sendForgotPasswordInProgress = true;
      state.forgotPasswordStatus = null;
      state.forgotPasswordResponse = null;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.sendForgotPasswordInProgress = false;
      state.forgotPasswordStatus = "success";
      state.forgotPasswordResponse = action.payload;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.sendForgotPasswordInProgress = false;
      state.forgotPasswordStatus = "error";
      state.forgotPasswordResponse = action.payload;
    },

    [changePassword.pending]: (state) => {
      state.changePasswordStatus = null;
    },
    [changePassword.fulfilled]: (state) => {
      state.changePasswordStatus = "success";
    },
    [changePassword.rejected]: (state, action) => {
      state.changePasswordStatus = "error";
      state.changePasswordError = action.payload;
    },

    [activate.pending]: (state) => {
      state.activationStatus = null;
    },
    [activate.fulfilled]: (state, action) => {
      state.activationStatus = "success";
      state.activationResponse = action.payload;
    },

    [loginFromChangePassword.pending]: (state) => {
      state.loginFromChangePasswordStatus = null;
    },
    [loginFromChangePassword.fulfilled]: (state, action) => {
      state.loginFromChangePasswordStatus = "success";
      state.loginFromChangePasswordResponse = action.payload;
    },

    [login.pending]: (state) => {
      state.loginStatus = null;
      state.loginResponse = null;
    },
    [login.fulfilled]: (state, action) => {
      state.loginResponse = action.payload;
      state.loginStatus = "success";
    },
    [login.rejected]: (state, action) => {
      state.loginResponse = action.payload;
      state.loginStatus = "error";
    }
  }
});

const { actions } = userSlice;
export const {
  loginSuccess,
  loginWentWrong,
  resetActivation,
  resetChangePassword,
  resetForgotPassword,
  resetLogin,
  resetSignUp,
  sendChangePassword,
  sendChangePasswordFailed,
  sendForgotPassword,
  sendForgotPasswordFailed,
  setSignUpResponse,
  signUpSent,
  startLogin,
  stopLogin,
  updateBalance
} = actions;
export default userSlice.reducer;
