// @flow
export const gameTypesHDRatio = {
  "1": 16 / 9,
  "2": 16 / 9,
  "4": 16 / 9,
  "8": 4 / 3,
  "12": 16 / 9,
  "14": 4 / 3,
  "16": 16 / 9,
  "22": 4 / 3,
  "24": 16 / 9,
  "26": 16 / 9,
  "28": 16 / 9,
  "30": 16 / 9,
  "32": 16 / 9
};

export default function aspectRatio(openedItems: number, games: any) {
  // ration
  let ratio = 4 / 3;

  if (openedItems > 0) {
    const gameType = games.find(item => gameTypesHDRatio[item.gameType] === 16 / 9);
    ratio = gameType ? 16 / 9 : 4 / 3;
  }

  // get window width and height
  const windowHeight = openedItems > 2 ? window.innerHeight / 2 : window.innerHeight;
  const windowWidth = openedItems > 1 ? window.innerWidth / 2 : window.innerWidth;

  // Set defaults for wrapper for height and width
  let wrapperHeight = windowHeight;
  let wrapperWidth = windowWidth;

  if (windowWidth > windowHeight) {
    wrapperHeight = windowWidth / ratio;

    // Check if wrapper width is to big
    // Adjust the height and width

    if (wrapperHeight > windowHeight) {
      wrapperHeight = windowHeight;
      wrapperWidth = windowHeight * ratio;
    }
  }

  if (windowWidth < windowHeight) {
    wrapperHeight = windowWidth;

    // Check if wrapper width is to big
    // Adjust the height and width

    if (wrapperHeight < windowHeight) {
      wrapperWidth = windowWidth;
      wrapperHeight = windowWidth / ratio;
    }
  }

  return { height: wrapperHeight, width: wrapperWidth };
}
