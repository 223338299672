import React from "react";
import styled from "styled-components";
import { hideLobby } from "src/store/common/commonActions";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const ArrowWrapper = styled.div`
  height: 18px;
  width: 12px;
  float: left;
`;
const Path = styled.path`
  fill: #fff;
`;
const Button = styled.button`
  height: 48px;
  width: 218px;
  background-color: rgba(13, 13, 13, 0.7);
  border: 1px solid silver;
  border-radius: 7px;
  font-size: 20px;
  color: #e6e6e6;
  font-family: Roboto, Arial;
  text-transform: uppercase;
  position: fixed;
  right: 30px;
  bottom: 30px;
  padding-left: 15px;
  cursor: pointer;
  z-index: 11;
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    & ${Path} {
      fill: black;
    }
  }
`;

const GoToLobbyComponent = () => {
  const { hideLobby: showLobby, games } = useSelector((state) => state.multiView);
  const dispatch = useDispatch();

  return (
    showLobby &&
    games.length > 0 && (
      <Button onClick={() => dispatch(hideLobby())}>
        <ArrowWrapper>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 18.95 21.972">
            <Path
              d="M2598.634,1275.625a.382.382,0,0,0,.652-.27v-4.421h.764c5.488,0,9.936,5.586,9.936,11.065v-7.631c0-5.9-4.791-9.539-10.7-9.539v-4.421a.382.382,0,0,0-.652-.27l-7.485,7.473a.382.382,0,0,0,0,.54Zm0,0"
              transform="translate(-2591.037 -1260.027)"
            />
          </svg>
        </ArrowWrapper>
        <FormattedMessage id="GLgoToTable" values={{ breakLine: <br /> }} />
      </Button>
    )
  );
};

export default GoToLobbyComponent;
