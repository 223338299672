// @flow
import type { LoggingRequest } from "../clientModels/LoggingRequest";
import type { LCLoggingRequest } from "../serverModels/LCLoggingRequest";

export const mapLoggerRequest = (request: LoggingRequest): LCLoggingRequest => {
  return {
    ...getEnvData,
    ...getPlayerData(),
    message: request.message,
    type: request.type
  };
};

const getEnvData = {
  domain: window.location.host,
  userAgent: window.navigator.userAgent,
  appVersion: window.navigator.appVersion,
  platform: window.navigator.platform
};

const getPlayerData = () => {
  const storage = window.localStorage;
  return (
    storage && {
      operatorId: storage.getItem(storage.operatorId),
      playerId: storage.getItem(storage.playerId)
    }
  );
};
