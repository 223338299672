import LobbyClient from "./LobbyClient";

// TODO - make this configurable per environment
const ApiParamsUrl = "https://services.xpgtesting.com/ApiParams/Game";
const ApiParamsUrls = ["https://services.xpgtesting.com/ApiParams/Game"];
const isLocalEnv = false;
const baseUrl = "/";
const gaUI = "UA-34513679-11";
const clientId = LobbyClient.Id;
const livegames = "https://livegames.xpgtesting.com/"
const retryMechanismDelayMs = 1500;

export { LobbyClient, ApiParamsUrl, isLocalEnv, baseUrl, gaUI, clientId, livegames, ApiParamsUrls, retryMechanismDelayMs };
