import React, { useEffect } from "react";
import { GAService } from "src/services";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const HistoryProvider = ({ children }) => {
  useEffect(() => {
    GAService.sendPageView();
    history.listen((location) => {
      GAService.sendPageView(location.hash);
    });
  });

  return <>{children}</>;
};

export default HistoryProvider;
