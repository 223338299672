const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isOpen: false,
  isOpenPanel: false,
  activeTab: null,
  tabs: []
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setActiveTabMenu(state, action) {
      state.activeTab = action.payload;
    },
    setTabsMenu(state, action) {
      state.tabs = action.payload;
    },
    togglePanelMenu(state, action) {
      state.isOpenPanel = action.payload;
    },
    toggleMenu(state, action) {
      state.isOpen = action.payload;
    }
  }
});

const { actions, reducer } = menuSlice;
export const { setActiveTabMenu, setTabsMenu, toggleMenu, togglePanelMenu } = actions;
export default reducer;
