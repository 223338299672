import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";

import { StyledInfoPage } from "../common/formControlStyles";
import IconFailedComponent from "./IconFailedComponent";
import IconConfirmComponent from "./IconConfirmComponent";

const ActivationInfoComponent = ({ submit, isFail, translationKeys }) => {
  const TypeIcon = {
    true: <IconFailedComponent />,
    false: <IconConfirmComponent />
  };

  return (
    <StyledInfoPage>
      <Grid container alignItems="center" justifyContent="center" className="login">
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Grid container alignItems="center" justifyContent="center" className="login" spacing={2}>
            <Grid item xs={12}>
              {TypeIcon[!!isFail]}
            </Grid>
            <Grid item xs={12}>
              <div className="mainText">
                <FormattedMessage id={translationKeys.mainText} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="sndText">
                <FormattedMessage id={translationKeys.subText} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                classes={{
                  root: "button"
                }}
                onClick={submit}
              >
                {<FormattedMessage id="GLok" />}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledInfoPage>
  );
};

export default ActivationInfoComponent;
