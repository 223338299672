import styled from "styled-components";

const StyledFormComponent = styled.div`
  .screen {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;

    & h2 {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 5px;
    }
  }
  .img {
    max-width: 100%;
    margin-top: 16px;
  }
  .message {
    margin-top: 10px;
    font-size: 16px;
    color: #f44336;
  }
  .infoMessage {
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 30px;
  }
`;

export default StyledFormComponent;
